import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  LOGIN_CONSTANT_DATAS,
  LOGIN_PSVG,
} from "../../../constants/Auth/Login";
import { register } from "../../../services/api";
import { AuthContext } from "../../../contexts/AuthContext";
import { userRole } from "../../../constants/constants";

const SignUp = () => {
  const [formData, setFormData] = useState({
    companyName: "",
    position: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const { setUser, setRole } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };
  const validateForm = (formData) => {
    let errors = {};
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email format";
    }
    if (!formData.companyName) {
      errors.companyName = "Company name is required";
    }
    if (!formData.position) {
      errors.position = "Position is required";
    }
    if (!formData.firstName) {
      errors.firstName = "First name is required";
    }
    if (!formData.lastName) {
      errors.lastName = "Last name is required";
    }
    if (!formData.password) {
      errors.password = "Password is required";
    }
    if (!formData.confirmPassword) {
      errors.confirmPassword = "You need to confirm your password";
    } else if (formData.confirmPassword !== formData.password) {
      errors.confirmPassword = "Passwords do not match";
    }
    return errors;
  };
  const isValidEmail = (email) => {
    // Very basic email format validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm(formData);
    console.log({ validationErrors });
    if (Object.keys(validationErrors).length === 0) {
      const res = await register({
        email: formData.email,
        companyName: formData.companyName,
        position: formData.position,
        firstName: formData.firstName,
        lastName: formData.lastName,
        password: formData.password,
        role: userRole.admin,
      });
      console.log(res);
      if (res.status === "success") {
        localStorage.setItem(
          "accessToken",
          JSON.stringify({ accessToken: res.data?.token?.access })
        );
        localStorage.setItem(
          "refreshToken",
          JSON.stringify({ refreshToken: res.data?.token?.refresh })
        );
        const user = res.data.user;
        setUser(user);
        setRole(user.role);
        navigate("/admin/dashboard");
      } else {
        alert(res.message);
      }
    } else {
      // Form is invalid, set errors state with validation errors
      setErrors(validationErrors);
    }
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <div className="flex justify-between h-screen bg-payroll_primary">
      <div className="h-full overflow-hidden">
        <p className="font-eina02-bold font-[900] text-[36px] text-white opacity-[25%] pt-[48px] pl-[75px]">
          {LOGIN_PSVG.title}
        </p>
        <img src={LOGIN_PSVG.svg} alt="P" className="pt-[50px]" />
      </div>

      <div className="flex flex-col my-auto mr-[150px] max-w-[600px]  opacity-[70%]">
        <div className="rounded-[30px] shadow-2xl shadow-[#00000066] flex flex-col p-[60px] ">
          <form onSubmit={handleSubmit}>
            <h2 className="font-eina02-bold font-[700] text-[36px] text-white">
              CREATE ACCOUNT
            </h2>
            <p className="font-eina02-bold font-[500] text-[22px] mt-[30px] text-white">
              <span style={{ color: "#D64A00", fontFamily: "eina-bold" }}>
                Create account
              </span>{" "}
              to get started!
            </p>

            <InputField
              label="Company name"
              name="companyName"
              value={formData.companyName}
              handleChange={handleChange}
              placeholder={"Enter company name"}
            />
            {errors?.companyName && (
              <span style={{ color: "red" }}>{errors?.companyName}</span>
            )}
            <InputField
              label="Position/Role"
              name="position"
              value={formData.position}
              handleChange={handleChange}
              placeholder={"Enter position"}
            />
            {errors?.position && (
              <span style={{ color: "red" }}>{errors?.position}</span>
            )}
            <InputField
              label="Email address"
              name="email"
              value={formData.email}
              handleChange={handleChange}
              placeholder={"Enter email address"}
            />
            {errors?.email && (
              <span style={{ color: "red" }}>{errors?.email}</span>
            )}
            <div className="flex justify-between gap-4">
              <div className="flex flex-col">
                <InputField
                  label="First name"
                  name="firstName"
                  value={formData.firstName}
                  handleChange={handleChange}
                  placeholder={"Enter first name"}
                />
                {errors?.firstName && (
                  <span style={{ color: "red" }}>{errors?.firstName}</span>
                )}
              </div>
              <div className="flex flex-col">
                <InputField
                  label="Last name"
                  name="lastName"
                  value={formData.lastName}
                  handleChange={handleChange}
                  placeholder={"Enter last name"}
                />
                {errors?.lastName && (
                  <span style={{ color: "red" }}>{errors?.lastName}</span>
                )}
              </div>
            </div>
            <div className="flex justify-between gap-4 ">
              <div className="flex flex-col">
                <InputField
                  label="Password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  placeholder="Enter password"
                  handleChange={handleChange}
                  toggleVisibility={toggleShowPassword}
                  isPassword={true}
                />
                {errors?.password && (
                  <span style={{ color: "red" }}>{errors?.password}</span>
                )}
              </div>
              <div className="flex flex-col ">
                <InputField
                  label="Confirm password"
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  value={formData.confirmPassword}
                  placeholder="Confirm password"
                  handleChange={handleChange}
                  toggleVisibility={toggleShowConfirmPassword}
                  isPassword={true}
                />
                {errors?.confirmPassword && (
                  <span style={{ color: "red" }}>
                    {errors?.confirmPassword}
                  </span>
                )}
              </div>
            </div>

            <button
              type="submit"
              className="w-full h-[60px] py-2 rounded-md border-[1px] border-white text-white font-medium hover:bg-indigo-500 mt-[37px] shadow-lg shadow-[#FFFFFF22]"
            >
              Create account
            </button>
          </form>
          <p className="font-eina02-regular font-[400] text-[14px] text-white opacity-[70%] mt-[20px]">
            Already have an account?{" "}
            <button
              onClick={() => navigate("/login")}
              className="text-[#D64A00] hover:text-indigo-500"
            >
              Log in
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

const InputField = ({
  label,
  name,
  value,
  handleChange,
  type = "text",
  placeholder,
  toggleVisibility,
  isPassword,
}) => {
  // Assuming you have another SVG for "hide" or you use the same for both actions
  const showSvg = LOGIN_CONSTANT_DATAS.showsvg; // URL to your 'show' SVG icon

  return (
    <div className="relative w-full">
      <label
        htmlFor={name}
        className="block font-eina02-regular font-[500] text-[16px] text-white pt-[5px]"
      >
        {label}
      </label>
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className="mt-[5px] h-[50px] px-4 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 pr-[50px] bg-[#D9D9D9] bg-opacity-[30%] text-[#D9D9D9]" // Padding-right to make room for the icon
      />
      {isPassword ? (
        <img
          src={showSvg}
          alt={"Show"}
          className="absolute right-[2px] bottom-[10px] transform -translate-y-1/2 cursor-pointer"
          onClick={toggleVisibility}
        />
      ) : null}
    </div>
  );
};

export default SignUp;
