import { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import Avatar from "../../../assets/images/dashboard/svg/avatar.svg";
import Email from "../../../assets/images/dashboard/svg/mail.svg";
import Phone from "../../../assets/images/dashboard/svg/phone.svg";
import Home from "../../../assets/images/dashboard/svg/home.svg";
import Person from "../../../assets/images/dashboard/svg/person.svg";

const AccountDetail = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  
  return (
    <div className="w-full">
      <div className="text-[20px] font-eina02-semibold">Account</div>
      <div className="mt-[12px] border-b-[1px] border-[#0000001A]" />
      <div className="w-[629px] ml-[70px] mt-[25px] pb-[20px] border-[1px] border-[#EFF0F6] rounded-2xl">
        <div className="flex px-[40px] pt-[25px] pb-[20px] justify-between items-center">
          <div className="font-inter-semibold text-[16px] text-[#00000080]">Personal details</div>
          <button
            className="flex justify-center py-[7px] px-[15px] rounded-lg text-white font-inter-semibold text-[10px] bg-[#011165] hover:bg-[#011265e1]"
            onClick={() => {
              if (pathname.includes('admin')) {
                navigate("/admin/account/edit-profile")
              } else {
                navigate("/employee/account/edit-profile")
              }
            }}
          >
            Edit profile
          </button>
        </div>
        <div className="flex border-y-[1px] border-[#EFF0F6]">
          <div className="flex w-[300px] py-[25px] gap-[25px] justify-center items-center">
            <img src={Avatar} className="w-[94px] cursor-pointer" alt="company-logo" />
            <div className="flex flex-col justify-center gap-[6px]">
              <span className="font-inter-bold text-[#000000B2] text-[16px]">{user?.firstName} {user?.lastName}</span>
              <span className="font-eina02-regular text-[#9E9E9E] text-[12px]">{user?.role}</span>
            </div>
          </div>
          <div className="flex border-r-[1px] border-[#EFF0F6]"></div>
          <div className="flex flex-col justify-center w-[329px] gap-[10px]">
            <div className="flex items-center gap-[6px] pl-[25px]">
              <img src={Email} className="w-[19px] cursor-pointer" alt="company-logo" />
              <span className="font-inter-regular text-[#000000B2] text-[13px]">{user?.email}</span>
            </div>
            <div className="flex items-center gap-[13px] pl-[25px]">
              <img src={Phone} className="w-[12px] cursor-pointer" alt="company-logo" />
              <span className="font-inter-regular text-[#000000B2] text-[13px]">{user?.phoneNumber}</span>
            </div>
          </div>
        </div>
        <div className="pl-[40px] pt-[5px] font-inter-medium text-[16px] text-[#00000080]">Address</div>
        <div className="pl-[40px] pt-[25px] pb-[20px] flex items-center gap-[10px]">
          <img src={Home} className="w-[18px]" alt="home" />
          <div className="font-inter-regular text-[13px] text-[#000000B2]">{user.address}</div>
        </div>
        <div className="pl-[40px] py-[20px] flex flex-col gap-[25px] border-y-[1px] border-[#EFF0F6]">
          <div className="font-inter-medium text-[16px] text-[#00000080]">Next of kin details</div>
          {user && user?.kinFirstName?.length > 0 && <div className="flex flex-col gap-[15px]">
            <div className="flex items-center gap-[10px]">
              <img src={Person} className="w-[18px]" alt="home" />
              <div className="font-inter-regular text-[13px] text-[#000000B2]">{user?.kinFirstName} {user?.kinLastName}</div>
            </div>
            <div className="flex items-center gap-[16px]">
              <img src={Phone} className="w-[12px]" alt="home" />
              <div className="font-inter-regular text-[13px] text-[#000000B2]">{user?.kinPhoneNumber}</div>
            </div>
            <div className="flex items-center gap-[10px]">
              <img src={Home} className="w-[18px]" alt="home" />
              <div className="font-inter-regular text-[13px] text-[#000000B2]">{user?.kinAddress}</div>
            </div>
          </div>}
        </div>
        <div className="pl-[40px] py-[20px] flex flex-col gap-[25px]">
          <div className="font-inter-medium text-[16px] text-[#00000080]">Emergency contract</div>
          {user && user?.emergencyFirstName?.length > 0 && <div className="flex flex-col gap-[15px]">
            <div className="flex items-center gap-[10px]">
              <img src={Person} className="w-[18px]" alt="home" />
              <div className="font-inter-regular text-[13px] text-[#000000B2]">{user?.emergencyFirstName} {user?.emergencyLastName}</div>
            </div>
            <div className="flex items-center gap-[16px]">
              <img src={Phone} className="w-[12px]" alt="home" />
              <div className="font-inter-regular text-[13px] text-[#000000B2]">{user?.emergencyPhoneNumber}</div>
            </div>
            <div className="flex items-center gap-[10px]">
              <img src={Home} className="w-[18px]" alt="home" />
              <div className="font-inter-regular text-[13px] text-[#000000B2]">{user?.emergencyAddress}</div>
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
};
export default AccountDetail;
