import { useContext, useEffect, useState } from "react";
import Warning from "../../../assets/images/dashboard/svg/warning.svg";
import Close from "../../../assets/images/dashboard/svg/close-yellow.svg";
import Download from "../../../assets/images/dashboard/svg/download.svg";
import Calendar from "../../../assets/images/dashboard/svg/calendar.svg";
import Vacation from "../../../assets/images/dashboard/svg/vacation-icon.svg";
import { formatDate, formatEventDate } from "../../../utils/helper";
import Dropdown from "../../../components/Dropdown/index";
import { AuthContext } from "../../../contexts/AuthContext";
import { getPaymentHistory, getUpcommingEvent } from "../../../services/api";
import { EmployeeContext } from "../../../contexts/EmployeeContext";
const dummyPaymentSummary = {
  paymentSummary: [
    {
      date: "2024-01",
      payeeName: "Peter Pan",
      deduction: [
        {
          type: "NHFdeduction",
          amount: 5000,
        },
        {
          type: "pension",
          amount: 25000,
        },
        {
          type: "mortgage",
          amount: 15000,
        },
      ],
      netPay: 250000,
      status: "Completed",
    },
    {
      date: "2024-02",
      payeeName: "Peter Pan",
      deduction: [
        {
          type: "NHFdeduction",
          amount: 5000,
        },
        {
          type: "pension",
          amount: 25000,
        },
        {
          type: "mortgage",
          amount: 15000,
        },
      ],
      netPay: 250000,
      status: "Completed",
    },
    {
      date: "2024-03, 2024-04",
      payeeName: "Peter Pan",
      deduction: [
        {
          type: "NHFdeduction",
          amount: 5000,
        },
        {
          type: "pension",
          amount: 25000,
        },
        {
          type: "mortgage",
          amount: 15000,
        },
      ],
      netPay: 250000,
      status: "Completed",
    },
  ],
  upcommingEvents: [
    {
      eventName: "3 Day Vacation",
      eventCategory: "Company event",
      eventDate: "2024-04-17",
    },
    {
      eventName: "Hiking",
      eventCategory: "Company event",
      eventDate: "2024-04-17",
    },
    {
      eventName: "Picnic",
      eventCategory: "Company event",
      eventDate: "2024-04-17",
    },
    {
      eventName: "Independence day",
      eventCategory: "National holiday",
      eventDate: "2024-04-17",
    },
  ],
};

const EventRow = (props) => {
  const { event } = props;
  return (
    <div className="w-full py-[13px] flex justify-between items-center">
      <div className="flex gap-[15px] items-center">
        <img
          src={Vacation}
          className="w-[51px] cursor-pointer"
          alt="vacation"
        />
        <div className="">
          <div className="font-eina02-semibold text-[14px] text-[#404040]">
            {event.eventName}
          </div>
          <div className="font-eina02-regular text-[14px] text-[#757575]">
            {formatEventDate(event.eventDate)}
          </div>
        </div>
      </div>
      <div className="flex gap-[8px] items-center">
        <img
          src={Calendar}
          className="w-[26px] cursor-pointer"
          alt="calendar"
        />
        <div className="font-eina02-semibold text-[12px] text-[#011165] cursor-pointer">
          Add to calendar
        </div>
      </div>
    </div>
  );
};
const EmployeeDashboard = () => {
  const [displaySummaryData, setDisplaySummaryData] = useState({});
  const [lastPaymentData, setLastPaymentData] = useState({});
  // const filterOptions = ['Most recent', 'Dec, 2023', 'Nov, 2023'];
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(0);
  const [showPolicy, setShowPolicy] = useState(true);
  const { user } = useContext(AuthContext);
  const { paymentHistory, setPaymentHistory, upcomingEvent, setUpcomingEvent } =
    useContext(EmployeeContext);
  useEffect(() => {
    const fetchPaymentHistory = async (userId) => {
      const res = await getPaymentHistory(userId);
      console.log(res);
      if (res?.length > 0) {
        setPaymentHistory(res);
        setDisplaySummaryData(res[0]);
        setLastPaymentData(res[0]);
      }
    };
    if (Object.keys(user).length > 0 && !paymentHistory) {
      fetchPaymentHistory(user.userId);
    }
  }, [user, paymentHistory]);

  useEffect(() => {
    const fetchUpcommingEvent = async (companyName) => {
      const res = await getUpcommingEvent(companyName);
      console.log(res);
      if (res?.length > 0) {
        setUpcomingEvent(res);
      }
    };
    if (Object.keys(user).length > 0 && !upcomingEvent) {
      fetchUpcommingEvent(user.companyName);
    }
  }, [user]);

  useEffect(() => {
    if (paymentHistory?.length > 0) {
      setDisplaySummaryData(paymentHistory[0]);
      setLastPaymentData(paymentHistory[0]);
      //initialize filter option
      let filterOption = [];
      paymentHistory.forEach((e, index) => {
        filterOption.push({
          index,
          label: formatDate(e.fromDate, e.toDate),
        });
      });

      setFilterOptions(filterOption);
    }
  }, [paymentHistory]);
  useEffect(() => {
    if (paymentHistory?.length > 0) {
      setDisplaySummaryData(paymentHistory[selectedOption]);
    }
  }, [selectedOption, paymentHistory]);
  const handleDownload = () => {
    // Here you can perform the download operation
    // For example, create a blob URL and trigger download
    if (paymentHistory && paymentHistory.length > 0) {
      const data = JSON.stringify(paymentHistory);
      const blob = new Blob([data], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `paymentSummary-${displaySummaryData?.payeeName}.json`;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };
  return (
    <div className="w-full">
      <div className="text-[20px] font-eina02-semibold">Dashboard</div>
      <div className="mt-[12px] border-b-[1px] border-[#0000001A]" />
      <div className="w-full flex gap-[30px] pt-[40px]">
        <div className="w-1/2 min-w-[554px] flex flex-col gap-[25px]">
          <div className="border-[#EFF0F6] border-[0.92px] rounded-2xl">
            <div className="flex w-full px-[22px] py-[10px] border-b-[0.5px] border-[#EFF0F6] pt-[10px]">
              <div className="w-3/5 font-eina02-semibold text-[16px] text-[#404040]">
                Payment summary
              </div>
              <div className="flex gap-[10px]">
                <div className="font-eina02-semibold text-[16px] text-[#404040]">
                  Filter by
                </div>
                <Dropdown
                  options={filterOptions}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                />
              </div>
            </div>
            {Object.keys(displaySummaryData)?.length > 0 && (
              <>
                <div className="flex w-full px-[22px] py-[15px] pt-[30px] border-b-[1px] border-[#EFF0F6]">
                  <div className="w-3/5 font-eina02-regular text-[14px] text-[#404040]">
                    Date
                  </div>
                  <div className="font-eina02-semibold text-[16px] text-[#404040]">
                    {formatDate(
                      displaySummaryData?.fromDate,
                      displaySummaryData?.toDate
                    )}
                  </div>
                </div>
                <div className="flex w-full px-[22px] py-[15px]">
                  <div className="w-3/5 font-eina02-regular text-[14px] text-[#404040]">
                    Payee
                  </div>
                  <div className="font-eina02-semibold text-[16px] text-[#404040]">
                    {displaySummaryData?.payeeName}
                  </div>
                </div>
                {displaySummaryData?.deduction?.length > 0 &&
                  displaySummaryData.deduction.map((data) => {
                    return (
                      <div className="flex w-full px-[22px] py-[15px]">
                        <div className="w-3/5 font-eina02-regular text-[14px] text-[#404040]">
                          {data.type}
                        </div>
                        <div className="font-eina02-semibold text-[16px] text-[#404040]">
                          &#8358; {data.amount}
                        </div>
                      </div>
                    );
                  })}
                {/* <div className="flex w-full px-[22px] py-[15px]">
                  <div className="w-3/5 font-eina02-regular text-[14px] text-[#404040]">NHF deductions</div>
                  <div className="font-eina02-semibold text-[16px] text-[#404040]">&#8358; {displaySummaryData?.NHFduduction}</div>
                </div>
                <div className="flex w-full px-[22px] py-[15px]">
                  <div className="w-3/5 font-eina02-regular text-[14px] text-[#404040]">pension</div>
                  <div className="font-eina02-semibold text-[16px] text-[#404040]">&#8358; {displaySummaryData?.pension}</div>
                </div>
                <div className="flex w-full px-[22px] py-[15px] border-b-[1px] border-[#EFF0F6]">
                  <div className="w-3/5 font-eina02-regular text-[14px] text-[#404040]">Mortgage</div>
                  <div className="font-eina02-semibold text-[16px] text-[#404040]">&#8358; {displaySummaryData?.mortgage}</div>
                </div> */}
                <div className="flex w-full px-[22px] py-[15px] border-b-[1px] border-[#EFF0F6]">
                  <div className="w-3/5 font-eina02-semibold text-[14px] text-[#404040]">
                    Net Pay
                  </div>
                  <div className="font-eina02-semibold text-[16px] text-[#1DA392]">
                    &#8358; {displaySummaryData?.netPay}
                  </div>
                </div>
              </>
            )}
            <div className="flex gap-[5px] w-full px-[22px] py-[20px]">
              <div className="font-eina02-regular text-[14px] text-[#404040]">
                Download
              </div>
              <img
                src={Download}
                className="w-[24px] cursor-pointer"
                alt="download"
                onClick={handleDownload}
              />
            </div>
          </div>
          {showPolicy && (
            <div className="flex flex-col gap-[20px] h-[150px] border-[#F0B732] border-[1px] rounded-2xl bg-[#FCF1D6] px-[25px] py-[20px]">
              <div className="flex items-center justify-between w-full">
                <div className="flex gap-[30px] items-center">
                  <img src={Warning} className="w-[24px]" alt="warning" />
                  <span className="font-eina02-bold text-[16px] text-[#0A0A0A]">
                    Allowance Policy
                  </span>
                </div>
                <img
                  src={Close}
                  className="w-[24px] cursor-pointer mt-[-15px] mr-[-10px]"
                  alt="close"
                  onClick={() => setShowPolicy(false)}
                />
              </div>
              <div className="text-[14px] text-[#404040] pl-[54px]">
                <p>1. Leave will start from the month of January</p>
                <p>2. Any type of change will be effective on the next day</p>
              </div>
            </div>
          )}
        </div>
        <div className="w-1/2 min-w-[500px] flex flex-col gap-[25px]">
          <div className="border-[#EFF0F6] border-[0.92px] rounded-2xl">
            <div className="flex justify-between items-center w-full px-[40px] py-[15px] border-b-[1px] border-[#EFF0F6]">
              <div className="font-eina02-semibold text-[16px] text-[#404040]">
                Last payment
              </div>
              <div className="font-eina02-regular text-[12px] text-[#FF5800] cursor-pointer">
                See more
              </div>
            </div>
            <div className="border-b-[2px] border-[#FF5800] ml-[40px] mt-[-2px] w-[100px]" />
            <div className="px-[40px] py-[20px]">
              {Object.keys(lastPaymentData).length > 0 && (
                <>
                  <div className="flex justify-between text-[14px] text[#757575]">
                    <span>Salary</span>
                    <span>
                      {formatDate(
                        lastPaymentData?.fromDate,
                        lastPaymentData?.toDate
                      )}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="font-eina02-semibold text-[32px] text-[#404040]">
                      &#8358; {lastPaymentData?.netPay}
                    </div>
                    <div className="font-eina02-semibold text-[12px] text-[#1DA392] py-[5px] px-[10px] bg-[#1DA39259] cursor-pointer rounded-2xl">
                      {lastPaymentData?.transactionStatus}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="border-[#EFF0F6] border-[0.92px] rounded-2xl">
            <div className="flex justify-between w-full px-[40px] py-[15px] border-b-[1px] border-[#EFF0F6]">
              <div className="font-eina02-semibold text-[16px] text-[#404040]">
                Upcomming events
              </div>
            </div>
            <div className="border-b-[2px] border-[#FF5800] ml-[40px] mt-[-2px] w-[145px]" />
            <div className="px-[40px] pt-[20px]">
              {upcomingEvent &&
                upcomingEvent.map((event, i) => {
                  return <EventRow event={event} key={i} />;
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmployeeDashboard;
