import { useContext, useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, useRowSelect } from "react-table";

import TotalAmount from "../../../assets/images/dashboard/svg/total-amount.svg";
import RightArrow from "../../../assets/images/dashboard/svg/right-arrow.svg";
import SortIcon from "../../../assets/images/dashboard/svg/sort-icon.svg";
import Employee from "../../../assets/images/dashboard/svg/employee-sample.svg";
import { getMonthFromDate } from "../../../utils/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  approverManagementState,
  dashboardState,
} from "../../../constants/constants";

const ApproverList = (props) => {
  const { approverData, setPageState } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [editableRows, setEditableRows] = useState(new Set()); // State to track editable rows
  const isRowEditable = (rowIndex) => editableRows.has(rowIndex);
  const data = approverData ?? [];
  const COLUMNS = [
    // {
    //   id: 'selection',
    //   Header: ({ getToggleAllRowsSelectedProps }) => (
    //     <div className="">
    //       <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
    //     </div>
    //   ),
    //   Cell: ({ row }) => <input type="checkbox" className="" {...row.getToggleRowSelectedProps()} />,
    // },
    { Header: "Name", accessor: "approverName" },
    { Header: "Email Address", accessor: "approverEmail" },
    { Header: "Designation", accessor: "approverPosition" },
    { Header: "Level", accessor: "approverLevel" },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      data,
      columns,
    },
    useSortBy,
    useRowSelect
  );

  useEffect(() => {
    setSelectedRows(selectedFlatRows?.map((row) => row.original));
  }, [selectedFlatRows, setSelectedRows]);

  const toggleEditable = (rowIndex) => {
    setEditableRows((prevState) => {
      const newSet = new Set(prevState);
      if (newSet.has(rowIndex)) {
        newSet.delete(rowIndex);
      } else {
        newSet.add(rowIndex);
      }
      return newSet;
    });
  };

  return (
    <div className="w-full">
      <div className="flex flex-col gap-[20px]">
        <table {...getTableProps()} className="w-full bg-white rounded-xl">
          <thead className="bg-[#EDEDED]">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    scope="col"
                    className={`px-8 py-6 
                    ${column.id === "selection" ? "rounded-tl-xl" : ""}
                    ${column.Header === "Action" ? "rounded-tr-xl" : ""}
                  `}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div className="flex items-center gap-[5px] text-left font-inter-semibold text-sm text-[#000000B2]">
                      {column.render("Header")}
                      {column?.id !== "selection" && (
                        <img src={SortIcon} className="w-[8px]" />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            className="divide-y divide-[#7b78781a]"
            {...getTableBodyProps()}
          >
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      className={`
                        px-8 py-10 text-[#00000080] font-inter-medium text-sm
                        ${
                          cell.column.Header === "Name"
                            ? "flex items-center justify-start gap-[5px]"
                            : ""
                        }
                      `}
                      {...cell.getCellProps()}
                    >
                      {/* {cell.column.Header === 'Name' && 
                      <img 
                          src={Employee}
                          className="w-[29px]"
                          alt="employee"
                      />
                    } */}
                      {cell.column.id === "selection" ? (
                        cell.render("Cell")
                      ) : isRowEditable(i) ? (
                        <input
                          type="text"
                          value={cell.value}
                          onChange={(e) => {
                            // You'll need to update your data here
                            data[row.index][cell.column.id] = e.target.value;
                          }}
                          className="w-full"
                        />
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default ApproverList;
