import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Avatar from "../../../assets/images/dashboard/svg/avatar.svg";
import Email from "../../../assets/images/dashboard/svg/mail.svg";
import Phone from "../../../assets/images/dashboard/svg/phone.svg";
import Home from "../../../assets/images/dashboard/svg/home.svg";
import Person from "../../../assets/images/dashboard/svg/person.svg";
import { updateEmployeeData } from "../../../services/api";

const EditProfile = () => {
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState({});
  useEffect(() => {
    if (user && Object.keys(user)?.length > 0) {
      setFormData(user);
    }
  }, [user])
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Process form data here
    console.log(formData);
    const res = await updateEmployeeData(formData);
    console.log(res)
  };
  return (
    <div className="w-full">
      <div className="text-[20px] font-eina02-semibold">Account</div>
      <div className="mt-[12px] border-b-[1px] border-[#0000001A]" />
      {/* <div className="mt-[25px] pb-[20px] border-[1px] border-[#EFF0F6] rounded-2xl"> */}
      {user && Object.keys(user)?.length > 0 && <div className="flex gap-[15px] pt-[20px]">
        <div className="w-1/3 max-w-[330px] flex flex-col pt-[10px] px-[15px] pb-[30px] border-[1px] border-[#EFF0F6] rounded-2xl">
          <div className="font-inter-medium text-[16px] text-[#000000B2]">Personal details</div>
          <div className="flex flex-col py-[25px] gap-[25px] justify-center items-center">
            <img src={Avatar} className="w-[94px] cursor-pointer" alt="company-logo" />
            <div className="flex flex-col justify-center gap-[6px]">
              <span className="font-inter-bold text-[#000000B2] text-[16px]">{user.firstName} {user.lastName}</span>
              <span className="font-eina02-regular text-[#9E9E9E] text-[12px]">{user.role}</span>
            </div>
          </div>
          <div className="flex flex-col justify-center gap-[10px]">
            <div className="flex items-center gap-[6px] pl-[70px]">
              <img src={Email} className="w-[19px] cursor-pointer" alt="company-logo" />
              <span className="font-inter-regular text-[#000000B2] text-[13px]">{user.email}</span>
            </div>
            <div className="flex items-center gap-[13px] pl-[70px]">
              <img src={Phone} className="w-[12px] cursor-pointer" alt="company-logo" />
              <span className="font-inter-regular text-[#000000B2] text-[13px]">{user.phoneNumber}</span>
            </div>
          </div>
        </div>
        <div className="w-2/3 max-w-[625px] flex flex-col pt-[10px] px-[35px] pb-[30px] border-[1px] border-[#EFF0F6] rounded-2xl">
          <form onSubmit={handleSubmit}>
            <div className="flex items-start justify-between">
              <div className="font-inter-medium text-[#000000B2] text-[16px]">Edit Profile</div>
              <div className="flex gap-[10px]">
                <button
                  className="flex justify-center py-[7px] px-[10px] rounded-lg text-[#011165] font-inter-semibold text-[11px] bg-[#ffffff] border-[1px] border-[#011165]"
                  onClick={() => {}}
                >
                  Cancel
                </button>
                <button
                  className="flex justify-center py-[7px] px-[10px] rounded-lg text-white font-inter-semibold text-[11px] bg-[#011165]"
                  onClick={() => {}}
                  type="submit"
                >
                  Save
                </button>
              </div>
            </div>
            <div className="flex flex-col gap-[12px] pt-[10px]">
              <div className="flex flex-col gap-[5px] font-inter-medium text-[14px] text-[#00000080]">
                <div className="font-inter-medium">Address</div>
                <input
                  type="text"
                  name="address"
                  className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col gap-[5px] font-inter-medium text-[14px] text-[#00000080]">
                <div className="font-inter-medium">Phone number</div>
                <input
                  type="text"
                  name="phoneNumber"
                  className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex gap-[30px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                  <div className="font-inter-medium">City</div>
                  <input
                    type="text"
                    name="city"
                    className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                  <div className="font-inter-medium">State</div>
                  <input
                    type="text"
                    name="state"
                    className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                    value={formData.state}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="font-inter-medium text-[#000000B2] text-[16px] pt-[30px]">Next of Kin</div>
            <div className="flex flex-col gap-[12px] pt-[10px]">
              <div className="flex gap-[30px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                  <div className="font-inter-medium">First name</div>
                  <input
                    type="text"
                    name="kinFirstName"
                    className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                    value={formData.kinFirstName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                  <div className="font-inter-medium">Last name</div>
                  <input
                    type="text"
                    name="kinLastName"
                    className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                    value={formData.kinLastName}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-[5px] font-inter-medium text-[14px] text-[#00000080]">
                <div className="font-inter-medium">Phone number</div>
                <input
                  type="text"
                  name="kinPhoneNumber"
                  className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                  value={formData.kinPhoneNumber}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col gap-[5px] font-inter-medium text-[14px] text-[#00000080]">
                <div className="font-inter-medium">Address</div>
                <input
                  type="text"
                  name="kinAddress"
                  className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                  value={formData.kinAddress}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="flex items-center justify-between pt-[30px]">
              <div className="font-inter-medium text-[#000000B2] text-[16px]">Emergency contact</div>
              {/* <div className="flex gap-[10px] items-center">
                <input
                  type="radio"
                  name="populateNextKinDetails"
                  className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                  value={formData.populateNextKinDetails}
                  onChange={handleInputChange}
                />
                <div className="font-inter-regular text-[14px] text-[#00000080]">Populate next of kin details</div>
              </div> */}
            </div>
            <div className="flex flex-col gap-[12px] pt-[10px]">
              <div className="flex gap-[30px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                  <div className="font-inter-medium">First name</div>
                  <input
                    type="text"
                    name="emergencyFirstName"
                    className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                    value={formData.emergencyFirstName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                  <div className="font-inter-medium">Last name</div>
                  <input
                    type="text"
                    name="emergencyLastName"
                    className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                    value={formData.emergencyLastName}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-[5px] font-inter-medium text-[14px] text-[#00000080]">
                <div className="font-inter-medium">Phone number</div>
                <input
                  type="text"
                  name="emergencyPhoneNumber"
                  className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                  value={formData.emergencyPhoneNumber}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col gap-[5px] font-inter-medium text-[14px] text-[#00000080]">
                <div className="font-inter-medium">Address</div>
                <input
                  type="text"
                  name="emergencyAddress"
                  className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                  value={formData.emergencyAddress}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </form>
        </div>
      </div>}
    </div>
  );
};
export default EditProfile;
