import React, { useContext, useEffect, useState } from "react"; // Ensure React and useState are imported
import { useNavigate, useParams } from "react-router-dom";
import {
  LOGIN_PSVG,
  LOGIN_CONSTANT_DATAS,
} from "../../../constants/Auth/Login";
import {
  confirmVerificationCode,
  getBankList,
  login,
  requestVerificationCode,
} from "../../../services/api";
import { userRole } from "../../../constants/constants";
import { AuthContext } from "../../../contexts/AuthContext";
import { EmployeeContext } from "../../../contexts/EmployeeContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Login = () => {
  const navigate = useNavigate();
  const { email } = useParams();
  const { setUser, setRole } = useContext(AuthContext);
  const { bankList, setBankList } = useContext(EmployeeContext);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isOTPsent, setIsOTPsent] = useState(false);
  const [otp, setOTP] = useState("");
  const [errors, setErrors] = useState({});
  const [firstEmployeeLogin, setFirstEmployeeLogin] = useState(false);

  useEffect(() => {
    if (email) {
      setFormData((prevState) => ({
        ...prevState,
        email: email,
      }));
      setFirstEmployeeLogin(true);
    }
  }, [email]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      const res = await login({
        email: formData.email,
        password: formData.password,
      });
      if (res.status === "success") {
        handleSuccessfulLogin(res);
      } else {
        toast.error(res.message || "Failed to log in. Please try again.");
        if (
          res.message === "Password is incorrect" ||
          res.message === "User does not exist"
        ) {
          //toast.error(res.message);
        } else {
          navigate(`/code-verification/${formData.email}`);
        }
      }
    } else {
      setErrors(validationErrors);
      Object.values(validationErrors).forEach((error) => toast.error(error));
    }
  };
  const validateForm = (formData) => {
    let errors = {};
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email format";
    }
    if (!formData.password) {
      errors.password = "Password is required";
    }
    if (firstEmployeeLogin) {
      if (!formData.confirmPassword) {
        errors.confirmPassword = "You need to confirm your password";
      } else if (formData.confirmPassword !== formData.password) {
        errors.confirmPassword = "Passwords do not match";
      }
    }
    return errors;
  };
  const isValidEmail = (email) => {
    // Very basic email format validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleOTPChange = async (e) => {
    const inputOTP = e.target.value;
    setOTP(inputOTP);
    if (inputOTP.length === 6) {
      const res = await confirmVerificationCode({
        email,
        password: formData.password,
        code: inputOTP,
      });
      if (res.status === "success") {
        handleSuccessfulLogin(res);
      } else {
        toast.error(res.message || "Invalid OTP. Please try again.");
      }
    }
  };

  const handleSuccessfulLogin = (res) => {
    localStorage.setItem(
      "accessToken",
      JSON.stringify({ accessToken: res.data?.token?.access })
    );
    localStorage.setItem(
      "refreshToken",
      JSON.stringify({ refreshToken: res.data?.token?.refresh })
    );
    setUser(res.data.user);
    setRole(res.data.user.role);
    navigate(
      res.data.user.role === userRole.admin
        ? "/admin/dashboard"
        : "/employee/dashboard"
    );
    toast.success("Login successful!");
  };
  // Navigate to Forgot Password page
  const navigateToForgotPassword = () => {
    navigate("/forget-pwd");
  };

  return (
    <div>
      <ToastContainer />
      <div className="flex justify-between h-screen bg-payroll_primary">
        {!isOTPsent ? (
          <>
            <div className="h-full overflow-hidden">
              <p className="font-eina02-bold font-[900] text-[36px] text-white opacity-[25%] pt-[48px] pl-[75px]">
                {LOGIN_PSVG.title}
              </p>
              <img src={LOGIN_PSVG.svg} alt="P" className="pt-[50px]" />
            </div>
            <div className="flex flex-col my-auto mr-[150px] max-w-[600px]">
              <div className="rounded-[30px] shadow-2xl shadow-[#00000066] flex flex-col p-[60px]">
                <form onSubmit={handleSubmit} className="space-y-6">
                  <p className=" font-eina02-bold font-[700] text-[36px] text-white opacity-70">
                    {LOGIN_CONSTANT_DATAS.title}
                  </p>
                  <p className="font-eina02-bold font-[500] text-[22px] mt-[30px] text-white opacity-70">
                    <span style={{ color: "#D64A00", fontFamily: "eina-bold" }}>
                      Welcome back!
                    </span>{" "}
                    Log in to Continue
                  </p>
                  <div>
                    <label
                      htmlFor="email"
                      className="font-eina02-regular font-[500] text-[16px] text-white mt-[53px]  opacity-70"
                    >
                      {LOGIN_CONSTANT_DATAS.email}
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder={"Enter email"}
                      className="h-[50px] px-4 py-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500  bg-[#D9D9D9] bg-opacity-[30%] pr-[50px]  text-[#D9D9D9] mt-[17px]  opacity-70" // Increased padding-right to make room for the icon
                    />
                    {errors?.email && (
                      <span style={{ color: "red" }}>{errors?.email}</span>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="  font-eina02-regular font-[500] text-[16px] text-white mt-[20px]  opacity-70"
                    >
                      {LOGIN_CONSTANT_DATAS.passowrd}
                    </label>
                    <div className="flex justify-between items-center mt-[17px] relative w-full">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        placeholder={"Enter passowrd"}
                        className="h-[50px] px-4 py-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-[#D9D9D9] bg-opacity-[30%] pr-[50px]  text-[#D9D9D9]  opacity-70" // Increased padding-right to make room for the icon
                      />
                      <img
                        src={LOGIN_CONSTANT_DATAS.showsvg}
                        alt="Show"
                        className="absolute transform -translate-y-1/2 cursor-pointer right-4 top-1/2" // Positioned to the right
                        onClick={togglePasswordVisibility} // Assuming you have a function to toggle the visibility
                      />
                    </div>
                    {errors?.password && (
                      <span style={{ color: "red" }}>{errors?.password}</span>
                    )}
                  </div>
                  {firstEmployeeLogin && (
                    <div>
                      <label
                        htmlFor="confirmPassword"
                        className="  font-eina02-regular font-[500] text-[16px] text-white mt-[20px]"
                      >
                        {LOGIN_CONSTANT_DATAS.passowrd}
                      </label>
                      <div className="flex justify-between items-center mt-[17px] relative w-full">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          id="confirmPassword"
                          name="confirmPassword"
                          value={formData.confirmPassword}
                          onChange={handleChange}
                          placeholder={"Enter passowrd"}
                          className="h-[50px] px-4 py-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-[#D9D9D9] bg-opacity-[30%] pr-[50px]  text-[#D9D9D9]" // Increased padding-right to make room for the icon
                        />
                        <img
                          src={LOGIN_CONSTANT_DATAS.showsvg}
                          alt="Show"
                          className="absolute transform -translate-y-1/2 cursor-pointer right-4 top-1/2" // Positioned to the right
                          onClick={toggleConfirmPasswordVisibility} // Assuming you have a function to toggle the visibility
                        />
                      </div>
                      {errors?.confirmPassword && (
                        <span style={{ color: "red" }}>
                          {errors?.confirmPassword}
                        </span>
                      )}
                    </div>
                  )}
                  <div className="text-right">
                    <button
                      onClick={navigateToForgotPassword}
                      className="font-eina02-regular font-[400] text-[14px] text-[#C4c4c4] mt-[26px] hover:text-blue-500 cursor-pointer"
                    >
                      {LOGIN_CONSTANT_DATAS.forgot}
                    </button>
                  </div>
                  <button
                    type="submit"
                    className="w-full h-[60px] py-2 rounded-md border-[1px] border-white text-white font-medium hover:bg-indigo-500 mt-[37px] shadow-lg shadow-[#FFFFFF22]"
                  >
                    {LOGIN_CONSTANT_DATAS.btn}
                  </button>
                </form>
                <p className=" font-eina02-regular font-[400] text-[14px] text-white opacity-[70%] mt-[34px]">
                  Don’t have an account?{" "}
                  <button
                    onClick={() => navigate("/signup")}
                    className="text-[#D64A00] hover:text-indigo-500"
                  >
                    Create free account
                  </button>
                </p>
              </div>
            </div>
          </>
        ) : (
          <div className="m-auto">
            <div className="font-eina02-regular font-[500] text-[16px] text-white mt-[53px]">
              Verification code has sent to your email.
              <br></br>
              Please check your inbox.
            </div>
            <div>
              <input
                type="text"
                placeholder="OTP"
                value={otp}
                onChange={handleOTPChange}
                className="h-[50px] px-4 py-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500  bg-[#D9D9D9] bg-opacity-[30%] pr-[50px]  text-[#FFFFFF] mt-[17px]"
              />
            </div>
            <div className="flex items-end justify-between">
              <div className="font-eina02-regular font-[500] text-[16px] text-white mt-[53px]">
                You did not recieve it?
              </div>
              <button
                type="submit"
                className="w-1/3 h-[40px] py-2 rounded-md border-[1px] border-white text-white font-medium hover:bg-indigo-500 mt-[37px] shadow-lg shadow-[#FFFFFF22]"
              >
                Resend
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
