import { Fragment, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, useSortBy, useRowSelect, usePagination, useFilters  } from 'react-table';
import Employee from "../../../assets/images/dashboard/svg/employee-sample.svg";
import Edit from "../../../assets/images/dashboard/svg/edit.svg";
import Remove from "../../../assets/images/dashboard/svg/remove.svg";
import Close from "../../../assets/images/dashboard/svg/close.svg";
import Search from "../../../assets/images/dashboard/svg/search.svg";
import ThreeDots from "../../../assets/images/dashboard/svg/three-dots.svg";
import Pagination from "../../../components/Pagination/index";
import SortIcon from "../../../assets/images/dashboard/svg/sort-icon.svg";
import Warning from "../../../assets/images/dashboard/svg/warning.svg";
import CloseYellow from "../../../assets/images/dashboard/svg/close-yellow.svg";
import { initiatePayrollState } from "../../../constants/constants";

const AddEmployeePayroll = (props) => {
  const { employeeData, setPayableEmployee, setPageState } = props;
  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [totalSalary, setTotalSalary] = useState(13050090);
  const navigate = useNavigate();
  const [searchName, setSearchName] = useState('');
  const [showNote, setShowNote] = useState(true);

  const selectedRowsRef = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editableRows, setEditableRows] = useState(new Set()); // State to track editable rows
  const isRowEditable = rowIndex => editableRows.has(rowIndex);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null); // State to track selected row for the more options popup

  const data = employeeData ?? [];
  const COLUMNS = [
    // {
    //   id: 'selection',
    //   Header: ({ getToggleAllRowsSelectedProps }) => (
    //     <div className="">
    //       <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
    //     </div>
    //   ),
    //   Cell: ({ row }) => <input type="checkbox" className="" {...row.getToggleRowSelectedProps()} />,
    // },
    { Header: 'First Name', accessor: 'firstName' },
    { Header: 'Middle Name', accessor: 'middleName' },
    { Header: 'Last Name', accessor: 'lastName' },
    { Header: 'Salary', accessor: row => `₦ ${row.grossSalary}` },
    // { Header: 'Total Amount Payable', accessor: row => `₦ ${row.grossSalary}` },
    { Header: 'Total Amount Payable', accessor: row => `₦ ${row.totalPayableAmount}` },
    // { Header: 'Total Amount Payable', accessor: 'totalPayableAmount' },
    { Header: 'Status', accessor: 'status' },
    {
      id: 'action',
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <></>
      ),
      Cell: ({ row }) => <button onClick={event => handleMoreOptionsClick(event, row.index)}><img src={ThreeDots} className="w-[4px]"/></button>,
    },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const { 
    getTableProps, 
    getTableBodyProps, 
    headerGroups, 
    page,
    // rows, 
    prepareRow, 
    state: {pageIndex, pageSize},
    selectedFlatRows,
    pageOptions, 
    pageCount,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    setFilter,
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 5 }, // Initial pagination state
    },
    useFilters, 
    useSortBy,
    usePagination,
    useRowSelect,
  );

  useEffect(() => {
    setFilter('firstName', searchName);
  }, [searchName]);
  useEffect(() => {
    setSelectedRows(selectedFlatRows?.map(row => row.original));
  }, [selectedFlatRows, setSelectedRows]);

  useEffect(() => {
    console.log(pageIndex);
  }, [pageIndex])

  const toggleEditable = rowIndex => {
    setEditableRows(prevState => {
      const newSet = new Set(prevState);
      if (newSet.has(rowIndex)) {
        newSet.delete(rowIndex);
      } else {
        newSet.add(rowIndex);
      }
      return newSet;
    });
  };

  const handleMoreOptionsClick = (event, rowIndex) => {
    console.log(event, rowIndex);
    event.stopPropagation(); // Prevent table row click event from triggering
    setSelectedRowIndex(rowIndex === selectedRowsRef.current ? null : rowIndex); // Toggle selected row
  };
  useEffect(() => {
    selectedRowsRef.current = selectedRowIndex
  }, [selectedRowIndex]);
  
  const onFromDateChange = (date) => {
    setSelectedFromDate(date);
  };
  
  const onToDateChange = (date) => {
    setSelectedToDate(date);
  };
  const handleSearchInputChange = (event) => {
    setSearchName(event.target.value);
  }
  const handleInputChange = (id, event) => {
    console.log(id, event.target.value)
    let newTotalPayableAmount;
    if (event.target.value === '₦ ') {
      newTotalPayableAmount = 0;
    } else {
      newTotalPayableAmount = parseFloat(event.target.value.replace('₦ ', '')) ?? 0;
    }
    console.log(newTotalPayableAmount);
    const updatedData = [
      ...data.slice(0, id), // Elements before the updated row
      { ...data[id], totalPayableAmount: newTotalPayableAmount }, // Updated row
      ...data.slice(id + 1) // Elements after the updated row
    ];
    setPayableEmployee(updatedData);
  }
  const handleRemove = (i) => {
    let data = JSON.parse(JSON.stringify(employeeData));
    data.splice(i, 1);
    setPayableEmployee(data);
  };
  
  const handleInputBlur = (i) => {
    toggleEditable(i);
  }
  return (
    <div className="w-full flex flex-col pt-[0px] gap-[20px]">
      <div className="flex flex-col gap-[15px]">
        <div className="flex items-center justify-between">
          <div className="flex font-inter-regular text-sm text-[#00000080]">
            Showing eligible staff payroll for 25 March, 2024 
            <span 
              className="font-inter-semibold text-[#000000B2] underline underline-offset-2 ml-2 cursor-pointer"
              onClick={() => setPageState(initiatePayrollState.initiatePayroll)}
            >
              Change Payroll Date
            </span>
          </div>
          <div className="relative flex">
            <img src={Search} className="w-[12] absolute top-[7px] left-[3px]" alt='search'/>
            <input
              type="text"
              name="searchName"
              className="w-[167px] h-[27px] border-[1px] border-[#00000040] rounded-md px-[18px] py-[8px] focus:outline-none focus:border-[#000000A0] text-[11px] font-inter-regular text-[#000000B2]"
              value={searchName}
              placeholder="Search for employee..."
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
        {showNote && 
          <div className="flex flex-col max-w-[400px] gap-[5px] border-[#F0B732] border-[1px] rounded-2xl bg-[#FCF1D6] px-[10px] py-[10px]">
            <div className="flex items-center justify-between w-full">
              <div className="flex gap-[10px] items-center">
                <img src={Warning} className="w-[18px]" alt="warning" />
                <span className="font-inter-semibold text-[12px] text-[#000000B2]" >Important</span>
              </div>
              <img src={CloseYellow} className="w-[18px] cursor-pointer mt-[-5px] mr-[0px]" alt="close" onClick={() => setShowNote(false)}/>
            </div>
            <div className="font-inter-regular text-[9px] text-[#00000080] pl-[25px]">
              Only employees with Status as Active or Leave of Absence are uploaded in payroll
            </div>
          </div>
        }
      </div>
      <div className="w-full">
        <div className="flex ml-[-10px] gap-[20px]">
          <table
            {...getTableProps()}
            className="w-full bg-white rounded-xl"
          >
            <thead className="bg-[#EDEDED]">
              {headerGroups.map(headerGroup => (
                <tr 
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map(column => (
                    <th 
                      scope="col" 
                      className={`px-8 py-6 
                        ${column.id === 'firstName' ? 'rounded-tl-xl' : ''}
                        ${column.id === 'action' ? 'rounded-tr-xl' : ''}
                      `}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="flex items-center gap-[5px] text-left font-inter-semibold text-sm text-[#000000B2]">
                        {column.render('Header')}
                        {column?.id !== 'selection' && column?.id !== 'action' && <img src={SortIcon} className="w-[8px]" />}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody 
              className="divide-y divide-[#7b78781a]" 
              {...getTableBodyProps()}
            >
              {page.map((row, i) => {
                prepareRow(row);
                const showMoreOptions = selectedRowIndex === i; // Check if more options popup should be displayed for this row
                return (
                  <Fragment key={row.id}>
                    <tr className="relative" {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <td 
                          className={
                            `
                              px-8 py-10 text-[#00000080] font-inter-medium text-sm
                              ${cell.column.Header === 'First Name' ? 'underline underline-offset-2 cursor-pointer' : ''}
                            `
                          }
                          {...cell.getCellProps()}
                          // onClick={() => {
                          //   if (cell.column.Header === 'First Name') {
                          //     setSelectedEmployeeIndex(i);
                          //     setPageState(employeeRecordState.employeeView);
                          //   }
                          // }}
                        >
                          {cell.column.Header === 'Name' && 
                            <img 
                                src={Employee}
                                className="w-[29px]"
                                alt="employee"
                            />
                          }
                          {cell.column.Header === 'Status' ? (
                              <div className="flex gap-[10px]">
                                  <div
                                    className={`
                                      ${(cell.column.Header === 'Status' && cell.row.original.status === 'active') ? 
                                        'bg-[#1DA39259] text-[#1DA392] font-inter-semibold' : 
                                        ''
                                      }
                                      ${(cell.column.Header === 'Status' && cell.row.original.status === 'leave') ? 
                                        'bg-[#FBDCAE] text-[#5D3208] font-inter-semibold' : 
                                        ''
                                      }
                                      ${(cell.column.Header === 'Status' && cell.row.original.status === 'terminated') ? 
                                        'bg-[#DEDEDE] text-[#343434] font-inter-semibold' : 
                                        ''
                                      }
                                      px-2 py-1 rounded-xl
                                    `}
                                  >
                                    {cell.row.original.status}
                                  </div>
                              </div>
                            ) : (
                                cell.column.Header === 'Action' ? (
                                  <div className="flex gap-[20px] items-center">
                                    
                                  </div>
                                ) : (
                                  cell.column.id === 'selection' ? (
                                    cell.render('Cell')
                                  ) : (
                                    isRowEditable(i) && cell.column.Header === 'Total Amount Payable' ? (
                                      <input
                                        type="text"
                                        value={cell.value}
                                        onChange={event => {handleInputChange(i, event)}}
                                        className="w-full"
                                        autoFocus
                                        onBlur={() => handleInputBlur(i)}
                                      />
                                    ) : (
                                      cell.render('Cell')
                                    )
                                  )
                                )
                              )
                          }
                        </td>
                      ))}
                      {showMoreOptions && ( // Render more options popup if showMoreOptions is true
                        <div className="p-[10px] flex flex-col absolute right-[20px] top-[80px] bg-[#edeaea] rounded-md z-[999]">
                          <button
                            onClick={() => {
                              toggleEditable(i);
                              setSelectedRowIndex(null);
                            }}

                          >
                            <div className="flex items-center gap-[5px]">
                              <img src={Edit} className="w-[10px]" alt='edit' />
                              <div className="font-inter-medium text-[10px] text-[#000000B2]">Edit</div>
                            </div>
                          </button>
                          <div className="py-[2px] mx-[-5px] border-b-[1px] border-[#00000026]"/>
                          <button className="pt-[4px]" onClick={() => handleRemove(i)}>
                            <div className="flex items-center gap-[5px]">
                              <img src={Remove} className="w-[10px]" alt='remove' />
                              <div className="font-inter-medium text-[10px] text-[#000000B2]">Remove</div>
                            </div>
                          </button>
                        </div>
                      )}
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <Pagination
          pageIndex={pageIndex}
          pageCount={pageCount}
          pageOptions={pageOptions}
          pageSize={pageSize}
          previousPage={previousPage}
          nextPage={nextPage}
          setPageSize={setPageSize}
        />
      </div>
    </div>
  );
};
export default AddEmployeePayroll;
