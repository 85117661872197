import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminContext } from "../../../contexts/AdminContext";
import Upload from "../../../assets/images/dashboard/svg/upload.svg";
import RightArrow from "../../../assets/images/dashboard/svg/right-arrow.svg";
import Dropdown from "../../../components/Dropdown/index";
import { AuthContext } from "../../../contexts/AuthContext";
import * as XLSX from "xlsx";
import { getEmployeeData, registerEmployeeBulk } from "../../../services/api";
import EmployeeAccountTable from "./EmployeeAccountTable";
import { employeeAccountState, userRole } from "../../../constants/constants";
import EditEmployee from "../EmployeeRecord/EditEmployee";

const dummyEmployee = [
  {
    firstName: "Peter",
    middleName: "Oscar",
    lastName: "Pan",
    email: "peterpan@gmail.com",
    phoneNumber: "+1 2328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "HR",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "23-03-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Peter Pan",
    bankAccountNumber: 3940392019,
    isBankAccountValidated: true,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000002,
    status: "active",
  },
  {
    firstName: "Simon",
    middleName: "Oscar",
    lastName: "Pegg",
    email: "simonpegg@gmail.com",
    phoneNumber: "+1 4328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "ICT",
    position: "UX designer",
    lineManager: "Marvin Ralph",
    hireDate: "14-05-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Simon Pegg",
    bankAccountNumber: 4920392019,
    isBankAccountValidated: true,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "active",
  },
  {
    firstName: "Susan",
    middleName: "",
    lastName: "Megg",
    email: "susanmegg@gmail.com",
    phoneNumber: "+1 2328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "HR",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "05-09-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Susan Megg",
    bankAccountNumber: 7940392013,
    isBankAccountValidated: true,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "active",
  },
  {
    firstName: "Philip",
    middleName: "Mae",
    lastName: "Olaitan",
    email: "philip@gmail.com",
    phoneNumber: "+1 9328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "Finance",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "30-07-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Peter Pan",
    bankAccountNumber: 3940392019,
    isBankAccountValidated: false,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "leave",
  },
  {
    firstName: "Sade",
    middleName: "Mae",
    lastName: "Akinlade",
    email: "akinalade@gmail.com",
    phoneNumber: "+1 2328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "HR",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "23-03-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Peter Pan",
    bankAccountNumber: 2300392019,
    isBankAccountValidated: false,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "terminated",
  },
  {
    firstName: "Sade",
    middleName: "Mae",
    lastName: "Akinlade",
    email: "akinalade@gmail.com",
    phoneNumber: "+1 2328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "HR",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "23-03-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Peter Pan",
    bankAccountNumber: 2300392019,
    isBankAccountValidated: false,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "leave",
  },
];
const AdminEmployeeAccount = () => {
  const { user } = useContext(AuthContext);
  const { employeeData, setEmployeeData } = useContext(AdminContext);
  const [pageState, setPageState] = useState(
    employeeAccountState.employeeTable
  );
  const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(0);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  // useEffect(() => {
  //   setEmployeeData(dummyEmployee);
  // }, []);

  const fetchEmployeeData = async (companyName) => {
    const res = await getEmployeeData(companyName);

    if (res?.length > 0) {
      setEmployeeData(res);
    }
  };
  useEffect(() => {
    if (Object.keys(user).length > 0 && !employeeData) {
      fetchEmployeeData(user.companyName);
    }
  }, [user]);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      fetchEmployeeData(user.companyName);
    }
  }, [triggerFetch]);

  const handleUploadCSVClick = () => {
    fileInputRef.current.click();
  };
  const handleUpload = async (fileData) => {
    const workbook = XLSX.read(fileData, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    // Handle the parsed Excel data
    console.log("Parsed data:", data);
    let payload = [];
    if (data?.length > 1) {
      for (let i = 1; i < data?.length; i++) {
        payload.push({
          firstName: data[i][0],
          middleName: data[i][1],
          lastName: data[i][2],
          email: data[i][3],
          department: data[i][4],
          grossSalary: data[i][5],
          bankName: data[i][6],
          bankAccountName: data[i][7],
          bankAccountNumber: data[i][8],
          destinationcode: data[i][9],
          companyName: user.companyName,
          role: userRole.employee,
          password: "111111",
        });
      }
      const res = await registerEmployeeBulk(payload);
      if (res) {
        setTriggerFetch((prev) => !prev);
      }
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileData = e.target.result;
        handleUpload(fileData);
      };
      reader.readAsBinaryString(file);
    }
  };
  return (
    <div className="w-full">
      <div className="text-[20px] font-inter-bold text-[#011165]">
        Employee Management
      </div>
      <div className="mt-[12px] border-b-[1px] border-[#0000001A]" />
      <div className="w-full flex flex-col gap-[20px] items-center justify-between py-[30px]">
        {pageState === employeeAccountState.employeeTable && (
          <>
            <div className="w-full flex justify-between gap-[10px] items-center">
              <div className="font-inter-bold text-base text-[#404040]">
                Employee Accounts
              </div>
              <div className="flex gap-[10px]">
                <button
                  className="rounded-md font-inter-bold text-[10px] text-[#011165] p-[10px]"
                  onClick={() => {
                    navigate("/admin/employee-management/add-employee");
                  }}
                >
                  <span className="text-[14px]">+</span> ADD EMPLOYEE
                </button>
                <div>
                  <input
                    ref={fileInputRef}
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                </div>
                <button
                  className="flex gap-[8px] items-center rounded-md font-inter-bold text-[10px] text-white p-[10px] px-[15px] bg-[#011165]"
                  onClick={handleUploadCSVClick}
                >
                  <img src={Upload} className="w-[20px]" alt="upload" />
                  UPLOAD CSV
                </button>
                {/* <button 
                  className="rounded-md font-inter-bold text-[10px] text-[#A6A6A6] p-[10px] px-[15px] bg-[#01116526]"
                  onClick={() => {
                  }}
                  disabled={true}
                >
                  EDIT
                </button> */}
              </div>
            </div>
            {employeeData && employeeData?.length > 0 && (
              <EmployeeAccountTable
                employeeData={employeeData}
                setEmployeeData={setEmployeeData}
                setSelectedEmployeeIndex={setSelectedEmployeeIndex}
                setPageState={setPageState}
              />
            )}
          </>
        )}
        {pageState === employeeAccountState.employeeEdit && (
          <EditEmployee
            employee={employeeData[selectedEmployeeIndex]}
            setPageState={setPageState}
            setTriggerFetch={setTriggerFetch}
          />
        )}
      </div>
    </div>
  );
};
export default AdminEmployeeAccount;
