import { useContext, useEffect, useState } from "react";
import Modal from "../../../components/Modal/index";
import Info from "../../../assets/images/dashboard/svg/info.svg";
import ThreeDots from "../../../assets/images/dashboard/svg/three-dots.svg";
import SaveIcon from "../../../assets/images/dashboard/svg/save.svg";
import PrintIcon from "../../../assets/images/dashboard/svg/print.svg";
import DownloadIcon from "../../../assets/images/dashboard/svg/download2.svg";
import { formatDate, getMonthFromDate } from "../../../utils/helper";
import { EmployeeContext } from "../../../contexts/EmployeeContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { getPaymentHistory } from "../../../services/api";
const dummyPaymentSummary = {
  paymentSummary: [
    {
      date: '2024-01',
      payeeName: 'Peter Pan',
      NHFduduction: 5000,
      pension: 25000,
      mortgage: 15000,
      netPay: 250000,
      status: 'Completed',
    },
    {
      date: '2024-02',
      payeeName: 'Peter Pan2',
      NHFduduction: 5000,
      pension: 25000,
      mortgage: 15000,
      netPay: 300000,
      status: 'Completed',
    },
    {
      date: '2024-03',
      payeeName: 'Peter Pan',
      NHFduduction: 5000,
      pension: 25000,
      mortgage: 15000,
      netPay: 350000,
      status: 'Completed',
    },
    {
      date: '2024-01',
      payeeName: 'Peter Pan',
      NHFduduction: 5000,
      pension: 25000,
      mortgage: 15000,
      netPay: 250000,
      status: 'Completed',
    },
    {
      date: '2024-02',
      payeeName: 'Peter Pan',
      NHFduduction: 5000,
      pension: 25000,
      mortgage: 15000,
      netPay: 300000,
      status: 'Completed',
    },
    {
      date: '2024-03',
      payeeName: 'Peter Pan',
      NHFduduction: 5000,
      pension: 25000,
      mortgage: 15000,
      netPay: 350000,
      status: 'Completed',
    },
    {
      date: '2024-02',
      payeeName: 'Peter Pan',
      NHFduduction: 5000,
      pension: 25000,
      mortgage: 15000,
      netPay: 300000,
      status: 'Completed',
    },
    {
      date: '2024-03',
      payeeName: 'Peter Pan',
      NHFduduction: 5000,
      pension: 25000,
      mortgage: 15000,
      netPay: 350000,
      status: 'Completed',
    },
  ],
  upcommingEvents: [
    {
      eventName: '3 Day Vacation',
      eventDate: '2024-04-17',
    },
    {
      eventName: 'Hiking',
      eventDate: '2024-04-17',
    },
    {
      eventName: 'Picnic',
      eventDate: '2024-04-17',
    },
    {
      eventName: 'Independence day',
      eventDate: '2024-04-17',
    },
  ]
}
const PaySlipCard = (props) => {
  const { data, onClick } = props;
  console.log(data)
  return (
    <div className="w-1/6">
      <div className="m-[10px] px-[13px] py-[18px] border-[1px] border-[#EFF0F6] rounded-xl cursor-pointer  hover:scale-110 transition-transform" onClick={onClick}>
        <div className="flex items-center justify-between">
          <div className="font-inter-medium font-[#00000080] text-[13px]">{getMonthFromDate(data.fromDate)}</div>
          <img src={ThreeDots} className="w-[3px] cursor-pointer" alt="detail" />
        </div>
        <div className="pt-[10px] font-eina02-bold text-[18px] text-[#000000B2]">
          &#8358; {data.netPay}
        </div>
      </div>
    </div>
  )
}
const Payslips = () => {
  const [selectedYear, setSelectedYear] = useState("2024");
  const [modalData, setModalData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const { user } = useContext(AuthContext);
  const { paymentHistory, setPaymentHistory } = useContext(EmployeeContext);
  useEffect(() => {
    const fetchPaymentHistory = async (userId) => {
      const res = await getPaymentHistory(userId);
      if (res?.length > 0) {
        setPaymentHistory(res);
      }
    }
    if (Object.keys(user).length > 0 && !paymentHistory) {
      fetchPaymentHistory(user.userId);
    }
  }, [user, paymentHistory]);
  
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSave = () => {
    // Add logic to save data here
    console.log('Save button clicked');
    // Here you can perform the save operation
    alert('Data saved successfully!');
  };

  const handlePrint = () => {
    // Here you can perform the print operation
    window.print();
  };

  const handleDownload = () => {
    // Here you can perform the download operation
    // For example, create a blob URL and trigger download
    const data = JSON.stringify(modalData);
    const blob = new Blob([data], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `paySlipsData-${modalData?.date}.json`;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };
  return (
    <>
      <div className="w-full">
        <div className="text-[20px] font-eina02-semibold">Pay Slips</div>
        <div className="mt-[12px] border-b-[1px] border-[#0000001A]" />
        <div className="w-full flex items-center gap-[15px] py-[20px]">
          <img src={Info} className="w-[19px]" alt="info" />
          <div className="font-inter-regular text-[10px] text-[#00000080]">
            Showing pay slips for {selectedYear}. To show results for a previous year, change timeframe
          </div>
        </div>
        <div className="flex flex-wrap pt-[10px]">
          {paymentHistory && paymentHistory?.length > 0 && paymentHistory.map((payData, index) => {
            return (
              <PaySlipCard 
                data={payData} 
                onClick={() => {
                  setModalData(paymentHistory[index]);
                  console.log(index);
                  setShowModal(true);
                }}
                key={index}
              />
            )
          })}
        </div>
      </div>
      <Modal isOpen={showModal} onClose={handleCloseModal} title="Modal Heade">
        <div className="font-inter-medium text-[24px] text-[#000000B2] pb-[12px]">Payment summary</div>
        <div className="border-b-[1px] border-[#D9D9D9] mx-[-40px]" />
        <div className="flex pt-[30px]">
          <div className="w-3/5 font-inter-regular text-[14px] text-[#404040]">Date</div>
          <div className="font-inter-bold text-[16px] text-[#404040]">{formatDate(modalData?.fromDate, modalData?.toDate)}</div>
        </div>
        <div className="flex pt-[30px]">
          <div className="w-3/5 font-inter-regular text-[14px] text-[#404040]">Payee</div>
          <div className="font-inter-bold text-[16px] text-[#404040]">{modalData?.payeeName}</div>
        </div>
        {modalData?.deduction?.length > 0 && 
          modalData.deduction.map((data) => {
            return (
              <div className="flex pt-[30px]">
                <div className="w-3/5 font-inter-regular text-[14px] text-[#404040]">{data.type}</div>
                <div className="font-inter-bold text-[16px] text-[#404040]">&#8358; {data.amount}</div>
              </div>
            )
          })
        }
        {/* <div className="flex pt-[30px]">
          <div className="w-3/5 font-inter-regular text-[14px] text-[#404040]">NHF deductions</div>
          <div className="font-inter-bold text-[16px] text-[#404040]">&#8358; {modalData?.NHFduduction}</div>
        </div>
        <div className="flex pt-[30px]">
          <div className="w-3/5 font-inter-regular text-[14px] text-[#404040]">Pension</div>
          <div className="font-inter-bold text-[16px] text-[#404040]">&#8358; {modalData?.pension}</div>
        </div>
        <div className="flex pt-[30px]">
          <div className="w-3/5 font-inter-regular text-[14px] text-[#404040]">Mortgage</div>
          <div className="font-inter-bold text-[16px] text-[#404040]">&#8358; {modalData?.mortgage}</div>
        </div> */}
        <div className="border-b-[1px] border-[#D9D9D9] mx-[-40px] pt-[30px]" />
        <div className="flex pt-[30px]">
          <div className="w-3/5 font-inter-regular text-[14px] text-[#404040]">Net Pay</div>
          <div className="font-inter-bold text-[16px] text-[#1DA392]">&#8358; {modalData?.netPay}</div>
        </div>
        <div className="border-b-[1px] border-[#D9D9D9] mx-[-40px] pt-[30px]" />
        <div className="flex gap-[50px] justify-center items-center pt-[30px]">
          <div className="flex gap-[5px] items-center cursor-pointer" onClick={handleSave}>
            <img src={SaveIcon} className="w-[19px]" alt="save" />
            <div className="font-inter-regular text-[13px] text-black">Save</div>
          </div>
          <div className="flex gap-[5px] items-center cursor-pointer" onClick={handlePrint}>
            <img src={PrintIcon} className="w-[19px]" alt="print" />
            <div className="font-inter-regular text-[13px] text-black">Print</div>
          </div>
          <div className="flex gap-[5px] items-center cursor-pointer" onClick={handleDownload}>
            <img src={DownloadIcon} className="w-[19px]" alt="download" />
            <div className="font-inter-regular text-[13px] text-black">Download</div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default Payslips;
