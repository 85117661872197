import AdminApprovers from "../pages/AdminPage/ApprovalManagement/index";
import AdminApprove from "../pages/AdminPage/Approve/index";
import AdminDashboard from "../pages/AdminPage/Dashboard/index";
import AddEmployee from "../pages/AdminPage/EmployeeAccount/AddEmployee";
import AdminEmployeeAccount from "../pages/AdminPage/EmployeeAccount/index";
import EmployeeView from "../pages/AdminPage/EmployeeRecord/EmployeeView";
import AdminEmployeeRecord from "../pages/AdminPage/EmployeeRecord/index";
import InitiatePayrollPage from "../pages/AdminPage/InitiatePayroll/index";
import AdminDeduction from "../pages/AdminPage/PaySlips/Deduction";
import AdminPaySlips from "../pages/AdminPage/PaySlips/index";
import AdminPaymentSetting from "../pages/AdminPage/PaymentSetting/index";
import PayrollEmployeeBands from "../pages/AdminPage/PayrollEmployeeBands/index";
import AdminPayrollHistory from "../pages/AdminPage/PayrollHistory/index";
import PayrollVacation from "../pages/AdminPage/PayrollVacation/index";
import PayrollViewBands from "../pages/AdminPage/PayrollViewBands/index";
import AdminVacations from "../pages/AdminPage/VacationManagement/index";
import AccountDetail from "../pages/EmployeePage/AccountDetail/index";
import EditProfile from "../pages/EmployeePage/EditProfile/index";
import SecuritySetting from "../pages/EmployeePage/SecuritySetting/index";

export const adminRoutes = [
  { path: ``, Component: <AdminDashboard /> },
  { path: `dashboard`, Component: <AdminDashboard /> },
  { path: `employee-management`, Component: <AdminEmployeeAccount /> },
  { path: `employee-management/accounts`, Component: <AdminEmployeeAccount /> },
  { path: `employee-management/add-employee`, Component: <AddEmployee /> },
  { path: `employee-management/records`, Component: <AdminEmployeeRecord /> },
  { path: `employee-management/employee-view`, Component: <EmployeeView /> },
  { path: `payroll-management`, Component: <InitiatePayrollPage /> },
  {
    path: `payroll-management/initiate-payroll`,
    Component: <InitiatePayrollPage />,
  },
  { path: `payroll-management/approve`, Component: <AdminApprove /> },
  { path: `payroll-management/history`, Component: <AdminPayrollHistory /> },
  { path: `payroll-management/vacation`, Component: <PayrollVacation /> },
  {
    path: `payroll-management/employeebands`,
    Component: <PayrollEmployeeBands />,
  },
  { path: `payroll-management/viewbands`, Component: <PayrollViewBands /> },
  { path: `payment-setting`, Component: <AdminPaymentSetting /> },
  { path: `payslips`, Component: <AdminPaySlips /> },
  { path: `payslips/deduction`, Component: <AdminDeduction /> },
  { path: `approval-management`, Component: <AdminApprovers /> },
  { path: `approval-management/approver`, Component: <AdminApprovers /> },
  { path: `approval-management/vacation`, Component: <AdminVacations /> },
  { path: `account/`, Component: <AccountDetail /> },
  { path: `account/details`, Component: <AccountDetail /> },
  { path: `account/edit-profile`, Component: <EditProfile /> },
  { path: `account/setting`, Component: <SecuritySetting /> },
];
