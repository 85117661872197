import { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AdminSidebar from "../AdminSidebar/index";
import Header from "../../Header/index";
import Sidebar from "../../Employee/EmployeeSidebar/index";
import { AuthContext } from "../../../../contexts/AuthContext";
import { authUser } from "../../../../services/api";

const EmployeeLayout = () => {
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    const checkAuth = async () => {
      let refreshToken = ''
      const storage = localStorage.getItem("refreshToken")
      if (storage) {
        refreshToken = JSON.parse(storage)?.refreshToken?.token
      }
      const res = await authUser({ token: refreshToken });
      console.log(res);
      if (res.status === 'success') {
        setUser(res.data);
      } else {
        navigate("/login");
      }
    }
    if (Object.keys(user).length === 0) {
      checkAuth();
    }
  }, []);
  return (
    <div className="w-full h-full">
      <Header />
      <div 
        className="flex w-full"
        style={{ height: 'calc(100% - 50px)'}}
      >
        <AdminSidebar />
        <div className="bg-[#F9F9F9] w-full h-full px-[45px] py-[20px]">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
export default EmployeeLayout;
