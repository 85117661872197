import { useContext, useState } from "react";
import HidePassword from "../../../assets/images/dashboard/svg/password-hide.svg";
import { AuthContext } from "../../../contexts/AuthContext";
import { changePassword } from "../../../services/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const SecuritySetting = () => {
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState({});
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const validateForm = (formData) => {
    let errors = {};
    if (!formData.currentPassword) {
      errors.currentPassword = "Password is required";
    }
    if (!formData.newPassword) {
      errors.newPassword = "New password is required";
    }

    if (!formData.confirmPassword) {
      errors.confirmPassword = "You need to confirm your password";
    } else if (formData.confirmPassword !== formData.newPassword) {
      errors.confirmPassword = "Passwords do not match";
    }
    return errors;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
      try {
        const res = await changePassword({
          userId: user.userId,
          currentPassword: formData.currentPassword,
          newPassword: formData.newPassword,
        });

        toast.success("Password changed successfully!");
      } catch (error) {
        toast.error("An error occurred. Please try again.");
      }
    } else {
      setErrors(validationErrors);
      Object.values(validationErrors).forEach((error) => toast.error(error));
    }
  };
  return (
    <div className="w-full">
      <ToastContainer />
      <div className="text-[20px] font-eina02-semibold">Account</div>
      <div className="mt-[12px] border-b-[1px] border-[#0000001A]" />
      <div className="w-[629px] flex flex-col mt-[20px] ml-[80px] pt-[15px] px-[50px] pb-[30px] border-[1px] border-[#EFF0F6] rounded-2xl">
        <form onSubmit={handleSubmit}>
          <div className="flex items-start justify-between pt-[15px]">
            <div className="font-inter-medium text-[#000000B2] text-[16px]">
              Security settings
            </div>
            <div className="flex gap-[10px]">
              <button
                className="flex justify-center py-[7px] px-[10px] rounded-lg text-[#011165] font-inter-semibold text-[11px] bg-[#ffffff] border-[1px] border-[#011165]"
                onClick={() => {}}
              >
                Cancel
              </button>
              <button
                className="flex justify-center py-[7px] px-[10px] rounded-lg text-white font-inter-semibold text-[11px] bg-[#011165]"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
          <div className="mt-[15px] mx-[-50px] border-b-[1px] border-[#0000001A]" />
          <div className="pt-[15px] font-inter-medium text-[14px] text-[#000000B2]">
            Change password
          </div>
          <div className="flex flex-col gap-[15px] pt-[30px]">
            <div className="flex flex-col gap-[5px] font-inter-medium text-[14px] text-[#00000080]">
              <div className="font-inter-medium">Enter current password</div>
              <div className="flex w-[340px] border-[1px] border-[#00000040] rounded-md px-[5px]">
                <input
                  type={showCurrentPassword ? "text" : "password"}
                  name="currentPassword"
                  className="w-[328px] px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                  value={formData.currentPassword}
                  onChange={handleInputChange}
                />
                <img
                  src={showCurrentPassword ? HidePassword : HidePassword}
                  className="w-[19px]"
                  alt="show"
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                />
              </div>
              {errors?.currentPassword && (
                <span style={{ color: "red" }}>{errors?.currentPassword}</span>
              )}
            </div>
            <div className="flex flex-col gap-[5px] font-inter-medium text-[14px] text-[#00000080]">
              <div className="font-inter-medium">New password</div>
              <div className="flex w-[340px] border-[1px] border-[#00000040] rounded-md px-[5px]">
                <input
                  type={showNewPassword ? "text" : "password"}
                  name="newPassword"
                  className="w-[328px] px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                  value={formData.newPassword}
                  onChange={handleInputChange}
                />
                <img
                  src={showNewPassword ? HidePassword : HidePassword}
                  className="w-[19px]"
                  alt="show"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                />
              </div>
              {errors?.newPassword && (
                <span style={{ color: "red" }}>{errors?.newPassword}</span>
              )}
            </div>
            <div className="flex flex-col gap-[5px] font-inter-medium text-[14px] text-[#00000080]">
              <div className="font-inter-medium">Confirm password</div>
              <div className="flex w-[340px] border-[1px] border-[#00000040] rounded-md px-[5px]">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  className="w-[328px] px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                />
                <img
                  src={showConfirmPassword ? HidePassword : HidePassword}
                  className="w-[19px]"
                  alt="show"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              </div>
              {errors?.confirmPassword && (
                <span style={{ color: "red" }}>{errors?.confirmPassword}</span>
              )}
            </div>
          </div>
          <div className="mt-[15px] mx-[-50px] pt-[50px] border-b-[1px] border-[#0000001A]" />
          <div className="flex flex-col gap-[18px] pt-[30px]">
            <div className="font-inter-medium text-[14px] text-[#000000B2]">
              Multifactor authentication
            </div>
            <div className="font-inter-regular text-[13px] text-[#00000080]">
              Enabling MFA will send an OTP to your registered email
            </div>
            <div className="flex gap-[15px] items-center">
              <input
                type="checkbox"
                name="enableMFA"
                className=""
                value={formData.enableMFT}
                onChange={handleInputChange}
              />
              <div className="font-inter-medium text-[14px] text-[#000000B2]">
                Enable MFA
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default SecuritySetting;
