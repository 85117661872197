import { useContext, useEffect, useMemo, useState } from "react";
import { useTable, useSortBy } from "react-table";
import { AdminContext } from "../../../contexts/AdminContext";

import SortIcon from "../../../assets/images/dashboard/svg/sort-icon.svg";
import Download from "../../../assets/images/dashboard/svg/download.svg";
import Vacation from "../../../assets/images/dashboard/svg/vacation-icon.svg";
import Employee from "../../../assets/images/dashboard/svg/employee-sample.svg";
import Clock from "../../../assets/images/dashboard/svg/clock.svg";
import Check from "../../../assets/images/dashboard/svg/check.svg";
import { dashboardState } from "../../../constants/constants";
import {
  formatEventDate,
  getMonthFromDate,
  getYearFromDate,
} from "../../../utils/helper";

const EventRow = (props) => {
  const { event } = props;
  return (
    <div className="w-full py-[13px] flex justify-between items-center">
      <div className="flex gap-[15px] items-center">
        <img
          src={Vacation}
          className="w-[51px] cursor-pointer"
          alt="vacation"
        />
        <div className="">
          <div className="font-eina02-semibold text-[14px] text-[#404040]">
            {event.eventName}
          </div>
          <div className="font-eina02-regular text-[14px] text-[#757575]">
            {formatEventDate(event.eventDate)}
          </div>
        </div>
      </div>
      <div className="flex gap-[8px] items-center">
        <div className="font-eina02-semibold text-[10px] text-[#404040] cursor-pointer">
          {event.eventCategory}
        </div>
      </div>
    </div>
  );
};

const PayrollDetail = (props) => {
  const {
    payrollHistory,
    approvalHistory,
    setPageState,
    selectedMonthData,
    nextMonthData,
  } = props;
  const data = payrollHistory;

  const COLUMNS = [
    { Header: "Employee", accessor: "payeeName" },
    { Header: "Department", accessor: "department" },
    { Header: "Amount Paid", accessor: "netPay" },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        data,
        columns,
      },
      useSortBy
    );
  console.log(data);
  // useEffect(() => {
  //   console.log(headerGroups)
  // }, [headerGroups]);
  return (
    <div className="w-full pt-[20px]">
      <div className="flex ml-[-10px] gap-[20px]">
        <div className="w-2/3">
          <div className="flex gap-[25px] pb-[20px]">
            <div className="flex w-1/2 flex-col justify-between rounded-2xl bg-white py-[20px] px-[25px]">
              <div className="flex flex-col gap-[10px]">
                <div className="flex justify-between">
                  <span className="font-inter-regular text-xs text-[#00000073]">
                    Total Amount Paid
                  </span>
                  <span className="font-inter-regular text-xs text-[#000000B2]">
                    {getMonthFromDate(selectedMonthData.date)}{" "}
                    {getYearFromDate(selectedMonthData.date)}
                  </span>
                </div>
                <div className="font-eina02-bold text-[20px] text-[#000000B2]">
                  &#8358; {selectedMonthData.totalAmount}
                </div>
              </div>
              <div className="flex justify-end">
                <img
                  src={Download}
                  className="w-[26px] cursor-pointer"
                  alt="download"
                />
              </div>
            </div>
            {nextMonthData && (
              <div className="flex w-1/2 flex-col gap-[10px] rounded-2xl bg-white py-[20px] px-[25px]">
                <div className="flex justify-between">
                  <span className="font-inter-regular text-xs text-[#00000073]">
                    Total Amount Paid
                  </span>
                  <span className="font-inter-regular text-xs text-[#000000B2]">
                    {nextMonthData.date}
                  </span>
                </div>
                <div className="font-eina02-bold text-[20px] text-[#000000B2]">
                  &#8358; {nextMonthData.totalAmount}
                </div>
              </div>
            )}
          </div>
          <table {...getTableProps()} className="w-full bg-white rounded-xl">
            <thead className="bg-[#EDEDED]">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      scope="col"
                      className={`px-8 py-6 
                        ${column.Header === "Employee" ? "rounded-tl-xl" : ""}
                        ${
                          column.Header === "Amount Paid" ? "rounded-tr-xl" : ""
                        }
                      `}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="flex gap-[5px] text-left font-inter-semibold text-sm text-[#000000B2]">
                        {column.render("Header")}
                        <img src={SortIcon} className="w-[8px]" />
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              className="divide-y divide-[#7b78781a]"
              {...getTableBodyProps()}
            >
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        className={`
                            px-8 py-6 text-[#00000080] font-inter-medium text-sm
                            ${
                              cell.column.Header === "Employee"
                                ? "flex items-center justify-start gap-[5px]"
                                : ""
                            }
                          `}
                        {...cell.getCellProps()}
                      >
                        {/* {cell.column.Header === "Employee" && (
                          <img
                            src={Employee}
                            className="w-[29px]"
                            alt="employee"
                          />
                        )} */}
                        {cell.column.Header === "Amount Paid"
                          ? `₦ ${cell.value}`
                          : cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="w-1/3 min-w-[500px] flex flex-col gap-[25px]">
          <div className="border-[#EFF0F6] border-[0.92px] rounded-2xl">
            <div className="flex justify-between items-center w-full px-[40px] py-[15px] border-b-[1px] border-[#EFF0F6]">
              <div className="font-eina02-semibold text-[16px] text-[#404040]">
                Approval History
              </div>
            </div>
            <div className="px-[40px] py-[20px]">
              {Object.keys(approvalHistory).length > 0 &&
                approvalHistory.map((history) => {
                  return (
                    <div className=" w-[320px] flex justify-between py-[10px]">
                      <div className="flex flex-col gap-[5px] px-[10px]">
                        <div className="flex items-center gap-[20px]">
                          <span className="uppercase font-inter-bold text-sm text-[#011165]">
                            {history.status}
                          </span>
                          <span className="uppercase font-inter-bold text-xs text-[#00000033]">
                            Payroll was approved
                          </span>
                        </div>
                        <div className="flex items-center justify-between gap-[20px]">
                          <div className="flex items-center gap-[10px]">
                            <img
                              src={Clock}
                              className="w-[16px] cursor-pointer"
                              alt="clock"
                            />
                            <span className="uppercase font-inter-medium text-sm text-[#00000080]">
                              {history.date}
                            </span>
                          </div>
                          <div className="flex items-center gap-[10px]">
                            <img
                              src={Employee}
                              className="w-[16px] cursor-pointer"
                              alt="person"
                            />
                            <span className="uppercase font-inter-medium text-sm text-[#00000080]">
                              {history.name}
                            </span>
                          </div>
                        </div>
                      </div>
                      <img src={Check} className="w-[24px]" alt="approved" />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PayrollDetail;
