import { useContext, useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, useRowSelect, usePagination } from "react-table";
import { EmployeeContext } from "../../../contexts/EmployeeContext";
import Modal from "../../../components/Modal/index";
import Pagination from "../../../components/Pagination/index";
import SortIcon from "../../../assets/images/dashboard/svg/sort-icon.svg";
import LeftArrow from "../../../assets/images/dashboard/svg/left-arrow.svg";
import RightArrow from "../../../assets/images/dashboard/svg/right-arrow.svg";
import Employee from "../../../assets/images/dashboard/svg/employee-sample.svg";
import Edit from "../../../assets/images/dashboard/svg/edit.svg";
import ValidataActive from "../../../assets/images/dashboard/svg/validate-active.svg";
import ValidataInactive from "../../../assets/images/dashboard/svg/validate-inactive.svg";
import { employeeAccountState } from "../../../constants/constants";
import { getCheckBankAccountValidation } from "../../../services/api";
import { NotificationContext } from "../../../contexts/NotificationContext";
const EmployeeAccountTable = (props) => {
  const {
    employeeData,
    setSelectedEmployeeIndex,
    setPageState,
    setEmployeeData,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editableRows, setEditableRows] = useState(new Set()); // State to track editable rows
  const { bankList } = useContext(EmployeeContext);
  const isRowEditable = (rowIndex) => editableRows.has(rowIndex);
  const { addNotification } = useContext(NotificationContext);
  const data = employeeData ?? [];
  const COLUMNS = [
    {
      id: "selection",
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <div className="">
          <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
        </div>
      ),
      Cell: ({ row }) => (
        <input
          type="checkbox"
          className=""
          {...row.getToggleRowSelectedProps()}
        />
      ),
    },
    {
      Header: "Name",
      accessor: (row) =>
        `${row.firstName} ${row.middleName ?? ""} ${row.lastName}`,
    },
    { Header: "Department", accessor: "department" },
    { Header: "Email Address", accessor: "email" },
    { Header: "Gross Salary", accessor: (row) => `₦ ${row.grossSalary}` },
    {
      Header: "Bank Details",
      accessor: (row) => `${row.bankName} | ${row.bankAccountNumber}`,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <div
          className={`px-2 py-1 rounded-xl text-white font-inter-semibold ${
            row.original.status === "Validated"
              ? "bg-green-500"
              : row.original.status === "Pending"
              ? "bg-orange-500"
              : "bg-gray-300"
          }`}
        >
          {row.original.status}
        </div>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <div className="flex gap-[20px] items-center">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
            onClick={() => {
              setSelectedEmployeeIndex(row.index);
              setPageState(employeeAccountState.employeeEdit);
            }}
          >
            Edit
          </button>
          <button
            disabled={row.original.status === "Validated"}
            className={`font-bold py-1 px-2 rounded text-white ${
              row.original.status === "Validated"
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-green-500 hover:bg-green-700"
            }`}
            onClick={() => {
              if (row.original.status === "Check") {
              }
            }}
          >
            {row.original.status === "Check" ? "Validate" : "Validated"}
          </button>
        </div>
      ),
    },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    // rows,
    prepareRow,
    state: { pageIndex, pageSize },
    selectedFlatRows,
    pageOptions,
    pageCount,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 5 }, // Initial pagination state
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    setSelectedRows(selectedFlatRows?.map((row) => row.original));
  }, [selectedFlatRows, setSelectedRows]);

  useEffect(() => {}, [pageIndex]);

  const toggleEditable = (rowIndex) => {
    setEditableRows((prevState) => {
      const newSet = new Set(prevState);
      if (newSet.has(rowIndex)) {
        newSet.delete(rowIndex);
      } else {
        newSet.add(rowIndex);
      }
      return newSet;
    });
  };

  const fetchCheckBankAccountValidation = async (selectedEmployee) => {
    const payload = {
      accountNumber: selectedEmployee.bankAccountNumber, // Assuming 'companyName' should be 'bankAccountNumber'
      destinationCode: selectedEmployee.destinationcode, // Assuming 'destinationCode' should be 'bankName'
      userId: selectedEmployee.userId,
    };

    try {
      const res = await getCheckBankAccountValidation(payload);

      if (res.description === "Success") {
        // Map over employeeData to update the status
        const newData = employeeData.map((employee) =>
          employee.userId === selectedEmployee.userId
            ? { ...employee, isBankAccountValidated: true }
            : employee
        );
        addNotification("Bank account successfully validated!"); // Set notification message
        setEmployeeData(newData); // Use the state setter to update the state
      } else {
        // Handle other cases, such as Pending or failure states
        const newData = employeeData.map((employee) =>
          employee.userId === selectedEmployee.userId
            ? { ...employee, isBankAccountValidated: false }
            : employee
        );
        setEmployeeData(newData);
        addNotification("Validation failed, please try again."); // Notify user of failure
      }
    } catch (error) {
      console.error("Error validating bank account:", error);
      addNotification("An error occurred during validation."); // Notify user of error
    }
  };

  const handleCheckBankAccountNumber = () => {
    fetchCheckBankAccountValidation(setSelectedEmployeeIndex);
  };
  return (
    <>
      <div className="w-full">
        <div className="flex ml-[-10px] gap-[20px]">
          <table {...getTableProps()} className="w-full bg-white rounded-xl">
            <thead className="bg-[#EDEDED]">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      scope="col"
                      className={`px-8 py-6 
                        ${column.id === "selection" ? "rounded-tl-xl" : ""}
                        ${column.Header === "Action" ? "rounded-tr-xl" : ""}
                      `}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="flex items-center gap-[5px] text-left font-inter-semibold text-sm text-[#000000B2]">
                        {column.render("Header")}
                        {column?.id !== "selection" && (
                          <img src={SortIcon} className="w-[8px]" />
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              className="divide-y divide-[#7b78781a]"
              {...getTableBodyProps()}
            >
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        className={`
                            px-8 py-10 text-[#00000080] font-inter-medium text-sm
                            ${
                              cell.column.Header === "Name"
                                ? "flex items-center justify-start gap-[5px]"
                                : ""
                            }
                          `}
                        {...cell.getCellProps()}
                      >
                        {/* {cell.column.Header === "Name" && (
                          <img
                            src={Employee}
                            className="w-[29px]"
                            alt="employee"
                          />
                        )} */}
                        {cell.column.Header === "Status" ? (
                          <div className="flex gap-[10px]">
                            <div
                              className={`
                                    ${
                                      cell.column.Header === "Status" &&
                                      cell.row.original
                                        .isBankAccountValidated === true
                                        ? "bg-[#1DA39259] text-[#1DA392] font-inter-semibold"
                                        : ""
                                    }
                                    ${
                                      cell.column.Header === "Status" &&
                                      cell.row.original
                                        .isBankAccountValidated === false
                                        ? "bg-[#FBDCAE] text-[#5D3208] font-inter-semibold"
                                        : ""
                                    }
                                    px-2 py-1 rounded-xl
                                  `}
                            >
                              {cell.row.original.isBankAccountValidated
                                ? "Validated"
                                : "Pending"}
                            </div>
                          </div>
                        ) : cell.column.Header === "Action" ? (
                          <div className="flex gap-[20px] items-center">
                            <img
                              src={Edit}
                              className="w-[19px] cursor-pointer"
                              alt="edit"
                              onClick={() => {
                                console.log(cell, i);
                                setSelectedEmployeeIndex(i);
                                setPageState(employeeAccountState.employeeEdit);
                                // toggleEditable(i);
                              }}
                            />
                            {row.original.isBankAccountValidated ? (
                              <button
                                disabled
                                className=" font-inter-medium opacity-50 cursor-not-allowed bg-payroll_primary  text-white py-1 px-2 rounded-md"
                              >
                                Validated
                              </button>
                            ) : (
                              <button
                                className=" font-inter-medium bg-payroll_primary  hover:bg-green-700 text-white py-1 px-2 rounded-lg active:opacity-30"
                                onClick={() => {
                                  fetchCheckBankAccountValidation(row.original);
                                }}
                              >
                                Validate
                              </button>
                            )}
                          </div>
                        ) : cell.column.id === "selection" ? (
                          cell.render("Cell")
                        ) : isRowEditable(i) ? (
                          <input
                            type="text"
                            value={cell.value}
                            onChange={(e) => {
                              // You'll need to update your data here
                              data[row.index][cell.column.id] = e.target.value;
                            }}
                            className="w-full"
                          />
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Pagination
          pageIndex={pageIndex}
          pageCount={pageCount}
          pageOptions={pageOptions}
          pageSize={pageSize}
          previousPage={previousPage}
          nextPage={nextPage}
          setPageSize={setPageSize}
        />
      </div>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title="Modal Heade"
      >
        <div className="h-[542px]"></div>
      </Modal>
    </>
  );
};
export default EmployeeAccountTable;
