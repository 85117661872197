import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import Back from "../../../assets/images/dashboard/svg/back.svg";
import Next from "../../../assets/images/dashboard/svg/next.svg";
import LeftArrow from "../../../assets/images/dashboard/svg/left-arrow.svg";
import Terminate from "../../../assets/images/dashboard/svg/terminate-black.svg";
import Active from "../../../assets/images/dashboard/svg/active.svg";
import Leave from "../../../assets/images/dashboard/svg/leave.svg";
import { updateEmployeeData } from "../../../services/api";
import { employeeRecordState } from "../../../constants/constants";

const EditEmployee = (props) => {
  const { employee, setPageState, setTriggerFetch } = props;
  console.log(employee)
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [showEditStatus, setShowEditStatus] = useState(false);
  const [employeeStatus, setEmployeeStatus] = useState(employee.status)
  const [formData, setFormData] = useState({});
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  useEffect(() => {
    if (Object.keys(employee)?.length > 0) {
      setFormData(employee);
    }
  }, [employee]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Process form data here
    
    const res = await updateEmployeeData(Object.assign(formData, {status: employeeStatus}));
    console.log(res)
    setTriggerFetch(prev => !prev)
    setPageState(employeeRecordState.employeeTable)
  };
  // 
  return (
    <div className="w-full">
      <div className="max-w-[700px] pt-[30px] flex justify-between items-center">
        <div 
          className="flex gap-[30px] items-center cursor-pointer"
          onClick={() => setPageState(employeeRecordState.employeeTable)}
        >
          <img src={LeftArrow} className="w-[9px]" alt='prev' />
          <div className="text-base font-inter-semibold text-[#404040]">Edit Employee Record</div>
        </div>
        <div className="flex items-center gap-[15px] relative">
          <div 
            className="font-inter-semibold text-[13px] text-[#000000B2] cursor-pointer underline"
            onClick={() => setShowEditStatus(!showEditStatus)}
          >
            Edit Status:
          </div>
          <div 
            className={`
              font-inter-semibold text-[12px] capitalize rounded-xl py-[5px] px-[8px]
              ${employeeStatus === 'active' ? 'bg-[#1DA39259] text-[#1DA392]' : ''}
              ${employeeStatus === 'leave' ? 'bg-[#FBDCAE] text-[#5D3208]' : ''}
              ${employeeStatus === 'terminated' ? 'bg-[#DEDEDE] text-[#343434]' : ''}
            `}
          >
            {employeeStatus}
          </div>
          {showEditStatus && 
            <div className="p-[10px] flex flex-col absolute left-[0px] top-[35px] bg-[#edeaea] rounded-md z-[999]">
              <button
                onClick={() => {
                  setEmployeeStatus("active");
                  setShowEditStatus(false);
                }}
              >
                <div className="flex items-center gap-[7px]">
                  <img src={Active} className="w-[10px]" alt='active' />
                  <div className="font-inter-medium text-[10px] text-[#000000B2]">Active</div>
                </div>
              </button>
              <div className="py-[4px] mx-[-10px] border-b-[1px] border-[#00000026]"/>
              <button 
                className="pt-[8px]"
                onClick={() => {
                  setEmployeeStatus("leave");
                  setShowEditStatus(false);
                }}
              >
                <div className="flex items-center gap-[7px]">
                  <img src={Leave} className="w-[10px]" alt='leave' />
                  <div className="font-inter-medium text-[10px] text-[#000000B2]">Leave of Absence</div>
                </div>
              </button>
              <div className="py-[4px] mx-[-10px] border-b-[1px] border-[#00000026]"/>
              <button 
                className="pt-[8px]"
                onClick={() => {
                  setEmployeeStatus("terminated");
                  setShowEditStatus(false);
                }}
              >
                <div className="flex items-center gap-[7px]">
                  <img src={Terminate} className="w-[10px]" alt='terminate' />
                  <div className="font-inter-medium text-[10px] text-[#000000B2]">Terminated</div>
                </div>
              </button>
            </div>
          }
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex gap-[15px] pt-[20px]">
          <div className="w-3/5 max-w-[700px] flex flex-col pt-[10px] px-[40px] pb-[30px] border-[1px] border-[#EFF0F6] rounded-2xl">
            <div className="py-[15px] flex justify-between font-inter-bold text-[15px]">
              <button 
                className={activeTab === 0 ? "text-[#011165]" : "text-[#00000040]"} 
                type="button"
                onClick={() => handleTabClick(0)}
              >
                Personal Information
              </button>
              <button 
                className={activeTab === 1 ? "text-[#011165]" : "text-[#00000040]"}
                type="button" 
                onClick={() => handleTabClick(1)}
              >
                Job Details
              </button>
              <button 
                className={activeTab === 2 ? "text-[#011165]" : "text-[#00000040]"} 
                type="button"
                onClick={() => handleTabClick(2)}
              >
                Next of Kin
              </button>
              <button 
                className={activeTab === 3 ? "text-[#011165]" : "text-[#00000040]"} 
                type="button"
                onClick={() => handleTabClick(3)}
              >
                Emergency Contact
              </button>
            </div>
            <div className="mx-[-40px] mb-[20px] border-b-[1px] border-[#0000001A]" />
            <div className="flex flex-col justify-between min-h-[450px]">
              <div className="flex flex-col gap-[12px] pt-[10px]">
                {activeTab === 0 && 
                  <>
                    <div className="flex gap-[30px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                      <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                        <div className="font-inter-bold text-[#000000B2]">First Name</div>
                        <input
                          type="text"
                          name="firstName"
                          className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                          value={formData.firstName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                        <div className="font-inter-bold text-[#000000B2]">Middle Name</div>
                        <input
                          type="text"
                          name="middleName"
                          className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                          value={formData.middleName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="pt-[20px] flex gap-[30px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                      <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                        <div className="font-inter-bold text-[#000000B2]">Last Name</div>
                        <input
                          type="text"
                          name="lastName"
                          className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                          value={formData.lastName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                        <div className="font-inter-bold text-[#000000B2]">Phone Number</div>
                        <input
                          type="text"
                          name="phoneNumber"
                          className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="pt-[20px] flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                      <div className="font-inter-bold text-[#000000B2]">Email Address</div>
                      <input
                        type="text"
                        name="email"
                        className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="pt-[20px] flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                      <div className="font-inter-bold text-[#000000B2]">Address</div>
                      <input
                        type="text"
                        name="address"
                        className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                        value={formData.address}
                        onChange={handleInputChange}
                      />
                    </div>
                  </>
                }
                {activeTab === 1 && 
                  <>
                    <div className=" flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                      <div className="font-inter-bold text-[#000000B2]">Department</div>
                      <input
                        type="text"
                        name="department"
                        className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                        value={formData.department}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="pt-[20px] flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                      <div className="font-inter-bold text-[#000000B2]">Job Title</div>
                      <input
                        type="text"
                        name="position"
                        className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                        value={formData.position}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="pt-[20px] flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                      <div className="font-inter-bold text-[#000000B2]">Line Manager</div>
                      <input
                        type="text"
                        name="lineManager"
                        className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                        value={formData.lineManager}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="pt-[20px] flex gap-[30px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                      <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                        <div className="font-inter-bold text-[#000000B2]">Salary</div>
                        <input
                          type="text"
                          name="grossSalary"
                          className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                          value={formData.grossSalary}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                        <div className="font-inter-bold text-[#000000B2]">Hire Date</div>
                        <input
                          type="text"
                          name="hireDate"
                          className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                          value={formData.hireDate}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </>
                }
                {activeTab === 2 && 
                  <>
                    <div className="flex gap-[30px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                      <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                        <div className="font-inter-bold text-[#000000B2]">First Name</div>
                        <input
                          type="text"
                          name="kinFirstName"
                          className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                          value={formData.kinFirstName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                        <div className="font-inter-bold text-[#000000B2]">Last Name</div>
                        <input
                          type="text"
                          name="kinLastName"
                          className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                          value={formData.kinLastName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="pt-[20px] flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                      <div className="font-inter-bold text-[#000000B2]">Phone Number</div>
                      <input
                        type="text"
                        name="kinPhoneNumber"
                        className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                        value={formData.kinPhoneNumber}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="pt-[20px] flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                      <div className="font-inter-bold text-[#000000B2]">Address</div>
                      <input
                        type="text"
                        name="kinAddress"
                        className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                        value={formData.kinAddress}
                        onChange={handleInputChange}
                      />
                    </div>
                  </>
                }
                {activeTab === 3 && 
                  <>
                    <div className="flex gap-[30px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                      <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                        <div className="font-inter-bold text-[#000000B2]">First Name</div>
                        <input
                          type="text"
                          name="emergencyFirstName"
                          className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                          value={formData.emergencyFirstName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                        <div className="font-inter-bold text-[#000000B2]">Last Name</div>
                        <input
                          type="text"
                          name="emergencyLastName"
                          className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                          value={formData.emergencyLastName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="pt-[20px] flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                      <div className="font-inter-bold text-[#000000B2]">Phone Number</div>
                      <input
                        type="text"
                        name="emergencyPhoneNumber"
                        className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                        value={formData.emergencyPhoneNumber}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="pt-[20px] flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                      <div className="font-inter-bold text-[#000000B2]">Address</div>
                      <input
                        type="text"
                        name="emergencyAddress"
                        className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                        value={formData.emergencyAddress}
                        onChange={handleInputChange}
                      />
                    </div>
                  </>
                }
              </div>
              <div className="flex items-center justify-between">
                {activeTab !== 0 &&
                  <div 
                    className="flex gap-[10px] items-center justify-start w-full cursor-pointer"
                    onClick={() => setActiveTab(activeTab - 1)}
                  >
                    <img src={Back} className="w-[24px]" alt='back' />
                    <div className="font-inter-medium text-sm text-[#000000B2]">Back</div>
                  </div>
                }
                {activeTab !== 3 &&
                  <div 
                    className="flex gap-[10px] items-center justify-end w-full cursor-pointer"
                    onClick={() => setActiveTab(activeTab + 1)}
                  >
                    <img src={Next} className="w-[24px]" alt='back' />
                    <div className="font-inter-medium text-sm text-[#000000B2]">Next</div>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="w-2/5 max-w-[600px]">
            <div className="flex gap-[20px] justify-end">
              <button
                className="flex justify-center items-center py-[7px] px-[10px] rounded-lg text-[#011165] font-inter-semibold text-[11px] bg-[#ffffff] border-[1px] border-[#011165]"
                onClick={() => {
                  console.log("2222")
                  setPageState(employeeRecordState.employeeTable)
                }}
              >
                CANCEL
              </button>
              <button
                className="flex justify-center items-center py-[7px] px-[10px] rounded-lg text-white font-inter-semibold text-[11px] bg-[#011165] border-[1px] border-[#011165]"
                // onClick={handleSubmit}
                type='submit'
              >
                SAVE
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default EditEmployee;
