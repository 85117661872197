import Payroll_AboutUs from "./Payroll_AboutUs";
import Payroll_Life from "./Payroll_Life";
import Payroll_Process from "./Payroll_Process";
import Payroll_Save from "./Payroll_Save";
import Payroll_Simplify from "./Payroll_Simplify";
import Topheader from "./Topheader";

const LandingpPage_Payroll = () => {
  return (
    <div>
      <Topheader />
      <Payroll_Life />
      <Payroll_Process />
      <Payroll_Save />
      <Payroll_Simplify />
      <Payroll_AboutUs />
    </div>
  );
};
export default LandingpPage_Payroll;
