import React from 'react';

const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div className="fixed inset-0 bg-[#000000] opacity-25"></div>
      <div className="relative w-auto max-w-3xl mx-auto my-6">
        <div className="min-w-[452px] relative flex flex-col w-full bg-white border-0 rounded-3xl shadow-lg outline-none focus:outline-none">
          <div className="z-50 absolute top-[10px] right-[20px]">
            <button
              className="z-50 p-1 ml-auto text-3xl leading-none text-black bg-transparent border-0 outline-none focus:outline-none"
              onClick={onClose}
            >
              <span className="block w-6 h-6 text-3xl text-black bg-transparent outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <div className="relative flex-auto px-[40px] py-[50px]">
            {children}
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Modal;
