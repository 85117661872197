import AccountDetail from "../pages/EmployeePage/AccountDetail/index";
import Dashboard from "../pages/EmployeePage/Dashboard/index";
import EditProfile from "../pages/EmployeePage/EditProfile/index";
import PaymentSetting from "../pages/EmployeePage/PaymentSetting/index";
import Payslips from "../pages/EmployeePage/Payslips/index";
import SecuritySetting from "../pages/EmployeePage/SecuritySetting/index";
import Vacation from "../pages/EmployeePage/Vacation/index";

export const employeeRoutes = [
  { path: ``, Component: <Dashboard /> },
  { path: `dashboard`, Component: <Dashboard /> },
  { path: `payslips`, Component: <Payslips /> },
  { path: `vacation`, Component: <Vacation /> },
  { path: `payment-setting`, Component: <PaymentSetting /> },
  { path: `account/`, Component: <AccountDetail /> },
  { path: `account/details`, Component: <AccountDetail /> },
  { path: `account/edit-profile`, Component: <EditProfile /> },
  { path: `account/setting`, Component: <SecuritySetting /> },
];
