import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import LeftArrow from "../../../assets/images/dashboard/svg/left-arrow.svg";
import Edit from "../../../assets/images/dashboard/svg/edit-white.svg";
import Avatar from "../../../assets/images/dashboard/svg/avatar.svg";
import PersonBlue from "../../../assets/images/dashboard/svg/person-blue.svg";
import Email from "../../../assets/images/dashboard/svg/email-blue.svg";
import Phone from "../../../assets/images/dashboard/svg/phone-blue.svg";
import Home from "../../../assets/images/dashboard/svg/home.svg";
import Person from "../../../assets/images/dashboard/svg/person.svg";
import { AdminContext } from "../../../contexts/AdminContext";
import { employeeRecordState } from "../../../constants/constants";

const EmployeeView = (props) => {
  const { employee, setPageState } = props;
  const navigate = useNavigate();
  
  return (
    <div className="w-full">
      <div className="flex pt-[20px] justify-between items-center">
        <div 
          className="flex gap-[30px] items-center cursor-pointer"
          onClick={() => setPageState(employeeRecordState.employeeTable)}
        >
          <img src={LeftArrow} className="w-[9px]" alt='prev' />
          <div className="text-base font-inter-semibold text-[#404040]">Return to Employee Records</div>
        </div>
        <button
          className="flex gap-[10px] items-center py-[9px] px-[15px] rounded-md  bg-[#011165] hover:bg-[#011265e1]"
          onClick={() => setPageState(employeeRecordState.employeeEdit)}
        >
          <img src={Edit} className="w-[16px]" alt='etid' />
          <div className="text-white font-inter-semibold text-[10px]">EDIT RECORD</div>
        </button>
      </div>
      <div className="mt-[15px] flex gap-[20px]">
        <div className="w-2/3 border-[1px] border-[#EFF0F6] rounded-2xl px-[40px] pb-[40px]">
          <div className="flex justify-between items-center py-[20px]">
            <div className="font-inter-semibold text-[15px] text-[#000000B2]">Personal Information</div>
            <div className="flex items-center gap-[15px]">
              <div className="font-inter-semibold text-[13px] text-[#000000B2]">Status:</div>
              <div 
                className={`
                  font-inter-semibold text-[12px] capitalize rounded-xl py-[5px] px-[8px]
                  ${employee.status === 'active' ? 'bg-[#1DA39259] text-[#1DA392]' : ''}
                  ${employee.status === 'leave' ? 'bg-[#FBDCAE] text-[#5D3208]' : ''}
                  ${employee.status === 'terminated' ? 'bg-[#DEDEDE] text-[#343434]' : ''}
                `}
              >{employee.status}</div>
            </div>
          </div>
          <div className="mx-[-40px] border-y-[1px] border-[#EFF0F6] flex py-[20px]">
            <div className="w-1/3 pl-[30px]">
              <img src={Avatar} className="w-[96px] cursor-pointer" alt="avatar" />
            </div>
            <div className="w-2/3 flex flex-col justify-center gap-[6px]">
              <div className="flex items-center gap-[6px] pl-[25px]">
                <img src={PersonBlue} className="w-[19px] cursor-pointer" alt="person" />
                <span className="font-inter-semibold text-[#000000B2] text-[16px]">{employee?.firstName} {employee?.middleName} {employee?.lastName}</span>
              </div>
              <div className="flex items-center gap-[6px] pl-[25px]">
                <img src={Email} className="w-[19px] cursor-pointer" alt="email" />
                <span className="font-inter-medium text-[#000000B2] text-[13px]">{employee?.email}</span>
              </div>
              <div className="flex items-center gap-[13px] pl-[25px]">
                <img src={Phone} className="w-[12px] cursor-pointer" alt="phone" />
                <span className="font-inter-medium text-[#000000B2] text-[13px]">{employee?.phoneNumber}</span>
              </div>
            </div>

          </div>

          <div className="font-inter-semibold text-[15px] text-[#000000B2] pt-[20px]">Contact Information</div>
          <div className="pt-[20px]">
            <div className="font-inter-regular text-[13px] text-[#00000080]">Home Address</div>
            <div className="pt-[5px] font-inter-semibold text-[15px] text-[#000000B2]">{employee.address}</div>
          </div>
          <div className="pt-[20px]">
            <div className="font-inter-regular text-[13px] text-[#00000080]">Next of Kin Details</div>
            <div className="pt-[5px] font-inter-semibold text-[15px] text-[#000000B2]">{employee?.kinAddress}</div>
          </div>
          <div className="pt-[20px]">
            <div className="font-inter-regular text-[13px] text-[#00000080]">Emergency Contact</div>
            <div className="pt-[5px] font-inter-semibold text-[15px] text-[#000000B2]">{employee?.emergencyFirstName} {employee?.emergencyLastName}</div>
            <div className="pt-[5px] font-inter-semibold text-[15px] text-[#000000B2]">{employee?.emergencyAddress}</div>
            <div className="pt-[5px] font-inter-semibold text-[15px] text-[#000000B2]">{employee?.emergencyPhoneNumber}</div>
          </div>
        </div>
        <div className="w-1/3">
          <div className="border-[1px] border-[#EFF0F6] rounded-2xl py-[20px] px-[30px]">
            <div className="text-[15px] font-inter-semibold text-[#000000B2]">Job Details</div>
            <div className="pt-[10px] mx-[-30px] border-b-[1px] border-[#EFF0F6]" />
            <div className="flex flex-col gap-[15px] py-[20px]">
              <div className="flex items-center">
                <div className="w-2/3 font-inter-regular text-[13px] text-[#00000080]">Department</div>
                <div className="w-1/3 font-inter-semibold text-[15px] text-[#000000B2]">{employee?.department}</div>
              </div>
              <div className="flex items-center">
                <div className="w-2/3 font-inter-regular text-[13px] text-[#00000080]">Job Title</div>
                <div className="w-1/3 font-inter-semibold text-[15px] text-[#000000B2]">{employee?.position}</div>
              </div>
              <div className="flex items-center">
                <div className="w-2/3 font-inter-regular text-[13px] text-[#00000080]">Salary</div>
                <div className="w-1/3 font-inter-semibold text-[15px] text-[#000000B2]">₦ {employee?.grossSalary}</div>
              </div>
              <div className="flex items-center">
                <div className="w-2/3 font-inter-regular text-[13px] text-[#00000080]">Hire Date</div>
                <div className="w-1/3 font-inter-semibold text-[15px] text-[#000000B2]">{employee?.hireDate}</div>
              </div>
              <div className="flex items-center">
                <div className="w-2/3 font-inter-regular text-[13px] text-[#00000080]">Line Manager</div>
                <div className="w-1/3 font-inter-semibold text-[15px] text-[#000000B2]">{employee?.lineManager}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmployeeView;
