import LandingpPage_Payroll from "../pages/landing-payroll/index";
import Login from "../pages/Auth/Login/Login";
import SignUp from "../pages/Auth/SignUp/SignUp";
import Dashboard from "../pages/EmployeePage/Dashboard/index";
import CodeVerification from "../pages/Auth/CodeVerification/index";
import ForgotPassword from "../pages/Auth/ForgotPassword/index";

export const authRoutes = [
  { path: `/login/:email`, Component: <Login /> },
  { path: `/login`, Component: <Login /> },
  { path: `/signup`, Component: <SignUp /> },
  { path: `/code-verification/:email`, Component: <CodeVerification /> },
  { path: `/forget-pwd`, Component: <ForgotPassword /> },
];
