import React from 'react';

const Dropdown = (props) => {
  const { options, selectedOption, setSelectedOption, selectClassName, optionClassName } = props; 
  const handleSelectOption = (option) => {
    console.log(option)
    setSelectedOption(option);
  };
  return (
    <div>
      <select 
        className={`font-eina02-regular text-[12px] text-[#9E9E9E] p-[3px] border-[1px] border-[#C4C4C4] rounded-md focus:outline-none focus:border-[#C4C4C4] ${selectClassName}` }
        onChange={(e) => handleSelectOption(e.target.value)} 
        value={selectedOption}
      >
        {options.map((option, index) => (
          <option 
            className={`font-eina02-regular text-[12px] text-[#9E9E9E] ${optionClassName}`} 
            key={index} 
            value={option.index}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
