import PSVG from "../../assets/images/Auth/svg/p.svg";
import ShowSVG from "../../assets/images/Auth/svg/show.svg";
export const LOGIN_PSVG = {
  svg: PSVG,
  title: "PAYROLL",
};

export const LOGIN_CONSTANT_DATAS = {
  title: "LOGIN",
  welcome: "Welcome back! Log in to Continue",
  email: "Email or Phone Number",
  passowrd: "Passowrd",
  confirmPassowrd: "Confirm passowrd",
  forgot: "Forgot Password",
  btn: "Log in",
  create: "Don't have an account? Create free account",
  showsvg: ShowSVG,
};
