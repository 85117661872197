import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminContext } from "../../../contexts/AdminContext";
import Search from "../../../assets/images/dashboard/svg/search.svg";
import Employee from "../../../assets/images/dashboard/svg/employee-add.svg";
import Modal from "../../../components/Modal/index";

const dummyEmployee = [
  {
    firstName: 'Peter',
    middleName: 'Oscar',
    lastName: 'Pan',
    email: 'peterpan@gmail.com',
    phoneNumber: '+1 2328284903',
    role: 'employee',
    companyName: 'Leo Ltd',
    department: 'HR',
    position: 'HR officer',
    lineManager: 'Marvin Ralph',
    hireDate: '23-03-2023',
    grossSalary: '300000',
    bankName: 'GTB',
    bankAccountName: 'Peter Pan',
    bankAccountNumber: 3940392019,
    isBankAccountValidated: true,
    leftVacationInfo: [

    ],
    address: '6295 Lake Worth Rd #19',
    city: 'Los Angeles',
    state: 'California',
    kinFirstName: 'Wilter',
    kinLastName: 'Pan',
    kinPhoneNumber: '+1 3039403923',
    kinAddress: '6295 Lake Worth Rd #19',
    emergencyFirstName: 'Wilter',
    emergencyLastName: 'Pan',
    emergencyPhoneNumber: '+1 3039403923',
    emergencyAddress: '6295 Lake Worth Rd #19',
    userId: 10000002,
    status: 'active',
  },
  {
    firstName: 'Simon',
    middleName: 'Oscar',
    lastName: 'Pegg',
    email: 'simonpegg@gmail.com',
    phoneNumber: '+1 4328284903',
    role: 'employee',
    companyName: 'Leo Ltd',
    department: 'ICT',
    position: 'UX designer',
    lineManager: 'Marvin Ralph',
    hireDate: '14-05-2023',
    grossSalary: '300000',
    bankName: 'GTB',
    bankAccountName: 'Simon Pegg',
    bankAccountNumber: 4920392019,
    isBankAccountValidated: true,
    leftVacationInfo: [

    ],
    address: '6295 Lake Worth Rd #19',
    city: 'Los Angeles',
    state: 'California',
    kinFirstName: 'Wilter',
    kinLastName: 'Pan',
    kinPhoneNumber: '+1 3039403923',
    kinAddress: '6295 Lake Worth Rd #19',
    emergencyFirstName: 'Wilter',
    emergencyLastName: 'Pan',
    emergencyPhoneNumber: '+1 3039403923',
    emergencyAddress: '6295 Lake Worth Rd #19',
    userId: 10000003,
    status: 'active',
  },
  {
    firstName: 'Susan',
    middleName: '',
    lastName: 'Megg',
    email: 'susanmegg@gmail.com',
    phoneNumber: '+1 2328284903',
    role: 'employee',
    companyName: 'Leo Ltd',
    department: 'HR',
    position: 'HR officer',
    lineManager: 'Marvin Ralph',
    hireDate: '05-09-2023',
    grossSalary: '300000',
    bankName: 'GTB',
    bankAccountName: 'Susan Megg',
    bankAccountNumber: 7940392013,
    isBankAccountValidated: true,
    leftVacationInfo: [

    ],
    address: '6295 Lake Worth Rd #19',
    city: 'Los Angeles',
    state: 'California',
    kinFirstName: 'Wilter',
    kinLastName: 'Pan',
    kinPhoneNumber: '+1 3039403923',
    kinAddress: '6295 Lake Worth Rd #19',
    emergencyFirstName: 'Wilter',
    emergencyLastName: 'Pan',
    emergencyPhoneNumber: '+1 3039403923',
    emergencyAddress: '6295 Lake Worth Rd #19',
    userId: 10000003,
    status: 'active',
  },
  {
    firstName: 'Philip',
    middleName: 'Mae',
    lastName: 'Olaitan',
    email: 'philip@gmail.com',
    phoneNumber: '+1 9328284903',
    role: 'employee',
    companyName: 'Leo Ltd',
    department: 'Finance',
    position: 'HR officer',
    lineManager: 'Marvin Ralph',
    hireDate: '30-07-2023',
    grossSalary: '300000',
    bankName: 'GTB',
    bankAccountName: 'Peter Pan',
    bankAccountNumber: 3940392019,
    isBankAccountValidated: false,
    leftVacationInfo: [

    ],
    address: '6295 Lake Worth Rd #19',
    city: 'Los Angeles',
    state: 'California',
    kinFirstName: 'Wilter',
    kinLastName: 'Pan',
    kinPhoneNumber: '+1 3039403923',
    kinAddress: '6295 Lake Worth Rd #19',
    emergencyFirstName: 'Wilter',
    emergencyLastName: 'Pan',
    emergencyPhoneNumber: '+1 3039403923',
    emergencyAddress: '6295 Lake Worth Rd #19',
    userId: 10000003,
    status: 'leave',
  },
  {
    firstName: 'Sade',
    middleName: 'Mae',
    lastName: 'Akinlade',
    email: 'akinalade@gmail.com',
    phoneNumber: '+1 2328284903',
    role: 'employee',
    companyName: 'Leo Ltd',
    department: 'HR',
    position: 'HR officer',
    lineManager: 'Marvin Ralph',
    hireDate: '23-03-2023',
    grossSalary: '300000',
    bankName: 'GTB',
    bankAccountName: 'Peter Pan',
    bankAccountNumber: 2300392019,
    isBankAccountValidated: false,
    leftVacationInfo: [

    ],
    address: '6295 Lake Worth Rd #19',
    city: 'Los Angeles',
    state: 'California',
    kinFirstName: 'Wilter',
    kinLastName: 'Pan',
    kinPhoneNumber: '+1 3039403923',
    kinAddress: '6295 Lake Worth Rd #19',
    emergencyFirstName: 'Wilter',
    emergencyLastName: 'Pan',
    emergencyPhoneNumber: '+1 3039403923',
    emergencyAddress: '6295 Lake Worth Rd #19',
    userId: 10000003,
    status: 'terminated',
  },
  {
    firstName: 'Sade',
    middleName: 'Mae',
    lastName: 'Akinlade',
    email: 'akinalade@gmail.com',
    phoneNumber: '+1 2328284903',
    role: 'employee',
    companyName: 'Leo Ltd',
    department: 'HR',
    position: 'HR officer',
    lineManager: 'Marvin Ralph',
    hireDate: '23-03-2023',
    grossSalary: '300000',
    bankName: 'GTB',
    bankAccountName: 'Peter Pan',
    bankAccountNumber: 2300392019,
    isBankAccountValidated: false,
    leftVacationInfo: [

    ],
    address: '6295 Lake Worth Rd #19',
    city: 'Los Angeles',
    state: 'California',
    kinFirstName: 'Wilter',
    kinLastName: 'Pan',
    kinPhoneNumber: '+1 3039403923',
    kinAddress: '6295 Lake Worth Rd #19',
    emergencyFirstName: 'Wilter',
    emergencyLastName: 'Pan',
    emergencyPhoneNumber: '+1 3039403923',
    emergencyAddress: '6295 Lake Worth Rd #19',
    userId: 10000003,
    status: 'leave',
  },
]
const AddEmployeePayrollModal = (props) => {
  const { employeeData, onClose, showModal, setPayableEmployee } = props;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [addExisting, setAddExisting] = useState(true);
  const [searchName, setSearchName] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [terminatedEmployee, setTerminatedEmployee] = useState([]);
  useEffect(() => {
    if (employeeData?.length > 0) {
      const data = employeeData.filter(e => e.status === 'terminated');
      setTerminatedEmployee(data);
    }
  }, [employeeData]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Process form data here
    console.log(formData);
    // const res = await updateEmployeeData(formData);
    // console.log(res)
  };
  const handleSearchInputChange = (event) => {
    setSearchName(event.target.value);
  }
  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows.filter(rowId => rowId !== id));
    }
  };
  useEffect(() => {
    console.log(selectedRows);
  }, [selectedRows])
  const handleAddEmployee = () => {
    if (selectedRows?.length > 0) {
      let addingEmployee = [];
      for (let i = 0; i < selectedRows?.length; i++) {
        console.log(i, terminatedEmployee[i])
        let data = terminatedEmployee[i];
        data.totalPayableAmount = 0;
        addingEmployee.push(data);
      }
      console.log("adding employee", addingEmployee);
      setPayableEmployee(prev => [...prev, ...addingEmployee])
      onClose();
    }
  }
  return (
    <div className="w-full">
      <Modal isOpen={showModal} onClose={onClose} title="Modal Heade">
        <div className="max-h-[678px] min-w-[685px]">
          <div className="flex gap-[20px]">
            <img src={Employee} className="w-[53px]" alt='person' />
            <div className="flex flex-col justify-between">
              <div className="font-inter-semibold text-[20px] text-[#011165]">Add Employee</div>
              <div className="font-inter-regular text-[12px] text-[#00000080]">Add new employee or select already existing employee</div>
            </div>
          </div>
          <div className="pt-[30px] border-b-[1px] border-[#00000026]" />
          <form onSubmit={handleSubmit} >
            {!addExisting ? (
              <>
                <fieldset disabled={isDisabled} className={`${isDisabled ? 'opacity-30' : 'opacity-100'}`}>
                  <div className="flex flex-col gap-[30px]">
                    <div className="pt-[30px] font-inter-semibold text-[15px] text-[#000000B2]">Add New Employee</div>
                    <div className="w-full flex gap-[10px]">
                      <div className="w-full flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                        <div className="font-inter-bold text-[#000000B2]">First Name</div>
                        <input
                          type="text"
                          name="firstName"
                          className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                          value={formData.firstName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="w-full flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                        <div className="font-inter-bold text-[#000000B2]">Middle Name</div>
                        <input
                          type="text"
                          name="middleName"
                          className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                          value={formData.middleName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="w-full flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                        <div className="font-inter-bold text-[#000000B2]">Last Name</div>
                        <input
                          type="text"
                          name="lastName"
                          className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                          value={formData.lastName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="flex gap-[10px]">
                      <div className="w-full flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                        <div className="font-inter-bold text-[#000000B2]">Email Address</div>
                        <input
                          type="text"
                          name="email"
                          className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="w-full flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                        <div className="font-inter-bold text-[#000000B2]">Phone Number</div>
                        <input
                          type="text"
                          name="phoneNumber"
                          className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="flex gap-[10px]">
                      <div className="w-full flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                        <div className="font-inter-bold text-[#000000B2]">Job Title</div>
                        <input
                          type="text"
                          name="position"
                          className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                          value={formData.position}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="w-full flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                        <div className="font-inter-bold text-[#000000B2]">Department</div>
                        <input
                          type="text"
                          name="department"
                          className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                          value={formData.department}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div className="pt-[40px] border-b-[1px] border-[#00000026]" />
                <div className="flex pt-[45px] gap-[30px] items-center">
                  <div className="flex gap-[10px] items-center">
                    <input
                      type="checkbox"
                      name="isDisabled"
                      className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                      value={isDisabled}
                      onChange={() => setIsDisabled(!isDisabled)}
                    />
                    <div className="font-inter-semibold text-[10px] text-[#000000B2]">Add Existing Employee to Payroll</div>
                  </div>
                  {isDisabled && <button 
                      className="rounded-md font-inter-bold text-[10px] text-white px-[10px] py-[10px] bg-[#011165]"
                      onClick={() => {
                        setAddExisting(true);
                      }}
                    >
                      <span className="text-[14px]">+</span> CHOOSE EXISTING
                    </button>
                  }
                </div>
              </>
            ) : (
              <div className="">
                <div className="pt-[30px] font-inter-semibold text-[15px] text-[#000000B2]">Select Employee</div>
                <div className="relative flex py-[20px]">
                  <img src={Search} className="w-[12] absolute top-[26px] left-[3px]" alt='search'/>
                  <input
                    type="text"
                    name="searchName"
                    className="w-[167px] h-[27px] border-[1px] border-[#00000040] rounded-md px-[18px] py-[8px] focus:outline-none focus:border-[#000000A0] text-[11px] font-inter-regular text-[#000000B2]"
                    value={searchName}
                    placeholder="Search for employee..."
                    onChange={handleSearchInputChange}
                  />
                </div>
                <div className="border-y-[1px] py-[10px] border-[#00000026] overflow-y-auto max-h-[300px]" >
                  <table className="min-w-full" >
                    <thead className="">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left">
                        </th>
                        <th scope="col" className="px-6 py-5 text-left text-[15px] font-inter-semibold text-[#000000B2]">
                          Name
                        </th>
                        <th scope="col" className="px-6 py-5 text-left text-[15px] font-inter-semibold text-[#000000B2]">
                          Department
                        </th>
                        <th scope="col" className="px-6 py-5 text-left text-[15px] font-inter-semibold text-[#000000B2]">
                          Email Address
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {terminatedEmployee && terminatedEmployee.map((row, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <input
                              type="checkbox"
                              className="w-4 h-4 text-indigo-600 transition duration-150 ease-in-out form-checkbox"
                              onChange={(e) => handleCheckboxChange(e, index)}
                            />
                          </td>
                          <td className="px-6 py-5 whitespace-nowrap">
                            <div className="font-inter-semibold text-[12px] text-[#00000080]">{row.firstName} {row.lastName}</div>
                          </td>
                          <td className="px-6 py-5 whitespace-nowrap">
                            <div className="font-inter-semibold text-[12px] text-[#00000080]">{row.department}</div>
                          </td>
                          <td className="px-6 py-5 whitespace-nowrap">
                            <div className="font-inter-semibold text-[12px] text-[#00000080]">{row.email}</div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* <div className="py-[10px]">
                  <button 
                    className="rounded-md font-inter-bold text-[12px] text-white px-[7px] py-[5px] bg-[#011165]"
                    onClick={() => {
                    }}
                  >
                    + Add
                  </button>
                </div> */}
                {/* <div className="border-[1px] border-[#00000026] h-[59px] rounded-lg">

                </div> */}
              </div>
            )}

            <div className="flex justify-end gap-[10px] pt-[20px]">
              <button 
                className="rounded-md font-inter-bold text-[10px] text-[#011165] px-[10px] py-[10px] border-[1px] border-[#011165]"
                onClick={onClose}
              >
                CANCEL
              </button>
              <button 
                className="rounded-md font-inter-bold text-[10px] text-white px-[10px] py-[10px] bg-[#011165]"
                onClick={handleAddEmployee}
              >
                + ADD EMPLOYEE
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};
export default AddEmployeePayrollModal;
