import ApprovePayrollCard from "../../components/landingpage_payroll/ApprovePayrollCard";
import {
  PAYROLL_LIFE,
  PAYROLL_SUBTRACT_IMG,
  PAYROLL_WOMAN_IMG,
} from "../../constants/landingpage_payroll/landingpage_payroll";
const titleParts = PAYROLL_LIFE.title.split("Payroll,");
const Payroll_Life = () => {
  return (
    <div className=" relative flex items-center  justify-between mt-[83px] px-[40px] lg:px-[58px] lg2:px-[75px] xl:px-[98px]">
      <div className="w-3/5 lg2:w-2/3">
        <h1 className="font-eina02-bold text-[40px] md:text-[45px] lg:text-[50px] lg2:text-[55px] xl:text-[74px] leading-[45px] md:leading-[50px] lg:leading-[55px] xl:leading-[80px] text-payroll_primary line-[60px] tracking-[-1px]">
          {titleParts[0]}
          <span className="font-eina02-bold text-[#FF5800]">
            Payroll
          </span>,
          <br/>
          {titleParts[1]}
        </h1>
        <p className="max-w-[300px] md:max-w-[400px] lg:max-w-[600px] font-eni text-[16px] line-[22px] text-payroll_primary tracking-[0px] pt-[17px]">
          {PAYROLL_LIFE.description}
        </p>
        <div className="flex mt-[24px] items-center gap-[24px]">
          <div className="w-[149px] h-[57px] rounded-[100px] gap-[10px] bg-payroll_primary cursor-pointer">
            <p className="font-eina02-semibold font-[400] text-[20px] text-white text-center pt-[15px]">
              {PAYROLL_LIFE.btn}
            </p>
          </div>
          <div className="flex items-center cursor-pointer">
            <img src={PAYROLL_LIFE.demoImg} alt="Demo" />
            <p className="font-eina02-semibold font-[400] text-[16px] line-[21px] text-[#FF5800] pl-[10px]">
              {PAYROLL_LIFE.demo}
            </p>
          </div>
        </div>
      </div>
      <div className="relative w-2/5 lg2:w-1/3">
        <img
          src={PAYROLL_WOMAN_IMG}
          // width={497}
          height={535}
          alt="Woman"
          className="w-full"
        />
        <ApprovePayrollCard />
      </div>
      {/* <div>
        <img
          src={PAYROLL_SUBTRACT_IMG}
          width={117}
          alt="Subtract"
          className="absolute right-[436px] top-[-58px] z-0"
        />
        <ApprovePayrollCard />
      </div> */}
      {/* <img
        src={PAYROLL_WOMAN_IMG}
        width={497}
        height={535}
        alt="Woman"
        className="pr-[102px] z-10"
      /> */}
    </div>
  );
};
export default Payroll_Life;
