import React from 'react';
import LeftArrow from "../../assets/images/dashboard/svg/left-arrow.svg";
import RightArrow from "../../assets/images/dashboard/svg/right-arrow.svg";
const Pagination = ({ pageIndex, pageCount, pageOptions, pageSize, previousPage, nextPage, setPageSize }) => {

  return (
    <div className="flex justify-end gap-[20px]">
      <div className="flex gap-[10px] items-center">
        <button onClick={() => previousPage()} disabled={pageIndex === 0}>
          <img src={LeftArrow} className="w-[8px]" alt="prev" />
        </button>
        <span className="font-inter-bold text-base text-[#000000B2]">
            {pageIndex + 1} of {pageOptions.length}
        </span>
        <button onClick={() => nextPage()} disabled={pageIndex === pageCount - 1}>
          <img src={RightArrow} className="w-[8px]" alt="prev" />
        </button>
      </div>
      <select
        value={pageSize}
        onChange={e => {
          setPageSize(Number(e.target.value));
        }}
        className={`font-inter-regular text-[12px] text-[#9E9E9E] p-[3px] border-[1px] border-[#C4C4C4] rounded-md focus:outline-none focus:border-[#C4C4C4]` }
      >
        {[5, 10, 20, 30, 40, 50].map(pageSize => (
          <option 
            key={pageSize} 
            value={pageSize}
            className={`font-inter-regular text-[12px] text-[#9E9E9E]`} 
          >
            Show {pageSize}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Pagination;
