export const EmployeeSidebarItems = [
  {
    label: "Dashboard",
    path: "/employee/dashboard",
    icon: "radix-icon.svg",
    selectedIcon: "radix-icon-active.svg",
  },
  {
    label: "Pay slips",
    path: "/employee/payslips",
    icon: "payslip-icon.svg",
    selectedIcon: "payslip-icon-active.svg",
  },
  {
    label: "Vacation",
    path: "/employee/vacation",
    icon: "airplane-icon.svg",
    selectedIcon: "airplane-icon-active.svg",
  },
  {
    label: "Payment settings",
    path: "/employee/payment-setting",
    icon: "setting-icon.svg",
    selectedIcon: "setting-icon-active.svg",
  },
  {
    label: "Account",
    path: "/employee/account",
    icon: "account-icon.svg",
    selectedIcon: "account-icon-active.svg",
  },
  {
    label: "Personal details",
    path: "/employee/account/details",
    icon: "",
    selectedIcon: "",
  },
  {
    label: "Security settings",
    path: "/employee/account/setting",
    icon: "",
    selectedIcon: "",
  },
];

export const AdminSidebarItems = [
  {
    label: "Dashboard",
    path: "/admin/dashboard",
    icon: "radix-icon.svg",
    selectedIcon: "radix-icon-active.svg",
  },
  {
    label: "Employee Management",
    path: "/admin/employee-management",
    icon: "two-people.svg",
    selectedIcon: "two-people-active.svg",
  },
  {
    label: "Employee Accounts",
    path: "/admin/employee-management/accounts",
    icon: "",
    selectedIcon: "",
  },
  {
    label: "Employee Records",
    path: "/admin/employee-management/records",
    icon: "",
    selectedIcon: "",
  },
  {
    label: "Payroll Management",
    path: "/admin/payroll-management",
    icon: "payroll.svg",
    selectedIcon: "payroll-active.svg",
  },
  {
    label: "Initiate Payroll",
    path: "/admin/payroll-management/initiate-payroll",
    icon: "",
    selectedIcon: "",
  },
  {
    label: "Approve/Deny/Payroll",
    path: "/admin/payroll-management/approve",
    icon: "",
    selectedIcon: "",
  },

  {
    label: "Payroll History",
    path: "/admin/payroll-management/history",
    icon: "",
    selectedIcon: "",
  },
  {
    label: "Vacation",
    path: "/admin/payroll-management/vacation",
    icon: "",
    selectedIcon: "",
  },
  {
    label: "Employee Bands",
    path: "/admin/payroll-management/employeebands",
    icon: "",
    selectedIcon: "",
  },
  {
    label: "View Employee Bands",
    path: "/admin/payroll-management/viewbands",
    icon: "",
    selectedIcon: "",
  },
  {
    label: "Payment Settings",
    path: "/admin/payment-setting",
    icon: "setting-icon.svg",
    selectedIcon: "setting-icon-active.svg",
  },
  {
    label: "Pay Slips",
    path: "/admin/payslips",
    icon: "payslip-icon.svg",
    selectedIcon: "payslip-icon-active.svg",
  },
  {
    label: "Deduction",
    path: "/admin/payslips/deduction",
    icon: "",
    selectedIcon: "",
  },
  {
    label: "Approval Management",
    path: "/admin/approval-management",
    icon: "approve.svg",
    selectedIcon: "approve-active.svg",
  },
  {
    label: "Approvers",
    path: "/admin/approval-management/approver",
    icon: "",
    selectedIcon: "",
  },
  {
    label: "Vacations",
    path: "/admin/approval-management/vacation",
    icon: "",
    selectedIcon: "",
  },
  {
    label: "Account",
    path: "/admin/account",
    icon: "account-icon.svg",
    selectedIcon: "account-icon-active.svg",
  },
  {
    label: "Personal Details",
    path: "/admin/account/details",
    icon: "",
    selectedIcon: "",
  },
  {
    label: "Security Settings",
    path: "/admin/account/setting",
    icon: "",
    selectedIcon: "",
  },
];
