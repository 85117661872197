/* eslint-disable */
import React, { createContext, useState } from "react";

export const EmployeeContext = createContext();

const EmployeeProvider = ({ children }) => {
  const [focusDate, setFocusDate] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    date: new Date().getDate(),
  });

  const [selectedDays, setSelectedDays] = useState([]);
  const [paymentHistory, setPaymentHistory] = useState(null);
  const [upcomingEvent, setUpcomingEvent] = useState(null);
  const [availableLeave, setAvailableLeave] = useState(null);
  const [paymentSetting, setPaymentSetting] = useState(null);
  const [bankList, setBankList] = useState(null);
  return (
    <EmployeeContext.Provider
      value={{
        focusDate,
        setFocusDate,
        selectedDays,
        setSelectedDays,
        paymentHistory,
        setPaymentHistory,
        upcomingEvent,
        setUpcomingEvent,
        availableLeave,
        setAvailableLeave,
        paymentSetting,
        setPaymentSetting,
        bankList,
        setBankList,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
};

export default EmployeeProvider;
