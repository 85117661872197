import {
  PAYROLL_SIMPLIFY_TITLE,
  PAYROLL_SIMPLIFY_STEP1,
  PAYROLL_SIMPLIFY_STEP2,
  PAYROLL_SIMPLIFY_STEP3,
  PAYROLL_SIMPLIFY_BACKGROUND,
} from "../../constants/landingpage_payroll/landingpage_payroll";
const Payroll_Simplify = () => {
  const titleParts = PAYROLL_SIMPLIFY_TITLE.title.split("payroll");
  return (
    <div className="mx-[102px]">
      <p className=" font-eina02-bold font-[400] text-[45px] text-payroll_primary">
        {titleParts[0]}
        <span className="font-eina02-bold text-[#FF5800]">
          payroll
        </span>
        {titleParts[1]}
      </p>

      <div className=" relative flex justify-between pt-[60px] gap-[18px] mb-[287px]">
        <div>
          <img src={PAYROLL_SIMPLIFY_STEP1.img} alt="Step 1" />
          <p className=" font-eina02-bold font-[400] text-[25px] text-payroll_primary py-[24px]">
            {PAYROLL_SIMPLIFY_STEP1.title}
          </p>
          <p className=" font-eina02-regular font-[400] text-[16px] text-payroll_primary opacity-[45%] w-[400px]">
            {PAYROLL_SIMPLIFY_STEP1.description}
          </p>
        </div>

        <div className="z-10">
          <img src={PAYROLL_SIMPLIFY_STEP2.img} alt="Step 2" />
          <p className=" font-eina02-bold font-[400] text-[25px] text-payroll_primary py-[24px]">
            {PAYROLL_SIMPLIFY_STEP2.title}
          </p>
          <p className=" font-eina02-regular font-[400] text-[16px] text-payroll_primary opacity-[45%] w-[400px]">
            {PAYROLL_SIMPLIFY_STEP2.description}
          </p>
        </div>

        <div className="z-10">
          <img src={PAYROLL_SIMPLIFY_STEP3.img} alt="Step 3" />
          <p className=" font-eina02-bold font-[400] text-[25px] text-payroll_primary py-[24px]">
            {PAYROLL_SIMPLIFY_STEP3.title}
          </p>
          <p className=" font-eina02-regular font-[400] text-[16px] text-payroll_primary opacity-[45%] w-[400px]">
            {PAYROLL_SIMPLIFY_STEP3.description}
          </p>
        </div>
        <img
          src={PAYROLL_SIMPLIFY_BACKGROUND}
          alt="Background"
          width={826}
          height={814}
          className=" absolute right-[-333px] top-[-320px] z-0"
        />
      </div>
    </div>
  );
};
export default Payroll_Simplify;
