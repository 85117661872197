import {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useTable,
  useSortBy,
  useRowSelect,
  usePagination,
  useFilters,
} from "react-table";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/Modal/index";
import Pagination from "../../../components/Pagination/index";
import SortIcon from "../../../assets/images/dashboard/svg/sort-icon.svg";
import ThreeDots from "../../../assets/images/dashboard/svg/three-dots.svg";
import RightArrow from "../../../assets/images/dashboard/svg/right-arrow.svg";
import Employee from "../../../assets/images/dashboard/svg/employee-sample.svg";
import Terminate from "../../../assets/images/dashboard/svg/terminate-black.svg";
import Remove from "../../../assets/images/dashboard/svg/remove.svg";
import Edit from "../../../assets/images/dashboard/svg/edit.svg";
import ValidataActive from "../../../assets/images/dashboard/svg/validate-active.svg";
import ValidataInactive from "../../../assets/images/dashboard/svg/validate-inactive.svg";
import { AdminContext } from "../../../contexts/AdminContext";
import { employeeRecordState } from "../../../constants/constants";

const EmployeeRecordTable = (props) => {
  const {
    employeeData,
    setSelectedEmployeeIndex,
    setPageState,
    filterName,
    filterStatus,
    filterLineManager,
  } = props;
  const navigate = useNavigate();
  const selectedRowsRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editableRows, setEditableRows] = useState(new Set()); // State to track editable rows
  const isRowEditable = (rowIndex) => editableRows.has(rowIndex);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null); // State to track selected row for the more options popup

  const data = employeeData ?? [];
  const COLUMNS = [
    {
      id: "selection",
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <div className="">
          <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
        </div>
      ),
      Cell: ({ row }) => (
        <input
          type="checkbox"
          className=""
          {...row.getToggleRowSelectedProps()}
        />
      ),
    },
    { Header: "First Name", accessor: "firstName" },
    { Header: "Middle Name", accessor: "middleName" },
    { Header: "Last Name", accessor: "lastName" },
    { Header: "Gross Salary", accessor: (row) => `₦ ${row.grossSalary}` },
    { Header: "Hire Date", accessor: "hireDate" },
    { Header: "Job Title", accessor: "position" },
    { Header: "Line Manager", accessor: "lineManager" },
    { Header: "Status", accessor: "status" },
    // {
    //   id: 'action',
    //   Header: ({ getToggleAllRowsSelectedProps }) => (
    //     <></>
    //   ),
    //   Cell: ({ row }) => <button onClick={event => handleMoreOptionsClick(event, row.index)}><img src={ThreeDots} className="w-[4px]"/></button>,
    // },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    // rows,
    prepareRow,
    state: { pageIndex, pageSize },
    selectedFlatRows,
    pageOptions,
    pageCount,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    setFilter,
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 5 }, // Initial pagination state
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    setFilter("firstName", filterName);
  }, [filterName]);

  useEffect(() => {
    if (filterLineManager === "Select line manager") {
      setFilter("lineManager", "");
    } else {
      setFilter("lineManager", filterLineManager);
    }
  }, [filterLineManager]);

  useEffect(() => {
    if (filterStatus === "All status") {
      setFilter("status", "");
    } else {
      setFilter("status", filterStatus);
    }
  }, [filterStatus]);

  useEffect(() => {
    setSelectedRows(selectedFlatRows?.map((row) => row.original));
  }, [selectedFlatRows, setSelectedRows]);

  useEffect(() => {}, [pageIndex]);

  const toggleEditable = (rowIndex) => {
    setEditableRows((prevState) => {
      const newSet = new Set(prevState);
      if (newSet.has(rowIndex)) {
        newSet.delete(rowIndex);
      } else {
        newSet.add(rowIndex);
      }
      return newSet;
    });
  };

  const handleMoreOptionsClick = (event, rowIndex) => {
    console.log(event, rowIndex);
    event.stopPropagation(); // Prevent table row click event from triggering
    setSelectedRowIndex(rowIndex === selectedRowsRef.current ? null : rowIndex); // Toggle selected row
  };
  useEffect(() => {
    selectedRowsRef.current = selectedRowIndex;
  }, [selectedRowIndex]);

  return (
    <>
      <div className="w-full">
        <div className="flex ml-[-10px] gap-[20px]">
          <table {...getTableProps()} className="w-full bg-white rounded-xl">
            <thead className="bg-[#EDEDED]">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      scope="col"
                      className={`px-8 py-6 
                        ${column.id === "selection" ? "rounded-tl-xl" : ""}
                        ${column.id === "action" ? "rounded-tr-xl" : ""}
                      `}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="flex items-center gap-[5px] text-left font-inter-semibold text-sm text-[#000000B2]">
                        {column.render("Header")}
                        {column?.id !== "selection" &&
                          column?.id !== "action" && (
                            <img src={SortIcon} className="w-[8px]" />
                          )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              className="divide-y divide-[#7b78781a]"
              {...getTableBodyProps()}
            >
              {page.map((row, i) => {
                prepareRow(row);
                const showMoreOptions = selectedRowIndex === i; // Check if more options popup should be displayed for this row
                return (
                  <Fragment key={row.id}>
                    <tr className="relative" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td
                          className={`
                              px-8 py-10 text-[#00000080] font-inter-medium text-sm
                              ${
                                cell.column.Header === "First Name"
                                  ? "underline underline-offset-2 cursor-pointer"
                                  : ""
                              }
                            `}
                          {...cell.getCellProps()}
                          onClick={() => {
                            if (cell.column.Header === "First Name") {
                              setSelectedEmployeeIndex(i);
                              setPageState(employeeRecordState.employeeView);
                            }
                          }}
                        >
                          {cell.column.Header === "Name" && (
                            <img
                              src={Employee}
                              className="w-[29px]"
                              alt="employee"
                            />
                          )}
                          {cell.column.Header === "Status" ? (
                            <div className="flex gap-[10px]">
                              <div
                                className={`
                                      ${
                                        cell.column.Header === "Status" &&
                                        cell.row.original.status === "active"
                                          ? "bg-[#1DA39259] text-[#1DA392] font-inter-semibold"
                                          : ""
                                      }
                                      ${
                                        cell.column.Header === "Status" &&
                                        cell.row.original.status === "leave"
                                          ? "bg-[#FBDCAE] text-[#5D3208] font-inter-semibold"
                                          : ""
                                      }
                                      ${
                                        cell.column.Header === "Status" &&
                                        cell.row.original.status ===
                                          "terminated"
                                          ? "bg-[#DEDEDE] text-[#343434] font-inter-semibold"
                                          : ""
                                      }
                                      px-2 py-1 rounded-xl
                                    `}
                              >
                                {cell.row.original.status}
                              </div>
                            </div>
                          ) : cell.column.Header === "Action" ? (
                            <div className="flex gap-[20px] items-center">
                              <img
                                src={Edit}
                                className="w-[19px] cursor-pointer"
                                alt="edit"
                                onClick={() => {
                                  console.log(cell);
                                  // toggleEditable(i);
                                }}
                              />
                              <img
                                src={
                                  cell.row.original.isBankAccountValidated
                                    ? ValidataInactive
                                    : ValidataActive
                                }
                                className={`w-[19px] ${
                                  !cell.row.original.isBankAccountValidated
                                    ? "cursor-pointer"
                                    : "cursor-not-allowed"
                                }`}
                                alt="edit"
                                onClick={() => {}}
                              />
                            </div>
                          ) : cell.column.id === "selection" ? (
                            cell.render("Cell")
                          ) : isRowEditable(i) ? (
                            <input
                              type="text"
                              value={cell.value}
                              onChange={(e) => {
                                // You'll need to update your data here
                                data[row.index][cell.column.id] =
                                  e.target.value;
                              }}
                              className="w-full"
                            />
                          ) : (
                            cell.render("Cell")
                          )}
                        </td>
                      ))}
                      {showMoreOptions && ( // Render more options popup if showMoreOptions is true
                        <div className="p-[10px] flex flex-col absolute right-[20px] top-[80px] bg-[#edeaea] rounded-md z-[999]">
                          <button
                            onClick={() => {
                              setSelectedEmployeeIndex(selectedRowIndex);
                              setPageState(employeeRecordState.employeeEdit);
                              // setPageState(employeeRecordState.employeeView);
                            }}
                          >
                            <div className="flex items-center gap-[5px]">
                              <img src={Edit} className="w-[10px]" alt="edit" />
                              <div className="font-inter-medium text-[10px] text-[#000000B2]">
                                Edit
                              </div>
                            </div>
                          </button>
                          <div className="py-[2px] mx-[-5px] border-b-[1px] border-[#00000026]" />
                          <button className="pt-[4px]">
                            <div className="flex items-center gap-[5px]">
                              <img
                                src={Remove}
                                className="w-[10px]"
                                alt="remove"
                              />
                              <div className="font-inter-medium text-[10px] text-[#000000B2]">
                                Remove
                              </div>
                            </div>
                          </button>
                          <div className="py-[2px] mx-[-5px] border-b-[1px] border-[#00000026]" />
                          <button className="pt-[4px]">
                            <div className="flex items-center gap-[5px]">
                              <img
                                src={Terminate}
                                className="w-[10px]"
                                alt="terminate"
                              />
                              <div className="font-inter-medium text-[10px] text-[#000000B2]">
                                Terminate
                              </div>
                            </div>
                          </button>
                        </div>
                      )}
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <Pagination
          pageIndex={pageIndex}
          pageCount={pageCount}
          pageOptions={pageOptions}
          pageSize={pageSize}
          previousPage={previousPage}
          nextPage={nextPage}
          setPageSize={setPageSize}
        />
      </div>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title="Modal Heade"
      >
        <div className="h-[542px]"></div>
      </Modal>
    </>
  );
};
export default EmployeeRecordTable;
