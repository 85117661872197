import { useContext, useEffect, useState } from "react";
import Info from "../../../assets/images/dashboard/svg/info.svg";
import RightArrow from "../../../assets/images/dashboard/svg/right-arrow.svg";
import LeftArrow from "../../../assets/images/dashboard/svg/left-arrow.svg";
import Download from "../../../assets/images/dashboard/svg/download.svg";
import Calendar from "../../../assets/images/dashboard/svg/calendar.svg";
import Vacation from "../../../assets/images/dashboard/svg/vacation-icon.svg";
import {
  formatDate,
  formatEventDate,
  getMonthFromDate,
} from "../../../utils/helper";
import Dropdown from "../../../components/Dropdown/index";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  getApprovalHistory,
  getPaymentHistory,
  getPayrollHistoryByMonth,
  getPayrollHistoryInDetail,
  getSummaryForDepartment,
  getUpcommingEvent,
} from "../../../services/api";
import { EmployeeContext } from "../../../contexts/EmployeeContext";
import { AdminContext } from "../../../contexts/AdminContext";
import HistoryInMonth from "./HistoryInMonth";
import { dashboardState } from "../../../constants/constants";
import HistoryInDepartment from "./HistoryInDepartment";
import HistoryInDetail from "./HistoryInDetail";
import ApprovalHistory from "./ApprovalHistory";
const dummyPayrollHistory = [
  {
    date: "2024-01",
    payroll: [
      {
        department: "Human Resource",
        amount: 3000000,
      },
      {
        department: "Finance",
        amount: 4000000,
      },
      {
        department: "Procurement",
        amount: 5000000,
      },
      {
        department: "ICT",
        amount: 6000000,
      },
      {
        department: "Customer Service",
        amount: 7000000,
      },
    ],
    totalAmount: 25000000,
  },
  {
    date: "2024-02",
    payroll: [
      {
        department: "Human Resource",
        amount: 3000000,
      },
      {
        department: "Finance",
        amount: 4000000,
      },
      {
        department: "Procurement",
        amount: 5000000,
      },
      {
        department: "ICT",
        amount: 6000000,
      },
      {
        department: "Customer Service",
        amount: 7000000,
      },
    ],
    totalAmount: 25000000,
  },
  {
    date: "2024-03",
    payroll: [
      {
        department: "Human Resource",
        amount: 3000000,
      },
      {
        department: "Finance",
        amount: 4000000,
      },
      {
        department: "Procurement",
        amount: 5000000,
      },
      {
        department: "ICT",
        amount: 6000000,
      },
      {
        department: "Customer Service",
        amount: 7000000,
      },
    ],
    totalAmount: 25000000,
  },
  {
    date: "2024-04",
    payroll: [
      {
        department: "Human Resource",
        amount: 3000000,
      },
      {
        department: "Finance",
        amount: 4000000,
      },
      {
        department: "Procurement",
        amount: 5000000,
      },
      {
        department: "ICT",
        amount: 6000000,
      },
      {
        department: "Customer Service",
        amount: 7000000,
      },
    ],
    totalAmount: 25000000,
  },
  {
    date: "2024-05",
    payroll: [
      {
        department: "Human Resource",
        amount: 3000000,
      },
      {
        department: "Finance",
        amount: 4000000,
      },
      {
        department: "Procurement",
        amount: 5000000,
      },
      {
        department: "ICT",
        amount: 6000000,
      },
      {
        department: "Customer Service",
        amount: 7000000,
      },
    ],
    totalAmount: 25000000,
  },
];
const dummyPayrollHistoryInDetail = [
  {
    name: "Susan Megg",
    image: "",
    department: "Human Resource",
    amount: "300000",
  },
  {
    name: "Philip Olaitan",
    image: "",
    department: "Human Resource",
    amount: "400000",
  },
  {
    name: "Sade Akinlade",
    image: "",
    department: "Human Resource",
    amount: "500000",
  },
  {
    name: "Philip Olaitan",
    image: "",
    department: "Human Resource",
    amount: "400000",
  },
  {
    name: "Susan Megg",
    image: "",
    department: "Human Resource",
    amount: "400000",
  },
];
const dummyApprovalData = [
  {
    name: "Susan Megg",
    image: "",
    designation: "PM",
    status: "approved",
    date: "17-02-0203",
  },
  {
    name: "Philip Olaitan",
    image: "",
    designation: "Head Finance",
    status: "approved",
    date: "17-02-0203",
  },
  {
    name: "Sade Akinlade",
    image: "",
    designation: "CPO",
    status: "approved",
    date: "17-02-0203",
  },
  {
    name: "Philip Olaitan",
    image: "",
    designation: "COO",
    status: "pending",
    date: "17-02-0203",
  },
  {
    name: "Susan Megg",
    image: "",
    designation: "CEO",
    status: "pending",
    date: "17-02-0203",
  },
];
const dummyUpcommingEvents = [
  {
    eventName: "3 Day Vacation",
    eventCategory: "Company event",
    eventDate: "2024-04-17",
  },
  {
    eventName: "Hiking",
    eventCategory: "Company event",
    eventDate: "2024-04-17",
  },
  {
    eventName: "Picnic",
    eventCategory: "Company event",
    eventDate: "2024-04-17",
  },
];
const dummyEmployeeVacation = [
  {
    eventName: "Peter Michaels",
    eventCategory: "Paternity leave",
    eventDate: "2024-04-17",
  },
  {
    eventName: "Susan Megg",
    eventCategory: "Annual vacation",
    eventDate: "2024-04-18",
  },
];

const AdminDashboard = () => {
  const [selectedMonthData, setSelectedMonthData] = useState({});
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [payrollHistoryInDetail, setPayrollHistoryInDetail] = useState([]);
  const [approvalData, setApprovalData] = useState([]);
  const [employeeVacation, setEmployeeVacation] = useState([]);
  const { user } = useContext(AuthContext);
  const { payrollHistory, setPayrollHistory, upcomingEvent, setUpcomingEvent } =
    useContext(AdminContext);
  const [pageState, setPageState] = useState(dashboardState.historyInMonth);

  // useEffect(() => {
  //   setPayrollHistory(dummyPayrollHistory);
  // }, []);
  useEffect(() => {
    const fetchUpcommingEvent = async (companyName) => {
      const res = await getUpcommingEvent(companyName);
      if (res?.length > 0) {
        setUpcomingEvent(res);
      }
    };
    if (Object.keys(user).length > 0 && !upcomingEvent) {
      fetchUpcommingEvent(user.companyName);
    }
  }, [user]);
  useEffect(() => {
    const fetchPayrollHistory = async (companyName) => {
      const res = await getPayrollHistoryByMonth(companyName);

      if (res?.length > 0) {
        setPayrollHistory(res);
      }
    };
    if (Object.keys(user).length > 0 && !payrollHistory) {
      fetchPayrollHistory(user.companyName);
    }
  }, [user, payrollHistory]);

  const fetchPayrollHistoryInDetail = async (companyName, date, department) => {
    const res = await getPayrollHistoryInDetail({
      companyName,
      fromDate: date,
      department,
    });
    console.log(res);
    if (res?.length > 0) {
      setPayrollHistoryInDetail(res);
    }
  };
  const fetchApprovalHistory = async (companyName, date) => {
    const res = await getApprovalHistory({ companyName, fromDate: date });
    console.log(res);
    if (res?.length > 0) {
      setApprovalData(res);
    }
  };
  const fetchEmployeeVacation = async (companyName, date) => {
    const res = await getApprovalHistory({ companyName, date });
    console.log(res);
    if (res?.length > 0) {
      setApprovalData(res);
    }
  };

  useEffect(() => {
    if (selectedDepartment?.length > 0) {
      // fetchPayrollHistoryInDetail(user.companyName, selectedMonthData.date, selectedDepartment);
      fetchPayrollHistoryInDetail(
        user.companyName,
        selectedMonthData.date,
        selectedDepartment
      );
      fetchApprovalHistory(user.companyName, selectedMonthData.date);
      fetchEmployeeVacation(user.companyName);
      // // for UI work
      // setPayrollHistoryInDetail(dummyPayrollHistoryInDetail);
      // setApprovalData(dummyApprovalData);
      // setEmployeeVacation(dummyEmployeeVacation);
      // setUpcomingEvent(dummyUpcommingEvents);
    }
  }, [user, selectedMonthData, selectedDepartment]);

  return (
    <div className="w-full">
      <div className="text-[20px] font-inter-bold text-[#011165]">
        Dashboard
      </div>
      <div className="mt-[12px] border-b-[1px] border-[#0000001A]" />
      <div className="flex items-center justify-between py-[40px]">
        {pageState !== dashboardState.approvalHistory && (
          <div className="flex gap-[10px] items-center">
            <div className="font-inter-semibold text-[16px] text-[#000000B2]">
              Payroll history
            </div>
            {pageState === dashboardState.historyInDepartment && (
              <div className="flex gap-[10px] items-center">
                <img src={RightArrow} className="w-[10px]" alt="right-arrow" />
                <div
                  className="font-inter-regular text-base text-[#000000B2] cursor-pointer underline underline-offset-4"
                  onClick={() => {
                    setPageState(dashboardState.historyInMonth);
                    setSelectedMonthData({});
                  }}
                >
                  {getMonthFromDate(selectedMonthData?.date)}
                </div>
                <div className="flex items-center gap-[6px]">
                  <img src={Info} className="w-[18px]" alt="info" />
                  <div className="font-inter-regular text-[10px] text-[#00000066]">
                    Tip: Click month to select other periods
                  </div>
                </div>
              </div>
            )}
            {pageState === dashboardState.historyInDetail && (
              <div className="flex gap-[10px] items-center">
                <img src={RightArrow} className="w-[10px]" alt="right-arrow" />
                <div
                  className="font-inter-regular text-base text-[#000000B2] cursor-pointer underline underline-offset-4"
                  onClick={() => {
                    setPageState(dashboardState.historyInDepartment);
                    setSelectedDepartment("");
                  }}
                >
                  {selectedDepartment}
                </div>
                <div className="flex items-center gap-[6px]">
                  <img src={Info} className="w-[18px]" alt="info" />
                  <div className="font-inter-regular text-[10px] text-[#00000066]">
                    Tip: Click '{selectedDepartment}' to select other periods
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {pageState === dashboardState.approvalHistory && (
          <div className="flex items-center">
            <img
              src={LeftArrow}
              className="w-[15px] cursor-pointer"
              alt="arrow"
              onClick={() => setPageState(dashboardState.historyInDetail)}
            />
            <button
              className="ml-[40px] rounded-md font-inter-medium text-base text-white p-[10px] bg-[#011165]"
              onClick={() => {}}
            >
              Approval Requests
            </button>
            <button
              className="ml-[20px] rounded-md font-inter-medium text-base text-[#A6A6A6] p-[10px]"
              onClick={() => {}}
            >
              Approval History
            </button>
          </div>
        )}
      </div>
      {pageState === dashboardState.historyInMonth && (
        <div className="w-full flex items-center gap-[15px] py-[0px]">
          <img src={Info} className="w-[19px]" alt="info" />
          <div className="font-inter-regular text-[13px] text-[#00000066]">
            Select month to see full payroll history
          </div>
        </div>
      )}
      {pageState === dashboardState.historyInMonth && (
        <HistoryInMonth
          payrollHistory={payrollHistory}
          setSelectedMonthData={setSelectedMonthData}
          setPageState={setPageState}
        />
      )}
      {pageState === dashboardState.historyInDepartment && (
        <HistoryInDepartment
          selectedMonthData={selectedMonthData}
          setSelectedDepartment={setSelectedDepartment}
          setPageState={setPageState}
        />
      )}
      {pageState === dashboardState.historyInDetail && (
        <HistoryInDetail
          payrollHistory={payrollHistoryInDetail}
          approvalData={approvalData}
          employeeVacation={employeeVacation}
          setPageState={setPageState}
        />
      )}
      {pageState === dashboardState.approvalHistory && (
        <ApprovalHistory
          approvalData={approvalData}
          setPageState={setPageState}
        />
      )}
    </div>
  );
};
export default AdminDashboard;
