import React, { useState, useEffect, useContext } from "react";
import { EmployeeContext } from "../../contexts/EmployeeContext";
import CalendarDate from "./CalendarDate";
import { WEEK_ABBR_NAMES } from "../../constants/Calendar/week";
import { MONTH_NAMES, MONTH_ABBR_NAMES } from "../../constants/Calendar/month";

const Home = () => {
  const [dayArr, setDayArr] = useState([]);

  const { focusDate, setFocusDate } = useContext(EmployeeContext);

  useEffect(() => {
    if (focusDate) {
      const firstDate = new Date(focusDate.year, focusDate.month, 1);
      const firstDayofview = new Date(firstDate);
      firstDayofview.setDate(firstDate.getDate() - firstDate.getDay() - 1);
      const _arr = [];
      const nextDay = new Date(firstDayofview);
      for (let i = 0; i < 5; i++) {
        const rowArr = [];
        for (let j = 0; j < 7; j++) {
          nextDay.setDate(nextDay.getDate() + 1);
          rowArr.push({
            date: nextDay.getDate(),
            month: nextDay.getMonth(),
            year: nextDay.getFullYear(),
            week_day: nextDay.getDay()
          });
        }
        _arr.push(rowArr);
      }
      setDayArr(_arr);
    }
  }, [focusDate]);

  const handleClickPreviousMonth = () => {
    if (focusDate.month === 0) {
      setFocusDate({ ...focusDate, year: focusDate.year - 1, month: 11 });
      return;
    }
    setFocusDate({ ...focusDate, month: focusDate.month - 1 });
  };

  const handleClickNextMonth = () => {
    if (focusDate.month === 11) {
      setFocusDate({ ...focusDate, year: focusDate.year + 1, month: 0 });
      return;
    }
    setFocusDate({ ...focusDate, month: focusDate.month + 1 });
  };

  return (
    <div className="px-2 py-3">
      <div className="flex items-center justify-between w-full">
        <h3 className="m-0 mr-16 text-[15px] text-[#00000080] font-inter-medium">{`${MONTH_ABBR_NAMES[focusDate.month]} ${focusDate.year}`}</h3>
        <div className="flex">
          <div className="flex items-center justify-center w-[32px] h-[32px] rounded-[50%] bg-white hover:bg-[#ebebeb] mr-4">
            <svg
              className="cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 1024 1024"
              onClick={() =>handleClickPreviousMonth()
              }
            >
              <path
                fill="#00000080"
                d="M685.248 104.704a64 64 0 0 1 0 90.496L368.448 512l316.8 316.8a64 64 0 0 1-90.496 90.496L232.704 557.248a64 64 0 0 1 0-90.496l362.048-362.048a64 64 0 0 1 90.496 0z"
              />
            </svg>
          </div>
          <div className="flex items-center justify-center w-[32px] h-[32px] rounded-[50%] bg-white hover:bg-[#ebebeb]">
            <svg
              className="cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 1024 1024"
              onClick={() => handleClickNextMonth()
              }
            >
              <g transform="rotate(180 512 512)">
                <path
                  fill="#00000080"
                  d="M685.248 104.704a64 64 0 0 1 0 90.496L368.448 512l316.8 316.8a64 64 0 0 1-90.496 90.496L232.704 557.248a64 64 0 0 1 0-90.496l362.048-362.048a64 64 0 0 1 90.496 0z"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
      <table className="w-full">
        <thead>
          <tr>
            {WEEK_ABBR_NAMES &&
              WEEK_ABBR_NAMES.length > 0 &&
              WEEK_ABBR_NAMES.map((name, index) => (
                <th
                  key={name}
                  className={`w-[14.3%] border-[1px] border-solid border-[#9999994D] pt-1 font-normal text-sm text-white ${index === 0 || index === 6 ? 'bg-[#FF5800]' : 'bg-[#011165]'}`}
                >
                  {name.toLocaleUpperCase()}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {dayArr &&
            dayArr.length > 0 &&
            dayArr.map((weekArr, weekNumber) => (
              <tr key={`week-${weekNumber}`}>
                {weekArr &&
                  weekArr.length > 0 &&
                  weekArr.map((day, index) => (
                    <CalendarDate
                      key={`day-${index}`}
                      day={day}
                      weekNumber={weekNumber}
                    />
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Home;
