import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminContext } from "../../../contexts/AdminContext";
import Search from "../../../assets/images/dashboard/svg/search.svg";
import Terminate from "../../../assets/images/dashboard/svg/terminate.svg";
import Dropdown from "../../../components/Dropdown/index";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  getEmployeeData,
  getPayrollData,
  updateApprovalActivity,
} from "../../../services/api";
import {
  approvePayrollState,
  employeeRecordState,
  initiatePayrollState,
} from "../../../constants/constants";
import ApproveRequest from "./ApproveRequest";
import PayrollDetailTable from "./PayrollDetailTable";
import ApproveSuccess from "./ApproveSuccess";
import DeclineConfirm from "./DeclineConfirm";
import DeclineSuccess from "./DeclineSuccess";
import Initiator from "./Initiator";
import { getMonthFromDate, getYearFromDate } from "../../../utils/helper";
import { NotificationContext } from "../../../contexts/NotificationContext";
const dummyEmployee = [
  {
    firstName: "Peter",
    middleName: "Oscar",
    lastName: "Pan",
    email: "peterpan@gmail.com",
    phoneNumber: "+1 2328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "HR",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "23-03-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Peter Pan",
    bankAccountNumber: 3940392019,
    isBankAccountValidated: true,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000002,
    status: "active",
  },
  {
    firstName: "Simon",
    middleName: "Oscar",
    lastName: "Pegg",
    email: "simonpegg@gmail.com",
    phoneNumber: "+1 4328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "ICT",
    position: "UX designer",
    lineManager: "Marvin Ralph",
    hireDate: "14-05-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Simon Pegg",
    bankAccountNumber: 4920392019,
    isBankAccountValidated: true,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "active",
  },
  {
    firstName: "Susan",
    middleName: "",
    lastName: "Megg",
    email: "susanmegg@gmail.com",
    phoneNumber: "+1 2328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "HR",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "05-09-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Susan Megg",
    bankAccountNumber: 7940392013,
    isBankAccountValidated: true,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "active",
  },
  {
    firstName: "Philip",
    middleName: "Mae",
    lastName: "Olaitan",
    email: "philip@gmail.com",
    phoneNumber: "+1 9328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "Finance",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "30-07-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Peter Pan",
    bankAccountNumber: 3940392019,
    isBankAccountValidated: false,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "leave",
  },
  {
    firstName: "Sade",
    middleName: "Mae",
    lastName: "Akinlade",
    email: "akinalade@gmail.com",
    phoneNumber: "+1 2328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "HR",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "23-03-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Peter Pan",
    bankAccountNumber: 2300392019,
    isBankAccountValidated: false,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "terminated",
  },
  {
    firstName: "Sade",
    middleName: "Mae",
    lastName: "Akinlade",
    email: "akinalade@gmail.com",
    phoneNumber: "+1 2328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "HR",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "23-03-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Peter Pan",
    bankAccountNumber: 2300392019,
    isBankAccountValidated: false,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "leave",
  },
];
const AdminApprove = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { payrollData, setPayrollData, approvalData, setApprovalData } =
    useContext(AdminContext);
  const { addNotification } = useContext(NotificationContext); // Use the notification context
  const [pageState, setPageState] = useState(
    approvePayrollState.approveRequest
  );
  const [userState, setUserState] = useState("none");
  const [payrollDate, setPayrolDate] = useState("");
  useEffect(() => {
    const fetchPayrollData = async (companyName) => {
      const res = await getPayrollData(companyName);

      if (!res) {
      } else {
        setPayrollData(res.payroll);
        setApprovalData(res.approval);
      }
    };
    if (Object.keys(user).length > 0) {
      fetchPayrollData(user.companyName);
    }
  }, [user]);
  useEffect(() => {
    if (approvalData) {
      if (user.userId === approvalData?.approverId) {
        setUserState("approver");
      } else if (user.userId === approvalData?.initiatorId) {
        setUserState("initiator");
      } else {
        setUserState("none");
      }
    }
  }, [approvalData]);

  useEffect(() => {
    if (userState === "approver") {
      setPageState(approvePayrollState.approveRequest);
    } else if (userState === "initiator") {
      setPageState(approvePayrollState.initiator);
    }
  }, [userState]);

  useEffect(() => {
    if (payrollData && payrollData?.length > 0) {
      const month = getMonthFromDate(payrollData[0].fromDate);
      const year = getYearFromDate(payrollData[0].fromDate);
      setPayrolDate(`${month} ${year}`);
    }
  }, [payrollData]);
  const handleApprove = async () => {
    const res = await updateApprovalActivity({
      companyName: user.companyName,
      approverId: user.userId,
      payrollId: approvalData.payrollId,
      approval: "approved",
    });
    if (res.message) {
      addNotification("The funds sent successfully!"); // Add success notification
      setPageState(approvePayrollState.approveSuccess);
    } else {
      addNotification("Failed to approve payroll."); // Add error notification
    }
  };
  const handleDecline = async () => {
    setPageState(approvePayrollState.approveDeclineConfirm);
  };
  const handleConfirmDecline = async () => {
    const res = await updateApprovalActivity({
      companyName: user.companyName,
      approverId: user.userId,
      payrollId: approvalData.payrollId,
      approval: "canceled",
    });
    console.log(res);
    setPageState(approvePayrollState.approveDeclineSuccess);
  };
  return (
    <div className="w-full">
      <div className="text-[20px] font-inter-bold text-[#011165]">
        Payroll Management
      </div>
      <div className="mt-[12px] border-b-[1px] border-[#0000001A]" />
      {userState === "none" ? (
        <></>
      ) : (
        <>
          <div className="w-full flex justify-between items-center py-[25px]">
            <div className="font-inter-bold text-base text-[#404040]">
              Approve/Deny Payroll
            </div>
            <div className="flex gap-[10px]">
              {pageState === approvePayrollState.approvePayrollDetail && (
                <>
                  <button
                    className="rounded-md font-inter-bold text-[10px] text-[#011165] p-[10px] border-[1px] border-[#011165]"
                    onClick={() => {}}
                  >
                    DECLINE
                  </button>
                  <button
                    className="flex items-center rounded-md font-inter-bold text-[10px] text-white p-[10px] px-[15px] bg-[#011165]"
                    onClick={() => {}}
                  >
                    APPROVE
                  </button>
                </>
              )}
            </div>
          </div>
          {pageState === approvePayrollState.approveRequest && (
            <ApproveRequest
              setPageState={setPageState}
              payrollDate={payrollDate}
              handleApprove={handleApprove}
              handleDecline={handleDecline}
            />
          )}
          {pageState === approvePayrollState.approvePayrollDetail && (
            <PayrollDetailTable
              payrollData={payrollData}
              setPageState={setPageState}
            />
          )}
          {pageState === approvePayrollState.approveSuccess && (
            <ApproveSuccess
              setPageState={setPageState}
              payrollDate={payrollDate}
            />
          )}
          {pageState === approvePayrollState.approveDeclineConfirm && (
            <DeclineConfirm
              setPageState={setPageState}
              payrollDate={payrollDate}
              handleConfirmDecline={handleConfirmDecline}
            />
          )}
          {pageState === approvePayrollState.approveDeclineSuccess && (
            <DeclineSuccess
              setPageState={setPageState}
              payrollDate={payrollDate}
            />
          )}
          {pageState === approvePayrollState.initiator && (
            <Initiator setPageState={setPageState} payrollDate={payrollDate} />
          )}
        </>
      )}
    </div>
  );
};
export default AdminApprove;
