import { useContext, useEffect, useMemo, useState } from "react";
import { useTable, useSortBy } from "react-table";

import TotalAmount from "../../../assets/images/dashboard/svg/total-amount.svg";
import RightArrow from "../../../assets/images/dashboard/svg/right-arrow.svg";
import SortIcon from "../../../assets/images/dashboard/svg/sort-icon.svg";
import { getMonthFromDate } from "../../../utils/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { dashboardState } from "../../../constants/constants";

const HistoryInDepartment = (props) => {
  const { selectedMonthData, setSelectedDepartment, setPageState } = props;
  const data = selectedMonthData.payroll;
  const COLUMNS = [
    { Header: "Department", accessor: "department" },
    { Header: "Amount Paid", accessor: "amount" },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        data,
        columns,
      },
      useSortBy
    );
  useEffect(() => {
    console.log(headerGroups);
  }, [headerGroups]);
  return (
    <div className="w-full">
      <div className="flex ml-[-10px] gap-[20px]">
        <table {...getTableProps()} className="w-3/4 bg-white rounded-xl">
          <thead className="bg-[#EDEDED]">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    scope="col"
                    className={`px-8 py-6 
                      ${
                        column.Header === "Department"
                          ? "rounded-tl-xl"
                          : "rounded-tr-xl"
                      }
                    `}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div className="flex gap-[5px] text-left font-inter-semibold text-sm text-[#000000B2]">
                      {column.render("Header")}
                      <img src={SortIcon} className="w-[8px]" />
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            className="divide-y divide-[#7b78781a]"
            {...getTableBodyProps()}
          >
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      className={`
                          px-8 py-5 whitespace-nowrap text-[#00000080] font-inter-semibold text-sm
                          ${
                            cell.column.Header === "Department"
                              ? "underline underline-offset-4"
                              : ""
                          }
                          ${
                            cell.column.Header === "Amount Paid"
                              ? "flex justify-between items-center"
                              : ""
                          }
                        `}
                      {...cell.getCellProps()}
                    >
                      {cell.column.Header === "Amount Paid"
                        ? `₦ ${cell.value}`
                        : cell.render("Cell")}
                      {cell.column.Header === "Amount Paid" && (
                        <button
                          className="border-[1px] border-[#00000026] rounded-md font-inter-medium text-[10px] text-[#000000B2] p-[5px]"
                          onClick={() => {
                            console.log(cell);
                            setSelectedDepartment(row.original.department);
                            setPageState(dashboardState.historyInDetail);
                          }}
                        >
                          View Detail
                        </button>
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="w-1/4">
          <div className="flex flex-col border-[1px] border-[#0111652E] rounded-xl bg-gradient-to-br from-[#01116500] to-[#01116575] pb-[50px] px-[30px]">
            <div className="flex items-center gap-[20px] py-[20px]">
              <div className="bg-white rounded-md py-[12px] px-[8px]">
                <img
                  src={TotalAmount}
                  className="w-[35px]"
                  alt="total amount"
                />
              </div>
              <div className="font-inter-semibold text-[14px] text-[#011165]">
                Total Amount Paid
              </div>
            </div>
            <div className="border-b-[1px] border-[#FFFFFF59]" />
            <div className="font-inter-semibold text-[14px] text-[#00000080] py-[10px]">
              {getMonthFromDate(selectedMonthData.date)}
            </div>
            <div className="font-inter-semibold text-[32px] text-[#011165]">
              &#8358; {selectedMonthData.totalAmount}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HistoryInDepartment;
