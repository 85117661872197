import { useContext, useEffect, useMemo, useState } from "react";
import { useTable, useSortBy } from 'react-table';

import Modal from "../../../components/Modal/index";
import SortIcon from "../../../assets/images/dashboard/svg/sort-icon.svg";
import Employee from "../../../assets/images/dashboard/svg/employee-sample.svg";
import CheckIcon from "../../../assets/images/dashboard/svg/success-check.svg";

const ApprovalHistory = (props) => {
  const { approvalData, setPageState } = props;
  const [showModal, setShowModal] = useState(false);
  const data = approvalData;
  const COLUMNS = [
    { Header: 'Approver', accessor: 'name' },
    { Header: 'Designation', accessor: 'designation' },
    { Header: 'Status', accessor: 'status' },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    data,
    columns,
  }, useSortBy);
 
  useEffect(() => {
    console.log(headerGroups)
  }, [headerGroups]);
  return (
    <>
      <div className="w-full">
        <div className="flex ml-[-10px] gap-[20px]">
          <div className="w-2/3">
            <table
              {...getTableProps()}
              className="w-full bg-white rounded-xl"
            >
              <thead className="bg-[#EDEDED]">
                {headerGroups.map(headerGroup => (
                  <tr 
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map(column => (
                      <th 
                        scope="col" 
                        className={`px-8 py-6 
                          ${column.Header === 'Approver' ? 'rounded-tl-xl' : ''}
                          ${column.Header === 'Status' ? 'rounded-tr-xl' : ''}
                        `}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        <div className="flex gap-[5px] text-left font-inter-semibold text-sm text-[#000000B2]">
                          {column.render('Header')}
                          <img src={SortIcon} className="w-[8px]" />
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                className="divide-y divide-[#7b78781a]" 
                {...getTableBodyProps()}
              >
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <td 
                          className={
                            `
                              px-8 py-6 text-[#00000080] font-inter-medium text-sm
                              ${cell.column.Header === 'Approver' ? 'flex items-center justify-start gap-[5px]' : ''}
                            `
                          }
                          {...cell.getCellProps()}
                        >
                          {cell.column.Header === 'Approver' && 
                            <img 
                                src={Employee}
                                className="w-[19px]"
                                alt="employee"
                            />
                          }
                          {cell.column.Header === 'Status' ? 
                            <div className="flex gap-[10px]">
                                <div
                                  className={`
                                    ${(cell.column.Header === 'Status' && cell.row.original.status === 'approved') ? 
                                      'bg-[#1DA39259] text-[#1DA392] font-inter-semibold' : 
                                      ''
                                    }
                                    ${(cell.column.Header === 'Status' && cell.row.original.status === 'pending') ? 
                                      'bg-[#FBDCAE] text-[#5D3208] font-inter-semibold' : 
                                      ''
                                    }
                                    px-2 py-1 rounded-xl
                                  `}
                                >
                                  {cell.render('Cell')}
                                </div>
                                {cell.row.original.status === 'pending' && <button 
                                    className="px-2 py-1 rounded-xl text-[#00000080] border-[1px] border-[#00000026] font-inter-semibold text-[12px]"
                                    onClick={() => {setShowModal(true)}}
                                  >
                                    Send Reminder
                                  </button>
                                }
                            </div>
                              : 
                            cell.render('Cell')
                          }
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="w-1/3 flex flex-col gap-[25px]">
            <div className="border-[#EFF0F6] border-[0.92px] rounded-2xl">
              <div className="flex justify-between items-center w-full px-[30px] py-[15px] border-b-[1px] border-[#EFF0F6]">
                <div className="font-eina02-semibold text-[16px] text-[#404040]">Pending Approvals</div>
              </div>
              <div className="border-b-[2px] border-[#FF5800] ml-[30px] mt-[-2px] w-[100px]" />
              <div className="py-[40px] flex justify-center items-center font-inter-bold text-[24px] text-[#404040]">
                3
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} title="Modal Heade">
        <div className="h-[542px]">
          <div className="flex items-center justify-center h-full m-auto">
            <div className="flex flex-col items-center gap-[10px]">
              <img src={CheckIcon} className="w-[73px]" alt="success" />
              <div className="font-inter-bold text-[24px] text-[#011165]">Reminder sent</div>
              <div className="font-inter-regular text-[10px] text-[#011165]">A reminder has been sent and is currently receiving attention.</div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ApprovalHistory;
