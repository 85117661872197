// Header.js
import React, { useContext, useState } from "react";
import CompanyLogo from "../../../assets/images/dashboard/svg/company-logo.svg";
import Bell from "../../../assets/images/dashboard/svg/bell.svg";
import ArrowDown from "../../../assets/images/dashboard/svg/arrow-down.svg";
import { NotificationContext } from "../../../contexts/NotificationContext"; // Adjust the path as needed

const Header = () => {
  const { notifications, clearNotification } = useContext(NotificationContext);
  const [showNotifications, setShowNotifications] = useState(false);

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  return (
    <div className="w-full h-[50px] flex justify-between items-center px-[45px]">
      <img src={CompanyLogo} className="w-[87px]" alt="company-logo" />
      <div className="flex items-center gap-[15px] relative">
        <img
          src={Bell}
          className="w-[24px] cursor-pointer"
          alt="Notifications"
          onClick={toggleNotifications}
        />
        {notifications.length > 0 && (
          <span className="notification-badge">{notifications.length}</span>
        )}
        {showNotifications && (
          <div
            className="notification-dropdown"
            onMouseLeave={() => setShowNotifications(false)}
          >
            {notifications.map(({ id, message, isNew }) => (
              <div
                key={id}
                className={`notification-item ${
                  isNew ? "notification-new" : ""
                }`}
                onClick={() => clearNotification(id)}
              >
                <div className="notification-icon">
                  {/* Icon based on type or generic bell icon */}
                </div>
                <div className="notification-message cursor-pointer">
                  {message}
                </div>
              </div>
            ))}
          </div>
        )}
        <img
          src={ArrowDown}
          className="w-[14px] cursor-pointer"
          alt="Dropdown"
        />
      </div>
    </div>
  );
};

export default Header;
