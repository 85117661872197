import { useContext, useEffect, useState } from "react";
import Info from "../../../assets/images/dashboard/svg/info.svg";
import RightArrow from "../../../assets/images/dashboard/svg/right-arrow.svg";
import Close from "../../../assets/images/dashboard/svg/close-yellow.svg";
import Download from "../../../assets/images/dashboard/svg/download.svg";
import Calendar from "../../../assets/images/dashboard/svg/calendar.svg";
import Vacation from "../../../assets/images/dashboard/svg/vacation-icon.svg";
import { formatDate, formatEventDate, getMonthFromDate } from "../../../utils/helper";
import Dropdown from "../../../components/Dropdown/index";
import { AuthContext } from "../../../contexts/AuthContext";
import { getPaymentHistory, getUpcommingEvent } from "../../../services/api";
import { EmployeeContext } from "../../../contexts/EmployeeContext";
import { AdminContext } from "../../../contexts/AdminContext";
import { dashboardState } from "../../../constants/constants";
 
const HistsoryCard = (props) => {
  const { data, onClick } = props;
  return (
    <div className="w-1/4">
      <div 
        className="m-[10px] px-[20px] py-[25px] border-[1px] border-[#EFF0F6] rounded-xl cursor-pointer  hover:scale-110 transition-transform bg-[#FFFFFF]" 
        onClick={onClick}
      >
        <div className="flex items-center justify-between">
          <div className="font-inter-regular font-[#000000B2] text-[16px]">{getMonthFromDate(data.date)}</div>
          <img src={RightArrow} className="w-[8px] cursor-pointer" alt="detail" />
        </div>
      </div>
    </div>
  )
}
const HistoryInMonth = (props) => {
  const { payrollHistory, setSelectedMonthData, setPageState } = props;
  const { user } = useContext(AuthContext);
  
  return (
    <div className="w-full">
      <div className="flex flex-wrap ml-[-10px]">
        {payrollHistory && payrollHistory?.length > 0 && payrollHistory.map((data, index) => {
          return (
            <HistsoryCard 
              data={data} 
              onClick={() => {
                setSelectedMonthData(data);
                setPageState(dashboardState.historyInDepartment);
              }}
              key={index}
            />
          )
        })}
      </div>
    </div>
  );
};
export default HistoryInMonth;
