import { useState, useContext, useEffect } from "react";
import { EmployeeContext } from "../../../contexts/EmployeeContext";
import Modal from "../../../components/Modal/index";
import Info from "../../../assets/images/dashboard/svg/info.svg";
import CheckIcon from "../../../assets/images/dashboard/svg/success-check.svg";
import ReactDatePicker from "react-datepicker";
import Calendar from "../../../components/Calender/index";
import Dropdown from "../../../components/Dropdown/index";
import {
  addVacationRequest,
  getAvailableLeave,
  updateAvailableLeave,
} from "../../../services/api";
import { AuthContext } from "../../../contexts/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Vacation = () => {
  const [selectedDates, setSelectedDates] = useState([new Date()]);
  const [showModal, setShowModal] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [leaveOption, setLeaveOption] = useState([]);
  const [selectedOption, setSelectedOption] = useState(0);
  const { user } = useContext(AuthContext);
  const { selectedDays, availableLeave, setAvailableLeave } =
    useContext(EmployeeContext);
  useEffect(() => {
    fetchAvailableLeave(user.userId);
  }, [selectedOption]);
  useEffect(() => {
    if (availableLeave?.length > 0) {
      setLeaveOption(
        availableLeave.map((leave, index) => {
          return {
            index,
            label: leave?.vacationCategory,
          };
        })
      );
    }
  }, [availableLeave]);
  const onChange = (dates) => {
    setSelectedDates(dates);
  };
  const fetchAvailableLeave = async (userId) => {
    const res = await getAvailableLeave({
      userId: user.userId,
    });
    if (res?.length > 0) {
      console.log("res", res);
      setAvailableLeave(res);
    }
  };
  const handleProceed = async () => {
    await fetchAvailableLeave(user.userId);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSubmitSuccess(false);
  };
  const handleSubmit = async () => {
    const res = await addVacationRequest({
      firstName: user.firstName,
      middleName: user.middleName,
      lastName: user.lastName,
      companyName: user.companyName,
      department: user.department,
      grossSalary: user.grossSalary,
      vacationCategory: availableLeave[selectedOption].vacationCategory,
      days: selectedDays.length,
      vacationDate: selectedDates,
      userId: user.userId,
    });

    toast.success("Vacation request submitted successfully!");
    await updateAvailableLeave({
      userId: user.userId,
      leftdays: availableLeave[selectedOption].leftDays - selectedDays.length,
      leavetype: leaveOption[selectedOption].label,
    });
    //setSubmitSuccess(true);
    setShowModal(false);
  };
  return (
    <>
      <ToastContainer />
      <div className="w-full">
        <div className="text-[20px] font-eina02-semibold">Vacation</div>
        <div className="mt-[12px] border-b-[1px] border-[#0000001A]" />
        <div className="w-2/3 flex justify-end items-center gap-[15px] py-[20px] pr-[10px]">
          <img src={Info} className="w-[19px]" alt="info" />
          <div className="font-inter-regular text-[10px] text-[#00000080]">
            Click on calendar to choose leave days
          </div>
        </div>
        <div className="flex">
          <div className="w-2/3 border-[1px] border-[#EFF0F6] rounded-2xl">
            {/* <ReactDatePicker
              selectedDates={selectedDates}
              selectsMultiple
              onChange={onChange}
              shouldCloseOnSelect={false}
              disabledKeyboardNavigation
              inline
              className="custom-datepicker"
              calendarClassName="custom-datepicker-calendar"
              dayClassName={(date) => (date.getDay() === 0 || date.getDay() === 6) ? 'custom-datepicker-weekend' : undefined}
              wrapperClassName="custom-datepicker-wrapper" // Apply wrapper class
            /> */}
            <Calendar />
            <div className="flex justify-end">
              <button
                className={`
                  flex justify-center py-[7px] px-[15px] rounded-lg text-white font-inter-semibold text-[10px] 
                  ${
                    selectedDays?.length !== 0
                      ? "bg-[#011165] hover:bg-[#011265e1]"
                      : "bg-[#01116526]"
                  }
                `}
                disabled={selectedDays?.length === 0}
                onClick={handleProceed}
              >
                Proceed
              </button>
            </div>
          </div>
          <div className="w-1/3 border-[1px] border-[#EFF0F6] rounded-2xl ml-[14px]">
            <div className="font-inter-medium text-[15px] text-[#00000080] p-[35px] pb-[10px]">
              Outstanding holidays
            </div>
            <div className="border-b-[1px] border-[#0000001A]" />
            {availableLeave &&
              availableLeave?.length > 0 &&
              availableLeave.map((leave) => {
                return (
                  <div className="px-[35px] py-[10px]">
                    <div className="font-inter-medium text-[12px] text-[#00000066]">
                      {leave.vacationCategory}
                    </div>
                    <div className="font-inter-medium text-[18px] text-[#000000B2]">
                      {leave.leftDays}
                    </div>
                  </div>
                );
              })}
            {/* <div className="px-[35px] py-[10px]">
              <div className="font-inter-medium text-[12px] text-[#00000066]">{availableLeave[0].vacationCategory}</div>
              <div className="font-inter-medium text-[18px] text-[#000000B2]">14 Days</div>
            </div>
            <div className="px-[35px] py-[10px]">
              <div className="font-inter-medium text-[12px] text-[#00000066]">Sick leave</div>
              <div className="font-inter-medium text-[18px] text-[#000000B2]">8 Days</div>
            </div>
            <div className="px-[35px] py-[10px]">
              <div className="font-inter-medium text-[12px] text-[#00000066]">Emmergency</div>
              <div className="font-inter-medium text-[18px] text-[#000000B2]">3 Days</div>
            </div> */}
          </div>
        </div>
      </div>
      <Modal isOpen={showModal} onClose={handleCloseModal} title="Modal Heade">
        <div className="h-[542px]">
          {submitSuccess ? (
            <div className="flex items-center justify-center h-full m-auto">
              <div className="flex flex-col items-center gap-[10px]">
                <img src={CheckIcon} className="w-[73px]" alt="success" />
                <div className="font-inter-bold text-[24px] text-[#011165]">
                  Successful!
                </div>
                <div className="font-inter-regular text-[10px] text-[#011165]">
                  Your leave request has been sucessfully submitted
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="font-inter-medium text-[24px] text-[#000000B2] pt-[30px] pb-[15px]">
                Choose leave category
              </div>
              <div className="border-b-[1px] border-[#0000001A] mx-[-40px]" />
              <div className="w-full pt-[40px] flex flex-col gap-[40px]">
                <Dropdown
                  options={leaveOption}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  selectClassName={`font-inter-medium text-[15px] text-[#00000080] w-full py-[10px]`}
                  optionClassName={`font-inter-medium text-[15px] text-[#00000080]`}
                />
                <div className="flex justify-between">
                  <div className="flex flex-col">
                    <div className="font-inter-regular text-[14px] text-[#000000B2]">
                      Available leave days
                    </div>
                    <div className="font-inter-bold text-[10px] text-[#000000B2]">
                      Type:{" "}
                      {availableLeave &&
                        availableLeave?.length > 0 &&
                        availableLeave[selectedOption].vacationCategory}
                    </div>
                  </div>
                  <div className="font-inter-bold text-[16px] text-[#404040]">
                    {availableLeave &&
                      availableLeave?.length > 0 &&
                      availableLeave[selectedOption].leftDays -
                        selectedDays.length}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="font-inter-regular text-[14px] text-[#000000B2]">
                    Leave days chosen
                  </div>
                  <div className="font-inter-bold text-[16px] text-[#404040]">
                    {selectedDays.length}
                  </div>
                </div>
              </div>
              <div className="flex justify-center pt-[50px]">
                <button
                  className="flex justify-center py-[7px] px-[15px] rounded-lg text-white font-inter-semibold text-[14px] bg-[#011165] hover:bg-[#011265e1]"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
              <div className="w-full flex items-center gap-[10px] py-[30px]">
                <img src={Info} className="w-[19px]" alt="info" />
                <div className="font-inter-regular text-[10px] text-[#00000080]">
                  Note: You will have 0 number of leave days left for this
                  category
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
export default Vacation;
