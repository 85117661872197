import { useContext, useEffect, useState } from "react";
import {
  addPaymentSetting,
  deletePaymentSetting,
  getBankList,
  getPaymentSetting,
  markDefaultPaymentSetting,
} from "../../../services/api";
import { AuthContext } from "../../../contexts/AuthContext";
import { EmployeeContext } from "../../../contexts/EmployeeContext";
import Modal from "../../../components/Modal/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PaymentSetting = () => {
  const { user } = useContext(AuthContext);
  const { paymentSetting, setPaymentSetting, bankList, setBankList } =
    useContext(EmployeeContext);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [isDefault, setIsDefault] = useState(false);

  const fetchPaymentSetting = async (userId) => {
    const res = await getPaymentSetting(userId);
    console.log(res);
    if (res?.length > 0) {
      setPaymentSetting(res);
    }
  };
  useEffect(() => {
    if (Object.keys(user).length > 0 && !paymentSetting) {
      fetchPaymentSetting(user.userId);
    }
  }, [user, paymentSetting]);
  useEffect(() => {
    fetchBankList();
  }, []);
  const fetchBankList = async () => {
    const res = await getBankList();

    setBankList(res);
  };
  const [selectedRows, setSelectedRows] = useState([]);

  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    }
  };
  const handleMarkDefault = async () => {
    const res = await markDefaultPaymentSetting({
      paymentSettingId: paymentSetting[selectedRows[0]].id,
      userId: user.userId,
    });
    console.log(res);
    if (res) {
      const res = await getPaymentSetting(user.userId);
      console.log(res);
      if (res?.length > 0) {
        setPaymentSetting(res);
      }
    }
  };
  const handleDeletePayments = async () => {
    if (selectedRows?.length > 0) {
      const ids = selectedRows.map((index) => paymentSetting[index].id);
      const res = await deletePaymentSetting(ids);
      if (res) {
        const res = await getPaymentSetting(user.userId);
        if (res?.length > 0) {
          setPaymentSetting(res);
        }
      }
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      !formData.bankName ||
      !formData.bankAccountName ||
      !formData.bankAccountNumber
    ) {
      // Displaying different messages based on missing data for better user guidance
      if (!formData.bankName) {
        toast.error("Bank Name is required.");
      }
      if (!formData.bankAccountName) {
        toast.error("Bank Account Name is required.");
      }
      if (!formData.bankAccountNumber) {
        toast.error("Bank Account Number is required.");
      }
      return; // Exit the function to prevent further execution
    }
    try {
      const res = await addPaymentSetting({
        ...formData,
        isDefault,
        name: `${user.firstName}${user.middleName || ""}${user.lastName}`,
        userId: user.userId,
      });

      toast.success("Payment account created successfully!");
      fetchPaymentSetting(user.userId); // Refresh the payment settings
      setFormData({}); // Clear form data
      setIsDefault(false);
      setShowModal(false);
    } catch (error) {
      toast.error("An error occurred while creating the account.");
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="w-full">
        <div className="text-[20px] font-eina02-semibold">Payment settings</div>
        <div className="mt-[12px] border-b-[1px] border-[#0000001A]" />
        <div className="max-w-[776px] mt-[20px] border-[#EFF0F6] bg-white border-[1px] rounded-2xl">
          <div className="flex justify-between items-center px-[20px] py-[35px] border-b-[1px] border-[#0000001A]">
            <span className="font-inter-medium text-[16px] text-[#00000080]">
              Bank details
            </span>
            <div className="flex gap-[8px]">
              <button
                className="flex justify-center py-[7px] px-[15px] rounded-lg text-white font-inter-semibold text-[10px] bg-[#011165] hover:bg-[#011265e1]"
                onClick={() => setShowModal(true)}
              >
                Create account
              </button>
              <button
                className={`
                  flex justify-center py-[7px] px-[15px] rounded-lg text-white font-inter-semibold text-[10px] 
                  ${
                    selectedRows?.length === 1
                      ? "bg-[#011165] hover:bg-[#011265e1]"
                      : "bg-[#01116526]"
                  }
                `}
                disabled={selectedRows?.length !== 1}
                onClick={handleMarkDefault}
              >
                Mark as default
              </button>
              <button
                className={`
                  flex justify-center py-[7px] px-[15px] rounded-lg text-white font-inter-semibold text-[10px] 
                  ${
                    selectedRows?.length !== 0
                      ? "bg-[#241818] hover:bg-[#ff0000d3]"
                      : "bg-[#01116526]"
                  }
                `}
                disabled={selectedRows?.length === 0}
                onClick={handleDeletePayments}
              >
                Delete
              </button>
            </div>
          </div>
          <div className="flex pt-[20px]">
            <table className="min-w-full divide-y divide-[#0000001A]">
              <thead className="">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left"></th>
                  <th
                    scope="col"
                    className="px-6 py-5 text-left text-[13px] font-inter-medium text-[#00000080]"
                  >
                    Bank Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-5 text-left text-[13px] font-inter-medium text-[#00000080]"
                  >
                    Bank Account Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-5 text-left text-[13px] font-inter-medium text-[#00000080]"
                  >
                    Bank Account Number
                  </th>
                </tr>
              </thead>
              <tbody>
                {paymentSetting &&
                  paymentSetting.map((row, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <input
                          type="checkbox"
                          className="w-4 h-4 text-indigo-600 transition duration-150 ease-in-out form-checkbox"
                          onChange={(e) => handleCheckboxChange(e, index)}
                        />
                      </td>
                      <td className="px-6 py-5 whitespace-nowrap">
                        <div
                          className={`font-inter-regualar text-[12px] ${
                            row.isDefault
                              ? "text-[#000000] font-inter-medium"
                              : "text-[#000000B2]"
                          }`}
                        >
                          {row.bankName}
                        </div>
                      </td>
                      <td className="px-6 py-5 whitespace-nowrap">
                        <div
                          className={`font-inter-regualar text-[12px] ${
                            row.isDefault
                              ? "text-[#000000] font-inter-medium"
                              : "text-[#000000B2]"
                          }`}
                        >
                          {row.bankAccountName}
                        </div>
                      </td>
                      <td className="px-6 py-5 whitespace-nowrap">
                        <div
                          className={`font-inter-regualar text-[12px] ${
                            row.isDefault
                              ? "text-[#000000] font-inter-medium"
                              : "text-[#000000B2]"
                          }`}
                        >
                          {row.bankAccountNumber}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title="Modal Heade"
      >
        <div className="max-h-[678px] min-w-[588px]">
          <div className="flex gap-[20px]">
            <div className="flex flex-col justify-between">
              <div className="font-inter-semibold text-[20px] text-[#011165]">
                Create Account
              </div>
              <div className="font-inter-regular text-[12px] text-[#00000080] pt-[13px]">
                Add bank account details
              </div>
            </div>
          </div>
          <div className="pt-[25px] border-b-[1px] border-[#00000026]" />
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-[30px] py-[30px]">
              <div className="w-full flex flex-col gap-[10px]">
                <div className="w-full flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                  <div className="font-inter-bold text-[#000000B2]">
                    Bank Name
                  </div>
                  {/* Conditional rendering check */}
                  {bankList && bankList.length > 0 && (
                    <select
                      name="bankName"
                      className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                      value={formData.bankName || ""}
                      onChange={handleInputChange}
                    >
                      {bankList.map((bank, index) => (
                        <option key={index} value={bank.label}>
                          {bank.label}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                <div className="w-full flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                  <div className="font-inter-bold text-[#000000B2]">
                    Bank Account Name
                  </div>
                  <input
                    type="text"
                    name="bankAccountName"
                    className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                    value={formData.bankAccountName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="w-full flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                  <div className="font-inter-bold text-[#000000B2]">
                    Bank Account Number
                  </div>
                  <input
                    type="text"
                    name="bankAccountNumber"
                    className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                    value={formData.bankAccountNumber}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-[10px] items-center">
              <input
                type="checkbox"
                name="isDefault"
                className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                value={isDefault}
                onChange={() => setIsDefault((prev) => !prev)}
              />
              <div className="font-inter-semibold text-[10px] text-[#000000B2]">
                Add Existing Employee to Payroll
              </div>
            </div>
            <div className="flex justify-end gap-[10px] pt-[20px]">
              <button
                className="rounded-md font-inter-bold text-[10px] text-[#011165] px-[10px] py-[10px] border-[1px] border-[#011165]"
                onClick={() => setShowModal(false)}
                type="button"
              >
                CANCEL
              </button>
              <button
                className="rounded-md font-inter-bold text-[10px] text-white px-[10px] py-[10px] bg-[#011165]"
                type="submit"
              >
                CREATE ACCOUNT
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default PaymentSetting;
