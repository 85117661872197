import MenCard from "../../components/landingpage_payroll/MenCard";
import {
  PAYROLL_SAVE_IMG,
  PAYROLL_SAVE_DATAS,
} from "../../constants/landingpage_payroll/landingpage_payroll";
const Payroll_Save = () => {
  const titleParts = PAYROLL_SAVE_DATAS.title.split("payroll");
  return (
    <div className=" relative flex justify-between pt-[170px] px-[40px] lg:px-[58px] lg2:px-[75px] xl:px-[98px]">
      <div className="w-2/5">
        {/* <img
          src={PAYROLL_SAVE_IMG.wave}
          alt="Wave"
          className="absolute left-[-70px] top-[-45px] z-10"
        /> */}
        <img
          src={PAYROLL_SAVE_IMG.womanimg}
          alt="Woman"
          className="w-full pl-[5px] lg:pl-[30px] xl:pl-[50px]"
        />
        {/* <MenCard /> */}
      </div>
      <div className="w-3/5 pt-[50px] lg:pt-[100px] lg2:pt-[150px] xl:pt-[200px] pl-[0px] lg:pl-[50px] lg2:pl[100px] xl:pl-[250px]">
        <p className="font-eina02-bold text-[30px] md:text-[35px] lg:text-[40px] lg2:text-[45px] xl:text-[53px] leading-[33px] md:leading-[40px] lg:leading-[48px] xl:leading-[55px] text-payroll_primary max-w-[500px] lg2:max-w-[520px] xl:max-w-[585px]">
          {titleParts[0]}
          <span className="font-eina02-bold text-[#FF5800]">
            payroll
          </span>
          {titleParts[1]}
        </p>
        <p className=" font-eina02-regular font-[400] text-[16px] text-payroll_primary opacity-[45%] w-[420px] pt-[30px]">
          {PAYROLL_SAVE_DATAS.description}
        </p>
        <p className=" font-eina02-bold font-[400] text-[16px] text-payroll_primary pt-[20px]">
          {PAYROLL_SAVE_DATAS.learnmore}
        </p>
      </div>
    </div>
  );
};
export default Payroll_Save;
