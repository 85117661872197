import React from "react";
import { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { authRoutes } from "./AuthRoutes";
import { employeeRoutes } from "./EmployeeRoutes";
import { adminRoutes } from "./AdminRoutes";
import LandingpPage_Payroll from "../pages/landing-payroll/index";
import EmployeeLayout from "../pages/Layout/Employee/EmployeeLayout/index";
import AdminLayout from "../pages/Layout/Admin/AdminLayout/index";
// setup fake backend

const Routers = () => {
  const login = useState(JSON.parse(localStorage.getItem("login")))[0];

  const [authenticated, setAuthenticated] = useState(false);
  const jwt_token = localStorage.getItem("token");

  useEffect(() => {}, []);

  return (
    <BrowserRouter basename={"/"}>
      <Suspense>
        <Routes>
          {authRoutes.map(({ path, Component }, i) => (
            <Route path={path} element={Component} key={i} />
          ))}
          <Route path={`/`} element={<LandingpPage_Payroll />} />
          <Route path={`/employee`} element={<EmployeeLayout />}>
            {employeeRoutes.map(({ path, Component }, i) => (
              <Route path={path} element={Component} key={i} />
            ))}
          </Route>
          <Route path={`/admin`} element={<AdminLayout />}>
            {adminRoutes.map(({ path, Component }, i) => (
              <Route path={path} element={Component} key={i} />
            ))}
          </Route>
          {/* <Route path={`*`} element={<Navigate to={`/`} />} /> */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;
