import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminContext } from "../../../contexts/AdminContext";
import Search from "../../../assets/images/dashboard/svg/search.svg";
import Terminate from "../../../assets/images/dashboard/svg/terminate.svg";
import Dropdown from "../../../components/Dropdown/index";
import { AuthContext } from "../../../contexts/AuthContext";
import { getCompanyApprover, getEmployeeData } from "../../../services/api";
import {
  approvePayrollState,
  approverManagementState,
  employeeRecordState,
  initiatePayrollState,
} from "../../../constants/constants";
import ApproverList from "./ApproverList";
import AddApprover from "./AddApprover";

const dummyApproverData = [
  {
    name: "Susan Megg",
    image: "",
    designation: "PM",
    level: 1,
    date: "17-02-0203",
    email: "susanmegg@gmail.com",
  },
  {
    name: "Philip Olaitan",
    image: "",
    designation: "Head Finance",
    level: 2,
    date: "17-02-0203",
    email: "susanmegg@gmail.com",
  },
  {
    name: "Sade Akinlade",
    image: "",
    designation: "CPO",
    level: 3,
    date: "17-02-0203",
    email: "susanmegg@gmail.com",
  },
  {
    name: "Philip Olaitan",
    image: "",
    designation: "COO",
    level: 4,
    date: "17-02-0203",
    email: "susanmegg@gmail.com",
  },
  // approverName: String,
  //       approverEmail: String,
  //       approverPosition: String,
  //       approverLevel: Number,
  {
    name: "Susan Megg",
    image: "",
    designation: "CEO",
    level: 5,
    date: "17-02-0203",
    email: "susanmegg@gmail.com",
  },
];
const dummyEmployee = [
  {
    firstName: "Peter",
    middleName: "Oscar",
    lastName: "Pan",
    email: "peterpan@gmail.com",
    phoneNumber: "+1 2328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "HR",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "23-03-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Peter Pan",
    bankAccountNumber: 3940392019,
    isBankAccountValidated: true,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000002,
    status: "active",
  },
  {
    firstName: "Simon",
    middleName: "Oscar",
    lastName: "Pegg",
    email: "simonpegg@gmail.com",
    phoneNumber: "+1 4328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "ICT",
    position: "UX designer",
    lineManager: "Marvin Ralph",
    hireDate: "14-05-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Simon Pegg",
    bankAccountNumber: 4920392019,
    isBankAccountValidated: true,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "active",
  },
  {
    firstName: "Susan",
    middleName: "",
    lastName: "Megg",
    email: "susanmegg@gmail.com",
    phoneNumber: "+1 2328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "HR",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "05-09-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Susan Megg",
    bankAccountNumber: 7940392013,
    isBankAccountValidated: true,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "active",
  },
  {
    firstName: "Philip",
    middleName: "Mae",
    lastName: "Olaitan",
    email: "philip@gmail.com",
    phoneNumber: "+1 9328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "Finance",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "30-07-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Peter Pan",
    bankAccountNumber: 3940392019,
    isBankAccountValidated: false,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "leave",
  },
  {
    firstName: "Sade",
    middleName: "Mae",
    lastName: "Akinlade",
    email: "akinalade@gmail.com",
    phoneNumber: "+1 2328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "HR",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "23-03-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Peter Pan",
    bankAccountNumber: 2300392019,
    isBankAccountValidated: false,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "terminated",
  },
  {
    firstName: "Sade",
    middleName: "Mae",
    lastName: "Akinlade",
    email: "akinalade@gmail.com",
    phoneNumber: "+1 2328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "HR",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "23-03-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Peter Pan",
    bankAccountNumber: 2300392019,
    isBankAccountValidated: false,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "leave",
  },
];
const AdminApprovers = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { approverData, setApproverData } = useContext(AdminContext);
  const [pageState, setPageState] = useState(
    approverManagementState.approverList
  );
  const [triggerFetch, setTriggerFetch] = useState(false);
  const fetchApproverData = async (companyName) => {
    const res = await getCompanyApprover(companyName);

    if (res?.length > 0) {
      setApproverData(res);
    }
  };
  useEffect(() => {
    if (Object.keys(user).length > 0 && !approverData) {
      fetchApproverData(user.companyName);
    }
  }, [user]);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      fetchApproverData(user.companyName);
    }
  }, [triggerFetch]);

  return (
    <div className="w-full">
      <div className="text-[20px] font-inter-bold text-[#011165]">
        Approval Management
      </div>
      <div className="mt-[12px] border-b-[1px] border-[#0000001A]" />
      <>
        {pageState !== approverManagementState.addNewApprover && (
          <div className="w-full flex justify-between items-center py-[25px]">
            <div className="font-inter-bold text-base text-[#404040]">
              Approvers
            </div>
            <div className="flex gap-[10px]">
              <button
                className="rounded-md font-inter-bold text-[10px] text-white p-[10px] bg-[#011165]"
                onClick={() => {
                  setPageState(approverManagementState.addNewApprover);
                }}
              >
                + ADD APPROVER
              </button>
              {/* <button 
                className="flex items-center rounded-md font-inter-bold text-[10px] text-white p-[10px] px-[15px] bg-[#011165]"
                onClick={() => {
                }}
              >
                EDIT
              </button> */}
            </div>
          </div>
        )}
        {pageState === approverManagementState.approverList && approverData && (
          <ApproverList
            approverData={approverData}
            setPageState={setPageState}
          />
        )}
      </>
      {pageState === approverManagementState.addNewApprover && (
        <AddApprover
          setPageState={setPageState}
          approverData={approverData}
          user={user}
          setTriggerFetch={setTriggerFetch}
        />
      )}
    </div>
  );
};
export default AdminApprovers;
