import { useContext, useEffect, useState } from "react";
import Modal from "../../../components/Modal/index";
import Info from "../../../assets/images/dashboard/svg/info.svg";
import ThreeDots from "../../../assets/images/dashboard/svg/three-dots.svg";
import LeftArrow from "../../../assets/images/dashboard/svg/left-arrow.svg";
import SaveIcon from "../../../assets/images/dashboard/svg/save.svg";
import PrintIcon from "../../../assets/images/dashboard/svg/print.svg";
import DownloadIcon from "../../../assets/images/dashboard/svg/download2.svg";
import { formatDate, getMonthFromDate } from "../../../utils/helper";
import { EmployeeContext } from "../../../contexts/EmployeeContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { MarkSVG } from "../../../constants/payrollmanagement/payroll_management";
import {
  getApprovalHistory,
  getEmployeeData,
  getPaymentHistory,
  getPayrollHistoryByMonth,
  getPayrollHistoryInDetail,
} from "../../../services/api";
import { AdminContext } from "../../../contexts/AdminContext";
import {
  employeeBandsState,
  payrollHistoryState,
} from "../../../constants/constants";
import EmployeeBandsTable from "./EmployeeBandsTable";
import { createBandData } from "../../../services/api";
const PayrollEmployeeBands = () => {
  const [roles, setRoles] = useState([]);
  const { user } = useContext(AuthContext);
  const { employeeData, setEmployeeData } = useContext(AdminContext);
  const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(0);
  const [pageState, setPageState] = useState(employeeBandsState.employeeTable);
  const [enterBandName, setEnterBandName] = useState("");
  const [error, setError] = useState(""); // State to handle error message
  const [showModal, setShowModal] = useState(false);
  const fetchEmployeeData = async (companyName) => {
    const res = await getEmployeeData(companyName);

    if (res?.length > 0) {
      setEmployeeData(res);
    }
  };
  useEffect(() => {
    if (Object.keys(user).length > 0 && !employeeData) {
      fetchEmployeeData(user.companyName);
    }
  }, [user]);
  const handleChange = (e) => {
    if (e.target.value.trim() === "") {
      setError("Band name is required."); // Set error if input is empty
    } else {
      setError(""); // Clear error when valid input is entered
    }
    setEnterBandName(e.target.value);
  };
  const handleBandCreate = async () => {
    if (!enterBandName) {
      setError("Band name is required."); // Set error if input is empty
      return;
    }
    console.log(" user.companyName", user.companyName);
    // Proceed with creating the band
    setPageState(employeeBandsState.employeeTable);
    setError(""); // Reset error message
    const payload = {
      companyName: user.companyName,
      name: enterBandName,
    };
    const res = await createBandData(payload);
    setShowModal(true);
  };
  return (
    <>
      <div className="w-full">
        <div className="text-[20px] font-inter-bold text-[#011165]">
          Payroll Management
        </div>
        <div className="mt-[12px] border-b-[1px] border-[#0000001A]" />
        <div className="flex gap-[30px] items-center cursor-pointer pt-[25px]">
          <div className="text-base font-inter-bold text-[#404040]">
            View Employee Bands
          </div>
        </div>
        <div className="flex justify-start mt-[38px] gap-[20px]">
          {roles.map((role, index) => (
            <div
              key={index}
              className="w-[250px] h-[97px] bg-white rounded-md pl-[22px] pt-[11px] cursor-pointer"
            >
              <p className="font-inter-medium font-[500] text-[13px] opacity-50">
                {role.label} Band
              </p>
              <p className="font-inter-medium font-[400] text-[10px] opacity-50 mt-[20px]">
                Employees
              </p>
              <p className="font-inter-medium font-[400] text-[18px]">
                {role.employeeCount}
              </p>
            </div>
          ))}
        </div>
        {pageState == employeeBandsState.employeeTable && (
          <>
            {employeeData && employeeData?.length > 0 && (
              <EmployeeBandsTable
                employeeData={employeeData}
                setSelectedEmployeeIndex={setSelectedEmployeeIndex}
                setPageState={setPageState}
                roles={roles}
                setRoles={setRoles}
              />
            )}
          </>
        )}
        {pageState == employeeBandsState.createBands && (
          <>
            <div className=" w-[483px] h-[230px] bg-white border-[1px] border-[#00000033] rounded-md mt-[43px]  pt-[24px]">
              <p className=" font-inter-medium text-[14px] font-[500] opacity-90 ml-[34px]">
                Band Name
              </p>
              <input
                type="text"
                id="bandName"
                name="bandName"
                value={enterBandName}
                onChange={(e) => setEnterBandName(e.target.value)}
                placeholder="Enter band name"
                className="h-[50px] px-4 py-2 w-[90%] rounded-md border-[#00000033] border-[1px] pr-[50px] text-[#000000] mt-[24px] mx-[5%]"
                required // Added the required attribute
              />
              {error && (
                <p className="text-red-500 text-xs ml-[5%] mt-2 text-[#FF0000]">
                  {error}
                </p>
              )}
              <div
                className="flex justify-center items-center w-[116px] h-[41px] rounded-md bg-payroll_primary border-[1px] ml-[330px] mt-[36px] cursor-pointer active:bg-opacity-30"
                onClick={handleBandCreate}
              >
                <p className=" font-inter-regular text-[10px] font-[600] text-white">
                  CREATE BAND
                </p>
              </div>
            </div>
          </>
        )}
        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          title="Modal Header"
        >
          <div className="w-[452px] h-[534px] rounded-lg flex flex-col items-center justify-center gap-[16px]">
            <img src={MarkSVG} alt="Success" />
            <p className=" font-inter-bold font-[600] text-[24px] text-payroll_primary">
              Successful
            </p>
            <p className=" font-inter-regular font-[400] text-[10px] text-payroll_primary">
              {enterBandName} successfully created. Add employee to band.
            </p>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default PayrollEmployeeBands;
