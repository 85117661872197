import { useContext, useEffect, useState } from "react";
import Modal from "../../../components/Modal/index";
import Info from "../../../assets/images/dashboard/svg/info.svg";
import ThreeDots from "../../../assets/images/dashboard/svg/three-dots.svg";
import LeftArrow from "../../../assets/images/dashboard/svg/left-arrow.svg";
import SaveIcon from "../../../assets/images/dashboard/svg/save.svg";
import PrintIcon from "../../../assets/images/dashboard/svg/print.svg";
import DownloadIcon from "../../../assets/images/dashboard/svg/download2.svg";
import { formatDate, getMonthFromDate } from "../../../utils/helper";
import { EmployeeContext } from "../../../contexts/EmployeeContext";
import { AuthContext } from "../../../contexts/AuthContext";
import Dropdown from "../../../components/Dropdown/index";
import {
  getApprovalHistory,
  getCompanyBands,
  getPaymentHistory,
  getPayrollHistoryByMonth,
  getPayrollHistoryInDetail,
} from "../../../services/api";
import { AdminContext } from "../../../contexts/AdminContext";
import { payrollHistoryState } from "../../../constants/constants";
import VecationDropdown from "../../../components/VecationDropdown/index";
import {
  AnnualLeaveSVG,
  SickLeaveSVG,
  EmergencyLeaveSVG,
  GeneralEmployeeSVG,
  Mid_ManagementSVG,
  ManagementSVG,
} from "../../../constants/payrollmanagement/payroll_management";
const PayrollVacation = () => {
  const [statusOptions, setStatusOptions] = useState([
    {
      index: 0,
      label: "Select vacation",
    },
    {
      index: 1,
      label: "Annual Leave",
      img: AnnualLeaveSVG,
    },
    {
      index: 2,
      label: "Sick Leave",
      img: SickLeaveSVG,
    },
    {
      index: 3,
      label: "Emergency Leave",
      img: EmergencyLeaveSVG,
    },
  ]);
  const { user } = useContext(AuthContext);

  const [selectedStatusOption, setSelectedStatusOption] = useState(0);
  const [selectedStatusBandOption, setSelectedStatusBandOption] = useState(0);
  const [statusBandOptions, setStatusBandOptions] = useState([
    {
      index: 0,
      label: "Select Band",
    },
  ]);
  const bandImages = {
    "General Employee": GeneralEmployeeSVG,
    "Mid-Management": Mid_ManagementSVG,
    Management: ManagementSVG,
  };
  const fetchUpcomingEvent = async (user) => {
    if (!user || !user.companyName) {
      console.log("User or companyName is undefined");
      return;
    }
    try {
      const res = await getCompanyBands(user.companyName);

      if (res.band && res.band.length > 0) {
        const updatedRoles = res.band.map((band, index) => ({
          label: band.name,
          index: index + 1,
          img: getImageForBand(band.name), // Use the helper function to assign images
        }));
        setStatusBandOptions(updatedRoles);
      } else {
        console.log("No bands found or bad data structure:", res);
      }
    } catch (error) {
      console.error("Failed to fetch bands:", error);
    }
  };
  const getImageForBand = (bandName) => {
    return bandImages[bandName] || GeneralEmployeeSVG; // Fallback to GeneralEmployeeSVG if no match
  };
  useEffect(() => {
    fetchUpcomingEvent(user);
  }, [user]);
  return (
    <>
      <div className="w-full">
        <div className="text-[20px] font-inter-bold text-[#011165]">
          Payroll Management
        </div>
        <div className="mt-[12px] border-b-[1px] border-[#0000001A]" />
        <div className="flex gap-[30px] items-center cursor-pointer pt-[25px]">
          <div className="text-base font-inter-bold text-[#404040]">
            Set up vacation
          </div>
        </div>
        <VecationDropdown
          options_leave={statusOptions}
          selectedOption={selectedStatusOption}
          setSelectedOption={setSelectedStatusOption}
          options_band={statusBandOptions}
          selectedBandOption={selectedStatusBandOption}
          setSelectedBandOption={setSelectedStatusBandOption}
          selectClassName="w-[400px] h-[50px] text-[15px] font-[500] mt-[33px] pl-[28px] font-inter-regular bg-[#EBEBEB]"
        />
      </div>
    </>
  );
};
export default PayrollVacation;
