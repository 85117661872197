import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminContext } from "../../../contexts/AdminContext";
import Search from "../../../assets/images/dashboard/svg/search.svg";
import Terminate from "../../../assets/images/dashboard/svg/terminate.svg";
import Dropdown from "../../../components/Dropdown/index";
import { AuthContext } from "../../../contexts/AuthContext";
import { createPayrollActivity, getEmployeeData } from "../../../services/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  employeeRecordState,
  initiatePayrollState,
} from "../../../constants/constants";
import InitiatePayroll from "./InitiatePayroll";
import AddEmployeePayroll from "./AddEmployeePayroll";
import AddEmployeePayrollModal from "./AddEmployeePayrollModal";
import Modal from "../../../components/Modal/index";
import { MarkSVG } from "../../../constants/payrollmanagement/payroll_management";

const dummyEmployee = [
  {
    firstName: "Peter",
    middleName: "Oscar",
    lastName: "Pan",
    email: "peterpan@gmail.com",
    phoneNumber: "+1 2328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "HR",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "23-03-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Peter Pan",
    bankAccountNumber: 3940392019,
    isBankAccountValidated: true,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000002,
    status: "active",
  },
  {
    firstName: "Simon",
    middleName: "Oscar",
    lastName: "Pegg",
    email: "simonpegg@gmail.com",
    phoneNumber: "+1 4328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "ICT",
    position: "UX designer",
    lineManager: "Marvin Ralph",
    hireDate: "14-05-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Simon Pegg",
    bankAccountNumber: 4920392019,
    isBankAccountValidated: true,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "active",
  },
  {
    firstName: "Susan",
    middleName: "",
    lastName: "Megg",
    email: "susanmegg@gmail.com",
    phoneNumber: "+1 2328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "HR",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "05-09-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Susan Megg",
    bankAccountNumber: 7940392013,
    isBankAccountValidated: true,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "active",
  },
  {
    firstName: "Philip",
    middleName: "Mae",
    lastName: "Olaitan",
    email: "philip@gmail.com",
    phoneNumber: "+1 9328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "Finance",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "30-07-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Peter Pan",
    bankAccountNumber: 3940392019,
    isBankAccountValidated: false,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "leave",
  },
  {
    firstName: "Sade",
    middleName: "Mae",
    lastName: "Akinlade",
    email: "akinalade@gmail.com",
    phoneNumber: "+1 2328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "HR",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "23-03-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Peter Pan",
    bankAccountNumber: 2300392019,
    isBankAccountValidated: false,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "terminated",
  },
  {
    firstName: "Sade",
    middleName: "Mae",
    lastName: "Akinlade",
    email: "akinalade@gmail.com",
    phoneNumber: "+1 2328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "HR",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "23-03-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Peter Pan",
    bankAccountNumber: 2300392019,
    isBankAccountValidated: false,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "leave",
  },
];
const InitiatePayrollPage = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { employeeData, setEmployeeData } = useContext(AdminContext);
  const [payableEmployee, setPayableEmployee] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [payableMonth, setPayableMonth] = useState(1);
  const [pageState, setPageState] = useState(
    initiatePayrollState.initiatePayroll
  );
  const [searchName, setSearchName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);

  const [lineManagerOptions, setLineManagerOptions] = useState([
    {
      index: 0,
      label: "Select line manager",
    },
    {
      index: 1,
      label: "Peter",
    },
    {
      index: 2,
      label: "Susan",
    },
  ]);
  const [selectedLineManagerOption, setSelectedLineManagerOption] = useState(0);
  const [statusOptions, setStatusOptions] = useState([
    {
      index: 0,
      label: "All status",
    },
    {
      index: 1,
      label: "Active",
    },
    {
      index: 2,
      label: "Leave",
    },
    {
      index: 3,
      label: "Terminated",
    },
  ]);
  const [selectedStatusOption, setSelectedStatusOption] = useState(0);
  const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(0);
  // useEffect(() => {
  //   setEmployeeData(dummyEmployee);
  // }, []);
  useEffect(() => {
    const fetchEmployeeData = async (companyName) => {
      const res = await getEmployeeData(companyName);
      console.log(res);
      if (res?.length > 0) {
        setEmployeeData(res);
      }
    };
    if (Object.keys(user).length > 0 && !employeeData) {
      fetchEmployeeData(user.companyName);
    }
  }, [user]);

  useEffect(() => {
    if (employeeData?.length > 0) {
      let availableEmployee = employeeData.filter(
        (e) => e.status !== "terminated"
      );
      let newArray = availableEmployee.map((employee) => {
        let newObj = {};
        Object.assign(newObj, employee, {
          totalPayableAmount: employee.grossSalary * 1,
        });
        return newObj;
      });
      setPayableEmployee(newArray);
    }
  }, [employeeData, refresh]);

  const handleSubmitPayroll = async () => {
    if (payableEmployee?.length === 0) {
      toast.info("No employees selected for payroll.");
      return;
    }
    let payData = [];
    payableEmployee.forEach((e) => {
      payData.push({
        userId: e.userId,
        totalPayAmount: e.totalPayableAmount,
      });
    });
    let payload = {
      payData,
      initiatorId: user.userId,
      initiatorName: `${user.firstName} ${user.lastName}`,
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      companyName: user.companyName,
    };
    try {
      const res = await createPayrollActivity(payload);

      if (res) {
        toast.success(
          res.message || "Payroll submitted for approval successfully!"
        );
        //setShowApproveModal(true); // Showing modal if needed
      } else {
        toast.error("Submission failed. Please try again.");
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };
  return (
    <div className="w-full">
      <ToastContainer />
      <div className="text-[20px] font-inter-bold text-[#011165]">
        Payroll Management
      </div>
      <div className="mt-[12px] border-b-[1px] border-[#0000001A]" />
      <div className="w-full flex justify-between items-center py-[25px]">
        <div className="font-inter-bold text-base text-[#404040]">
          Initiate Payroll
        </div>
        <div className="flex gap-[10px]">
          {pageState === initiatePayrollState.addEmployeePayroll && (
            <button
              className="rounded-md font-inter-bold text-[10px] text-[#011165] p-[10px]"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <span className="text-[14px]">+</span> ADD EMPLOYEE
            </button>
          )}
          <button
            className="flex items-center rounded-md font-inter-bold text-[10px] text-white p-[10px] px-[15px] bg-[#011165]"
            onClick={handleSubmitPayroll}
          >
            SUBMIT FOR APPROVAL
          </button>
        </div>
      </div>
      {pageState === initiatePayrollState.initiatePayroll &&
        payableEmployee && (
          <InitiatePayroll
            employeeData={payableEmployee}
            setPayableEmployee={setPayableEmployee}
            setPayableMonth={setPayableMonth}
            payableMonth={payableMonth}
            selectedToDate={selectedToDate}
            setSelectedToDate={setSelectedToDate}
            selectedFromDate={selectedFromDate}
            setSelectedFromDate={setSelectedFromDate}
            setPageState={setPageState}
            setRefresh={setRefresh}
          />
        )}
      {pageState === initiatePayrollState.addEmployeePayroll && (
        <AddEmployeePayroll
          employeeData={payableEmployee}
          setPayableEmployee={setPayableEmployee}
          setPageState={setPageState}
        />
      )}
      {showModal && (
        <AddEmployeePayrollModal
          employeeData={employeeData}
          onClose={() => setShowModal(false)}
          showModal={showModal}
          setPayableEmployee={setPayableEmployee}
        />
      )}
      <Modal
        isOpen={showApproveModal}
        onClose={() => setShowApproveModal(false)}
        title="Modal Header"
      >
        <div className="w-[452px] h-[534px] rounded-lg flex flex-col items-center justify-center gap-[16px]">
          <img src={MarkSVG} alt="Success" />
          <p className=" font-inter-bold font-[600] text-[24px] text-payroll_primary">
            Successful
          </p>
          <p className=" font-inter-regular font-[400] text-[10px] text-payroll_primary">
            successfully sent submit for approval.
          </p>
        </div>
      </Modal>
    </div>
  );
};
export default InitiatePayrollPage;
