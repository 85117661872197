import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminContext } from "../../../contexts/AdminContext";
import MainNotification from "../../../assets/images/dashboard/svg/email-big.svg";
import Terminate from "../../../assets/images/dashboard/svg/terminate.svg";
import Dropdown from "../../../components/Dropdown/index";
import { AuthContext } from "../../../contexts/AuthContext";
import { getEmployeeData } from "../../../services/api";
import { approvePayrollState, employeeRecordState, initiatePayrollState } from "../../../constants/constants";

const Initiator = (props) => {
  const { setPageState, payrollDate } = props;
  const navigate = useNavigate();
  
  return (
    <div className="w-5/6 border-[1px] border-[#EFF0F6] rounded-xl min-h-[400px]">
      <div className="rounded-t-xl bg-[#011165] h-[10px]" />
      <div className="flex flex-col gap-[40px] justify-center items-center m-auto">
        <img src={MainNotification} className="w-[64px] pt-[60px]" alt='mail' />
        <div 
          className="font-inter-medium text-[16px] text-[#000000B2] underline underline-offset-2 cursor-pointer"
          onClick={() => setPageState(approvePayrollState.approvePayrollDetail)}
        >
          Payroll for &nbsp;
          <span className="font-inter-bold text-[#011165]">{payrollDate}</span>
          &nbsp; is pending for approval
        </div>
      </div>
    </div>
  );
};
export default Initiator;
