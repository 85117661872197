import { useNavigate } from "react-router-dom";
import {
  PAYROLL_HEADER_TITLE,
  PAYROLL_HEADER_TABS,
  PAYROLL_LOGIN,
  PAYROLL_SIGNUP,
} from "../../constants/landingpage_payroll/landingpage_payroll";
const Topheader = () => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between items-center pt-[30px] px-[40px] lg:px-[58px] lg2:px-[75px] xl:px-[98px]">
      <h1 className=" font-eina02-bold font-[400] text-[32px] line-[44px]  text-[#011165]">
        {PAYROLL_HEADER_TITLE}
      </h1>
      <ul className="flex gap-[50px] lg2:gap-[51px] xl:gap-[60px] cursor-pointer  text-[#011165] ">
        {PAYROLL_HEADER_TABS.map((tab) => (
          <li className="font-eina02-semibold" key={tab.label}>{tab.label}</li>
        ))}
      </ul>
      <div className="flex items-center gap-[41px]">
        <p 
          className="font-eina02-semibold font-[400] text-[16px] line-[22px] text-[#011165] cursor-pointer"
          onClick={() => navigate('/login')}
        >
          {PAYROLL_LOGIN}
        </p>
        <div className="w-[100px] h-[42px] rounded-[100px] bg-[#FF5800] cursor-pointer">
          {" "}
          <p 
            className="font-eina02-semibold font-[400] text-[16px] line-[22px] text-[#ffffff] pt-[10px] text-center"
            onClick={() => navigate('/signup')}
          >
            {PAYROLL_SIGNUP}
          </p>
        </div>
      </div>
    </div>
  );
};
export default Topheader;
