const AboutUsCard = ({ img, description, name, attribute }) => {
  return (
    <div className="flex justify-between w-[450px] h-[200px] rounded-[30px] bg-white px-[32px]">
      <div className="pt-[30px]">
        <img src={img} alt="Cofounder" />
      </div>
      <div className="w-[267px] pt-[33px]">
        <p className=" font-eina02-regular font-[400] text-[14px] text-payroll_primary opacity-[45%]">
          {description}
        </p>
        <p className=" font-eina02-semibold font-[400] text-[16px] text-payroll_primary pt-[33px]">
          {name}
        </p>
        <p className=" font-eina02-regular font-[400] text-[16px] text-payroll_primary opacity-[35%]">
          {attribute}
        </p>
      </div>
    </div>
  );
};
export default AboutUsCard;
