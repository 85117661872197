import { Fragment, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, useSortBy, useRowSelect, usePagination  } from 'react-table';
import SortIcon from "../../../assets/images/dashboard/svg/sort-icon.svg";
import Employee from "../../../assets/images/dashboard/svg/employee-sample.svg";
import Edit from "../../../assets/images/dashboard/svg/edit.svg";
import Remove from "../../../assets/images/dashboard/svg/remove.svg";
import Pagination from "../../../components/Pagination/index";


const VacationRequestTable = (props) => {
  const { vacationRequests, handleApprove, handleDecline, setSelectedRows } = props;
  const [editableRows, setEditableRows] = useState(new Set()); // State to track editable rows
  const isRowEditable = rowIndex => editableRows.has(rowIndex);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null); // State to track selected row for the more options popup
  const selectedRowsRef = useRef(null);

  const data = vacationRequests ?? [];
  const COLUMNS = [
    {
      id: 'selection',
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <div className="">
          <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
        </div>
      ),
      Cell: ({ row }) => <input type="checkbox" className="" {...row.getToggleRowSelectedProps()} />,
    },
    { Header: 'Name', accessor: row => `${row.firstName} ${row.lastName}`},
    { Header: 'Salary', accessor: 'email' },
    { Header: 'Department', accessor: 'department' },
    { Header: 'Date', accessor: 'vacationDate' },
    { Header: 'Duration', accessor: 'vacationDuration' },
    { Header: 'Action', accessor: 'action' },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const { 
    getTableProps, 
    getTableBodyProps, 
    headerGroups, 
    page,
    // rows, 
    prepareRow, 
    state: {pageIndex, pageSize},
    selectedFlatRows,
    pageOptions, 
    pageCount,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 5 }, // Initial pagination state
    }, 
    useSortBy,
    usePagination,
    useRowSelect,
  );

  useEffect(() => {
    setSelectedRows(selectedFlatRows?.map(row => row.original));
  }, [selectedFlatRows, setSelectedRows]);

  useEffect(() => {
    console.log(pageIndex);
  }, [pageIndex])

  const toggleEditable = rowIndex => {
    setEditableRows(prevState => {
      const newSet = new Set(prevState);
      if (newSet.has(rowIndex)) {
        newSet.delete(rowIndex);
      } else {
        newSet.add(rowIndex);
      }
      return newSet;
    });
  };
  const handleMoreOptionsClick = (event, rowIndex) => {
    console.log(event, rowIndex);
    event.stopPropagation(); // Prevent table row click event from triggering
    setSelectedRowIndex(rowIndex === selectedRowsRef.current ? null : rowIndex); // Toggle selected row
  };
  useEffect(() => {
    selectedRowsRef.current = selectedRowIndex
  }, [selectedRowIndex]);
  
  return (
    <div className="w-full">
      <div className="flex ml-[-10px] gap-[20px]">
        <table
          {...getTableProps()}
          className="w-full bg-white rounded-xl"
        >
          <thead className="bg-[#EDEDED]">
            {headerGroups.map(headerGroup => (
              <tr 
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map(column => (
                  <th 
                    scope="col" 
                    className={`px-8 py-6 
                      ${column.id === 'selection' ? 'rounded-tl-xl' : ''}
                      ${column.id === 'action' ? 'rounded-tr-xl' : ''}
                    `}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div className="flex items-center gap-[5px] text-left font-inter-semibold text-sm text-[#000000B2]">
                      {column.render('Header')}
                      {column?.id !== 'selection' && column?.id !== 'action' && <img src={SortIcon} className="w-[8px]" />}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody 
            className="divide-y divide-[#7b78781a]" 
            {...getTableBodyProps()}
          >
            {page.map((row, i) => {
              prepareRow(row);
              const showMoreOptions = selectedRowIndex === i; // Check if more options popup should be displayed for this row
              return (
                <Fragment key={row.id}>
                  <tr className="relative" {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td 
                        className={
                          `
                            px-8 py-10 text-[#00000080] font-inter-medium text-sm
                            ${cell.column.Header === 'Name' ? 'flex items-center gap-[6px]' : ''}
                          `
                        }
                        {...cell.getCellProps()}
                      >
                        {cell.column.Header === 'Name' && 
                         <img 
                              src={Employee}
                              className="w-[29px]"
                              alt="employee"
                          />
                        }
                        {
                          cell.column.Header === 'Action' ? (
                            <div className="flex gap-[10px] items-center">
                              <button 
                                className="px-[8px] py-[5px] font-inter-semibold text-[#011165] rounded-2xl bg-[#D9DBE8]"
                                onClick={() => {handleApprove(i)}}
                              >
                                Approve
                              </button>
                              <button 
                                className="px-[8px] py-[5px] font-inter-semibold text-[#011165] rounded-2xl border-[1px] border-[#011165]"
                                onClick={() => {handleDecline(i)}}
                              >
                                Deny
                              </button>
                            </div>
                          ) : (
                            cell.column.id === 'selection' ? (
                              cell.render('Cell')
                            ) : (
                              isRowEditable(i) ? (
                                <input
                                  type="text"
                                  value={cell.value}
                                  onChange={e => {
                                    // You'll need to update your data here
                                    data[row.index][cell.column.id] = e.target.value;
                                  }}
                                  className="w-full"
                                />
                              ) : (
                                cell.render('Cell')
                              )
                            )
                          )
                        }
                      </td>
                    ))}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        pageIndex={pageIndex}
        pageCount={pageCount}
        pageOptions={pageOptions}
        pageSize={pageSize}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
      />
    </div>
  );
};
export default VacationRequestTable;
