import { PAYROLL_APPROVE_CARD_DATAS } from "../../constants/landingpage_payroll/landingpage_payroll";

const ApprovePayrollCard = () => {
  return (
    <div className="absolute flex flex-col px-[25px] py-[20px] w-[200px] lg:w-[300px] lg2:w-[320px] xl:w-[346px] rounded-[30px] bg-white left-0 bottom-[-100px] shadow-lg shadow-light z-20">
      
      <div className="flex justify-between">
        <p className="font-eina02-bold font-[400] text-[20px] text-payroll_primary">
          {PAYROLL_APPROVE_CARD_DATAS.title}
        </p>
        <p className="font-eina02-bold font-[400] text-[20px]  text-payroll_primary opacity-[35%]">
          {PAYROLL_APPROVE_CARD_DATAS.date}
        </p>
      </div>
      <div className=" bg-payroll_primary opacity-[15%] border-b-[1px] my-[20px] mx-[-20px]"></div>
      <div className="flex justify-between">
        <p className="font-eina02-regular font-[400] text-[10px] line-[50px] text-left text-payroll_primary ">
          {PAYROLL_APPROVE_CARD_DATAS.total_title}
        </p>
        <p className="font-eina02-regular font-[400] text-[10px] line-[50px] text-left text-payroll_primary pr-[34px]">
          {PAYROLL_APPROVE_CARD_DATAS.tax_title}
        </p>
      </div>
      <div className="flex justify-between pt-[5px]">
        <p className="font-eina02-bold font-[400] text-[15px] text-left text-payroll_primary">
          {PAYROLL_APPROVE_CARD_DATAS.total_amount}
        </p>
        <p className="font-eina02-bold font-[400] text-[15px] text-left text-payroll_primary">
          {PAYROLL_APPROVE_CARD_DATAS.tax_amount}
        </p>
      </div>
      <div className="flex items-center justify-between pt-[20px]">
        <div className="w-[115px] h-[52px] bg-payroll_primary gap-[10px] rounded-[50px] cursor-pointer">
          <p className="font-eina02-bold font-[400] text-white text-[13px] text-center py-[18px]">
            {PAYROLL_APPROVE_CARD_DATAS.btn}
          </p>
        </div>
        <p className="font-eina02-regular font-[400] text-[14px] underline cursor-pointer">
          {PAYROLL_APPROVE_CARD_DATAS.check_details}
        </p>
      </div>
    </div>
  );
};
export default ApprovePayrollCard;
