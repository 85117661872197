import DemoSVG from "../../assets/images/landingpage_payroll/svg/demo.svg";
import SubtractSVG from "../../assets/images/landingpage_payroll/svg/subtract.svg";
import WomanPNG from "../../assets/images/landingpage_payroll/png/woman.png";

import AutoSVG from "../../assets/images/landingpage_payroll/svg/automated.svg";
import AccurateSVG from "../../assets/images/landingpage_payroll/svg/accurate.svg";
import TaxSVG from "../../assets/images/landingpage_payroll/svg/tax.svg";

import Save_WomanPNG from "../../assets/images/landingpage_payroll/png/save_woman_group.png";
import Save_MenPNG from "../../assets/images/landingpage_payroll/png/save_men.png";
import WaveSVG from "../../assets/images/landingpage_payroll/svg/wave.svg";

import Step1PNG from "../../assets/images/landingpage_payroll/png/step1.png";
import Step2PNG from "../../assets/images/landingpage_payroll/png/step2.png";
import Step3PNG from "../../assets/images/landingpage_payroll/png/step3.png";
import QuoterSVG from "../../assets/images/landingpage_payroll/svg/quoter.svg";
import Step_BackgroundPNG from "../../assets/images/landingpage_payroll/png/step_background.png";
import AppStoreSVG from "../../assets/images/landingpage_prosphere/svg/applestore.svg";
import PleayStoreSVG from "../../assets/images/landingpage_prosphere/svg/playstore.svg";
import Cofounder1PNG from "../../assets/images/landingpage_payroll/png/Cofounder1.png";
import Cofounder2PNG from "../../assets/images/landingpage_payroll/png/Cofounder2.png";
import Cofounder3PNG from "../../assets/images/landingpage_payroll/png/Cofounder3.png";
import Effective1SVG from "../../assets/images/landingpage_payroll/svg/effective1.svg";
import Effective2SVG from "../../assets/images/landingpage_payroll/svg/effective2.svg";

import Focuse_StarSVG from "../../assets/images/landingpage_payroll/svg/star.svg";

import { TITLE } from "../landingpage_prosphere/landingpage_prosphere";

import CSVG from "../../assets/images/landingpage_common/c.svg";
import PhoneSVG from "../../assets/images/landingpage_common/phone.svg";
import TwitterSVG from "../../assets/images/landingpage_common/twitter.svg";
import FacebookSVG from "../../assets/images/landingpage_common/facebook.svg";
import LinkedinSVG from "../../assets/images/landingpage_common/linkedin.svg";
export const PAYROLL_HEADER_TITLE = "Payroll";
export const PAYROLL_HEADER_TABS = [
  {
    label: "Home",
    url: "#",
  },
  {
    label: "About Us",
    url: "#",
  },
  {
    label: "Pricing",
    url: "#",
  },
  {
    label: "FAQs",
    url: "#",
  },
];
export const PAYROLL_LOGIN = "Login";
export const PAYROLL_SIGNUP = "Sign Up";

export const PAYROLL_LIFE = {
  title: "Streamline Your Payroll, Simplify Your Life",
  description:
    "Effortless payroll that saves you time and money. Our automated system works for businesses of all sizes.",
  btn: "Get Started",
  demoImg: DemoSVG,
  demo: "Viwe Demo",
};
export const PAYROLL_SUBTRACT_IMG = SubtractSVG;
export const PAYROLL_WOMAN_IMG = WomanPNG;
export const PAYROLL_APPROVE_CARD_DATAS = {
  title: "Next Payroll",
  date: "May 27",
  total_title: "Estimated total",
  tax_title: "Tax burndens",
  total_amount: "NGN 50,000.00",
  tax_amount: "NGN 7,000.00",
  btn: "Approve payroll",
  check_details: "Check details",
};

export const PAYROLL_PROCESS_DATA = {
  title: `Let’s help you simplify
  your payroll process`,
  btn: `Get In touch`,
};
export const PAYROLL_PROCESS_CARDS = [
  {
    imgSrc: AutoSVG, // Update the path as needed
    title: "Automated payroll processing",
    description: "Saves time and minimizes errors",
  },
  {
    imgSrc: AccurateSVG,
    title: "Accurate and timely payments",
    description:
      "Ensures employees receive their compensation correctly and on time ",
  },
  {
    imgSrc: TaxSVG,
    title: "Tax and compliance management",
    description:
      "Handles complex tax calculations and ensures adherence to regulations",
  },
];

export const PAYROLL_PROCESS_AUTO = {
  imgSrc: AutoSVG, // Update the path as needed
  title: "Automated payroll processing",
  description: "Saves time and minimizes errors",
};

export const PAYROLL_PROCESS_ACCURATE = {
  imgSrc: AccurateSVG,
  title: "Accurate and timely payments",
  description:
    "Ensures employees receive their compensation correctly and on time ",
};
export const PAYROLL_PROCESS_TAX = {
  imgSrc: TaxSVG,
  title: "Tax and compliance management",
  description:
    "Handles complex tax calculations and ensures adherence to regulations",
};

export const PAYROLL_SAVE_IMG = {
  womanimg: Save_WomanPNG,

  wave: WaveSVG,
};

export const PAYROLL_SAVE_DATAS = {
  title: "Save time and money with automated payroll",
  description:
    "Experience seamless payroll service with us. Choose from a range of features and enjoy efficient payroll management.",
  learnmore: "Learn more",
};
export const PAYROLL_MEN_CARDS = {
  menimg: Save_MenPNG,
  salary: "Salary",
  date: "April, 2024",
  total_title: "Total pay",
  total_amount: "NGN 240,000.00",
  bonus_title: "Bonus pay",
  bonus_amount: "NGN 25,000.00",
};

export const PAYROLL_SIMPLIFY_TITLE = {
  title: "How we simplify payroll",
};
export const PAYROLL_SIMPLIFY_BACKGROUND = Step_BackgroundPNG;
export const PAYROLL_SIMPLIFY_STEP1 = {
  title: "Step 1",
  description:
    "Effortless payroll that saves you time and money. Our automated system works for businesses of all sizes.",
  img: Step1PNG,
};

export const PAYROLL_SIMPLIFY_STEP2 = {
  title: "Step 2",
  description:
    "Effortless payroll that saves you time and money. Our automated system works for businesses of all sizes.",
  img: Step2PNG,
};

export const PAYROLL_SIMPLIFY_STEP3 = {
  title: "Step 3",
  description:
    "Effortless payroll that saves you time and money. Our automated system works for businesses of all sizes.",
  img: Step3PNG,
};

export const PAYROLL_ABOUTUS_TITLE = {
  img: QuoterSVG,
  title: "What they say about us",
};

export const PAYROLL_COFOUNER1_DATAS = {
  img: Cofounder1PNG,
  description:
    "Experience seamless payroll service with us. Choose from a range of features and enjoy efficient payroll management.",
  name: "Abel M",
  attribute: "Cofounder Airbus",
};

export const PAYROLL_COFOUNER2_DATAS = {
  img: Cofounder2PNG,
  description:
    "Experience seamless payroll service with us. Choose from a range of features and enjoy efficient payroll management.",
  name: "Abel M",
  attribute: "Cofounder Airbus",
};

export const PAYROLL_COFOUNER3_DATAS = {
  img: Cofounder3PNG,
  description:
    "Experience seamless payroll service with us. Choose from a range of features and enjoy efficient payroll management.",
  name: "Abel M",
  attribute: "Cofounder Airbus",
};

export const PAYROLL_EFFECTIVE_SVG = {
  effective1: Effective1SVG,
  effective2: Effective2SVG,
};

export const PAYROLL_FOCUSE_DATAS = {
  svg: Focuse_StarSVG,
  title: "Focus on what matters We’ll handle the rest",
  btn: "Get a Free Quote",
};

export const PAYROLL_FOOTER_COMPANY = {
  title: "Our Company",
  aboutus: "About Us",
  faqs: "FAQs",
};
export const PAYROLL_FOOTER_PAYROLL = {
  title: "PAYROLL",
  street: "5 Bathrust Street Wuse 2  Abuja, FCT",
};
export const PAYROLL_FOOTER_PRODUCTS = [
  {
    label: "Pulse Check",
    url: "#",
  },
  {
    label: "Payroll",
    url: "#",
  },
  {
    label: "Survey",
    url: "#",
  },
  {
    label: "Leave Scheduler",
    url: "#",
  },
  {
    label: "Enagagement Survey",
    url: "#",
  },
  {
    label: "Employee Net Promoter Score",
    url: "#",
  },
];

export const PAYROLL_FOOTER_CONTACTUS = {
  title: "Contact Us",
  number: "0903 669 1857",
};
export const PAYROLL_FOOTER_DOWNLOADAPP = {
  title: "Download App",
  appstoresvg: AppStoreSVG,
  playstoresvg: PleayStoreSVG,
};
export const PAYROLL_FOOTER_COPYRIGHT = {
  title: "2024 PROSphere. All rights reserved",
  csvg: CSVG,
  phonesvg: PhoneSVG,
  twittersvg: TwitterSVG,
  linkedinsvg: LinkedinSVG,
  facebooksvg: FacebookSVG,
};
export const PAYROLL_FOOTER_PRODUCTS_TITLE = "Other products";
