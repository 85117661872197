import { useContext, useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, useRowSelect } from 'react-table';

import TotalAmount from "../../../assets/images/dashboard/svg/total-amount.svg";
import RightArrow from "../../../assets/images/dashboard/svg/right-arrow.svg";
import SortIcon from "../../../assets/images/dashboard/svg/sort-icon.svg";
import Employee from "../../../assets/images/dashboard/svg/employee-sample.svg";
import { getMonthFromDate } from "../../../utils/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { approverManagementState, dashboardState, userRole } from "../../../constants/constants";
import { register, updateCompanyData } from "../../../services/api";
 
const AddApprover = (props) => {
  const { approverData, setPageState, user, setTriggerFetch } = props;
  const [formData, setFormData] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log(formData)
    let approver = approverData ?? [];
    if (approverData && approverData?.length > 0 && approverData.some(e => e.approverLevel === parseInt(formData.approverLevel))) {
      alert("Duplicated approver level. Please select another level.");
      return;
    }
    // first register this buy as admin
    const registerRes = await register({
      email: formData.approverEmail,
      companyName: user.companyName,
      position: formData.approverPosition,
      firstName: formData.approverName.split(" ")[0],
      lastName: formData.approverName.split(" ")[1] ?? "",
      password: '111111',
      role: userRole.admin
    })
    console.log(registerRes);
    if (registerRes.status === 'success') {
      approver.push({
        approverId: registerRes.data.user.userId,
        approverEmail: formData.approverEmail,
        approverName: formData.approverName,
        approverPosition: formData.approverPosition,
        approverLevel: formData.approverLevel,
      })
      const res = await updateCompanyData({
        companyName: user.companyName,
        approver: approver
      });
      if (res) {
        setTriggerFetch(prev => !prev);
        setPageState(approverManagementState.approverList);
      }
    }
  }
  const handleSaveAndAnother = async () => {
    console.log(formData)
    let approver = approverData ?? [];
    if (approverData && approverData?.length > 0 && approverData.some(e => e.approverLevel === parseInt(formData.approverLevel))) {
      alert("Duplicated approver level. Please select another level.");
      return;
    }
    // first register this buy as admin
    const registerRes = await register({
      email: formData.approverEmail,
      companyName: user.companyName,
      position: formData.approverPosition,
      firstName: formData.approverName.split(" ")[0],
      lastName: formData.approverName.split(" ")[1] ?? "",
      password: '111111',
      role: userRole.admin
    })
    console.log(registerRes);
    if (registerRes.status === 'success') {
      approver.push({
        approverId: registerRes.data.user.userId,
        approverName: formData.approverName,
        approverEmail: formData.approverEmail,
        approverPosition: formData.approverPosition,
        approverLevel: formData.approverLevel,
      })
      const res = await updateCompanyData({
        companyName: user.companyName,
        approver: approver
      });
      console.log(res)
      if (res) {
        setTriggerFetch(prev => !prev);
      }
      setFormData({
        approverName: '',
        approverPosition: '',
        approverEmail: '',
        approverLevel: 0,
      });
    }
  }

  return (
    <div className="w-full">
      <div className="flex flex-col gap-[20px]">
        <form onSubmit={handleSubmit} >
          <div className="w-full flex justify-between items-center py-[25px]">
            <div className="font-inter-bold text-base text-[#404040]">
              Set up Approver
            </div>
            <div className="flex gap-[10px]">
              <button 
                className="rounded-md font-inter-bold text-[10px] text-[#011165] p-[10px] border-[1px] border-[#011165]"
                onClick={() => {
                  setPageState(approverManagementState.approverList)
                }}
                type="button"
              >
                CANCEL
              </button>
              <button 
                className="rounded-md font-inter-bold text-[10px] text-white p-[10px] bg-[#011165]"
                type="submit"
              >
                SAVE
              </button>
              <button 
                className="flex items-center rounded-md font-inter-bold text-[10px] text-white p-[10px] px-[15px] bg-[#011165]"
                onClick={handleSaveAndAnother}
                type="button"
              >
                SAVE AND ADD ANOTHER
              </button>
            </div>
          </div>
          <div className="flex flex-col w-1/2 max-w-[500px] py-[30px] px-[30px] bg-white rounded-2xl">
            <div className="w-full flex flex-col gap-[30px]">
              <div className="w-full flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                <div className="font-inter-bold text-[#000000B2]">Name</div>
                <input
                  type="text"
                  name="approverName"
                  className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                  value={formData.approverName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="w-full flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                <div className="font-inter-bold text-[#000000B2]">Email Address</div>
                <input
                  type="text"
                  name="approverEmail"
                  className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                  value={formData.approverEmail}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="w-full flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                <div className="font-inter-bold text-[#000000B2]">Designation</div>
                <input
                  type="text"
                  name="approverPosition"
                  className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                  value={formData.approverPosition}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="w-full flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                <div className="font-inter-bold text-[#000000B2]">Approval Level</div>
                <input
                  type="number"
                  name="approverLevel"
                  className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                  value={formData.approverLevel}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddApprover;
