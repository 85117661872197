import Dropdown from "../../assets/images/landingpage_payroll/svg/dropdown.svg";
import AnnualLeave from "../../assets/images/payroll_management/svg/annualleave.svg";
import SickLeave from "../../assets/images/payroll_management/svg/sickleave.svg";
import EmergencyLeave from "../../assets/images/payroll_management/svg/emergencyleave.svg";

import GeneralEmployee from "../../assets/images/payroll_management/svg/generalemployee.svg";
import Mid_Management from "../../assets/images/payroll_management/svg/mid_management.svg";
import Management from "../../assets/images/payroll_management/svg/management.svg";
import Mark from "../../assets/images/payroll_management/svg/mark.svg";
export const DropdownSVG = Dropdown;
export const AnnualLeaveSVG = AnnualLeave;
export const SickLeaveSVG = SickLeave;
export const EmergencyLeaveSVG = EmergencyLeave;

export const GeneralEmployeeSVG = GeneralEmployee;
export const Mid_ManagementSVG = Mid_Management;
export const ManagementSVG = Management;
export const MarkSVG = Mark;
