import { Fragment, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, useSortBy, useRowSelect, usePagination  } from 'react-table';
import ThreeDots from "../../../assets/images/dashboard/svg/three-dots.svg";
import SortIcon from "../../../assets/images/dashboard/svg/sort-icon.svg";
import Edit from "../../../assets/images/dashboard/svg/edit.svg";
import Remove from "../../../assets/images/dashboard/svg/remove.svg";
import Pagination from "../../../components/Pagination/index";

const dummyEmployee = [
  {
    firstName: 'Peter',
    middleName: 'Oscar',
    lastName: 'Pan',
    email: 'peterpan@gmail.com',
    phoneNumber: '+1 2328284903',
    role: 'employee',
    companyName: 'Leo Ltd',
    department: 'HR',
    position: 'HR officer',
    lineManager: 'Marvin Ralph',
    hireDate: '23-03-2023',
    grossSalary: '300000',
    bankName: 'GTB',
    bankAccountName: 'Peter Pan',
    bankAccountNumber: 3940392019,
    isBankAccountValidated: true,
    leftVacationInfo: [

    ],
    address: '6295 Lake Worth Rd #19',
    city: 'Los Angeles',
    state: 'California',
    kinFirstName: 'Wilter',
    kinLastName: 'Pan',
    kinPhoneNumber: '+1 3039403923',
    kinAddress: '6295 Lake Worth Rd #19',
    emergencyFirstName: 'Wilter',
    emergencyLastName: 'Pan',
    emergencyPhoneNumber: '+1 3039403923',
    emergencyAddress: '6295 Lake Worth Rd #19',
    userId: 10000002,
    status: 'active',
  },
  {
    firstName: 'Simon',
    middleName: 'Oscar',
    lastName: 'Pegg',
    email: 'simonpegg@gmail.com',
    phoneNumber: '+1 4328284903',
    role: 'employee',
    companyName: 'Leo Ltd',
    department: 'ICT',
    position: 'UX designer',
    lineManager: 'Marvin Ralph',
    hireDate: '14-05-2023',
    grossSalary: '300000',
    bankName: 'GTB',
    bankAccountName: 'Simon Pegg',
    bankAccountNumber: 4920392019,
    isBankAccountValidated: true,
    leftVacationInfo: [

    ],
    address: '6295 Lake Worth Rd #19',
    city: 'Los Angeles',
    state: 'California',
    kinFirstName: 'Wilter',
    kinLastName: 'Pan',
    kinPhoneNumber: '+1 3039403923',
    kinAddress: '6295 Lake Worth Rd #19',
    emergencyFirstName: 'Wilter',
    emergencyLastName: 'Pan',
    emergencyPhoneNumber: '+1 3039403923',
    emergencyAddress: '6295 Lake Worth Rd #19',
    userId: 10000003,
    status: 'active',
  },
  {
    firstName: 'Susan',
    middleName: '',
    lastName: 'Megg',
    email: 'susanmegg@gmail.com',
    phoneNumber: '+1 2328284903',
    role: 'employee',
    companyName: 'Leo Ltd',
    department: 'HR',
    position: 'HR officer',
    lineManager: 'Marvin Ralph',
    hireDate: '05-09-2023',
    grossSalary: '300000',
    bankName: 'GTB',
    bankAccountName: 'Susan Megg',
    bankAccountNumber: 7940392013,
    isBankAccountValidated: true,
    leftVacationInfo: [

    ],
    address: '6295 Lake Worth Rd #19',
    city: 'Los Angeles',
    state: 'California',
    kinFirstName: 'Wilter',
    kinLastName: 'Pan',
    kinPhoneNumber: '+1 3039403923',
    kinAddress: '6295 Lake Worth Rd #19',
    emergencyFirstName: 'Wilter',
    emergencyLastName: 'Pan',
    emergencyPhoneNumber: '+1 3039403923',
    emergencyAddress: '6295 Lake Worth Rd #19',
    userId: 10000003,
    status: 'active',
  },
  {
    firstName: 'Philip',
    middleName: 'Mae',
    lastName: 'Olaitan',
    email: 'philip@gmail.com',
    phoneNumber: '+1 9328284903',
    role: 'employee',
    companyName: 'Leo Ltd',
    department: 'Finance',
    position: 'HR officer',
    lineManager: 'Marvin Ralph',
    hireDate: '30-07-2023',
    grossSalary: '300000',
    bankName: 'GTB',
    bankAccountName: 'Peter Pan',
    bankAccountNumber: 3940392019,
    isBankAccountValidated: false,
    leftVacationInfo: [

    ],
    address: '6295 Lake Worth Rd #19',
    city: 'Los Angeles',
    state: 'California',
    kinFirstName: 'Wilter',
    kinLastName: 'Pan',
    kinPhoneNumber: '+1 3039403923',
    kinAddress: '6295 Lake Worth Rd #19',
    emergencyFirstName: 'Wilter',
    emergencyLastName: 'Pan',
    emergencyPhoneNumber: '+1 3039403923',
    emergencyAddress: '6295 Lake Worth Rd #19',
    userId: 10000003,
    status: 'leave',
  },
  {
    firstName: 'Sade',
    middleName: 'Mae',
    lastName: 'Akinlade',
    email: 'akinalade@gmail.com',
    phoneNumber: '+1 2328284903',
    role: 'employee',
    companyName: 'Leo Ltd',
    department: 'HR',
    position: 'HR officer',
    lineManager: 'Marvin Ralph',
    hireDate: '23-03-2023',
    grossSalary: '300000',
    bankName: 'GTB',
    bankAccountName: 'Peter Pan',
    bankAccountNumber: 2300392019,
    isBankAccountValidated: false,
    leftVacationInfo: [

    ],
    address: '6295 Lake Worth Rd #19',
    city: 'Los Angeles',
    state: 'California',
    kinFirstName: 'Wilter',
    kinLastName: 'Pan',
    kinPhoneNumber: '+1 3039403923',
    kinAddress: '6295 Lake Worth Rd #19',
    emergencyFirstName: 'Wilter',
    emergencyLastName: 'Pan',
    emergencyPhoneNumber: '+1 3039403923',
    emergencyAddress: '6295 Lake Worth Rd #19',
    userId: 10000003,
    status: 'terminated',
  },
  {
    firstName: 'Sade',
    middleName: 'Mae',
    lastName: 'Akinlade',
    email: 'akinalade@gmail.com',
    phoneNumber: '+1 2328284903',
    role: 'employee',
    companyName: 'Leo Ltd',
    department: 'HR',
    position: 'HR officer',
    lineManager: 'Marvin Ralph',
    hireDate: '23-03-2023',
    grossSalary: '300000',
    bankName: 'GTB',
    bankAccountName: 'Peter Pan',
    bankAccountNumber: 2300392019,
    isBankAccountValidated: false,
    leftVacationInfo: [

    ],
    address: '6295 Lake Worth Rd #19',
    city: 'Los Angeles',
    state: 'California',
    kinFirstName: 'Wilter',
    kinLastName: 'Pan',
    kinPhoneNumber: '+1 3039403923',
    kinAddress: '6295 Lake Worth Rd #19',
    emergencyFirstName: 'Wilter',
    emergencyLastName: 'Pan',
    emergencyPhoneNumber: '+1 3039403923',
    emergencyAddress: '6295 Lake Worth Rd #19',
    userId: 10000003,
    status: 'leave',
  },
]
const PayrollDetailTable = (props) => {
  const { payrollData, setPageState } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [editableRows, setEditableRows] = useState(new Set()); // State to track editable rows
  const isRowEditable = rowIndex => editableRows.has(rowIndex);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null); // State to track selected row for the more options popup
  const selectedRowsRef = useRef(null);

  const data = payrollData ?? [];
  const COLUMNS = [
    // {
    //   id: 'selection',
    //   Header: ({ getToggleAllRowsSelectedProps }) => (
    //     <div className="">
    //       <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
    //     </div>
    //   ),
    //   Cell: ({ row }) => <input type="checkbox" className="" {...row.getToggleRowSelectedProps()} />,
    // },
    { Header: 'Name', accessor: 'payeeName' },
    // { Header: 'Middle Name', accessor: 'middleName' },
    // { Header: 'Last Name', accessor: 'lastName' },
    { Header: 'Department', accessor: 'department' },
    { Header: 'Salary', accessor: row => `₦ ${row.totalPay}` },
    { Header: 'Total Amount Payable', accessor: row => `₦ ${row.totalPay}` },
    // {
    //   id: 'action',
    //   Header: ({ getToggleAllRowsSelectedProps }) => (
    //     <></>
    //   ),
    //   Cell: ({ row }) => <button onClick={event => handleMoreOptionsClick(event, row.index)}><img src={ThreeDots} className="w-[4px]"/></button>,
    // },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const { 
    getTableProps, 
    getTableBodyProps, 
    headerGroups, 
    page,
    // rows, 
    prepareRow, 
    state: {pageIndex, pageSize},
    selectedFlatRows,
    pageOptions, 
    pageCount,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 5 }, // Initial pagination state
    }, 
    useSortBy,
    usePagination,
    useRowSelect,
  );

  useEffect(() => {
    setSelectedRows(selectedFlatRows?.map(row => row.original));
  }, [selectedFlatRows, setSelectedRows]);

  useEffect(() => {
    console.log(pageIndex);
  }, [pageIndex])

  const toggleEditable = rowIndex => {
    setEditableRows(prevState => {
      const newSet = new Set(prevState);
      if (newSet.has(rowIndex)) {
        newSet.delete(rowIndex);
      } else {
        newSet.add(rowIndex);
      }
      return newSet;
    });
  };
  const handleMoreOptionsClick = (event, rowIndex) => {
    console.log(event, rowIndex);
    event.stopPropagation(); // Prevent table row click event from triggering
    setSelectedRowIndex(rowIndex === selectedRowsRef.current ? null : rowIndex); // Toggle selected row
  };
  useEffect(() => {
    selectedRowsRef.current = selectedRowIndex
  }, [selectedRowIndex]);
  
  return (
    <div className="w-full">
      <div className="flex ml-[-10px] gap-[20px]">
        <table
          {...getTableProps()}
          className="w-full bg-white rounded-xl"
        >
          <thead className="bg-[#EDEDED]">
            {headerGroups.map(headerGroup => (
              <tr 
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map(column => (
                  <th 
                    scope="col" 
                    className={`px-8 py-6 
                      ${column.id === 'payeeName' ? 'rounded-tl-xl' : ''}
                      ${column.id === 'Total Amount Payable' ? 'rounded-tr-xl' : ''}
                    `}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div className="flex items-center gap-[5px] text-left font-inter-semibold text-sm text-[#000000B2]">
                      {column.render('Header')}
                      {column?.id !== 'selection' && column?.id !== 'action' && <img src={SortIcon} className="w-[8px]" />}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody 
            className="divide-y divide-[#7b78781a]" 
            {...getTableBodyProps()}
          >
            {page.map((row, i) => {
              prepareRow(row);
              const showMoreOptions = selectedRowIndex === i; // Check if more options popup should be displayed for this row
              return (
                <Fragment key={row.id}>
                  <tr className="relative" {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td 
                        className={
                          `
                            px-8 py-10 text-[#00000080] font-inter-medium text-sm
                            ${cell.column.Header === 'First Name' ? 'underline underline-offset-2 cursor-pointer' : ''}
                          `
                        }
                        {...cell.getCellProps()}
                        // onClick={() => {
                        //   if (cell.column.Header === 'First Name') {
                        //     setSelectedEmployeeIndex(i);
                        //     setPageState(employeeRecordState.employeeView);
                        //   }
                        // }}
                      >
                        {cell.column.Header === 'Name' && 
                         <></>
                        }
                        {cell.column.Header === 'Status' ? (
                            <div className="flex gap-[10px]">
                                <div
                                  className={`
                                    ${(cell.column.Header === 'Status' && cell.row.original.status === 'active') ? 
                                      'bg-[#1DA39259] text-[#1DA392] font-inter-semibold' : 
                                      ''
                                    }
                                    ${(cell.column.Header === 'Status' && cell.row.original.status === 'leave') ? 
                                      'bg-[#FBDCAE] text-[#5D3208] font-inter-semibold' : 
                                      ''
                                    }
                                    ${(cell.column.Header === 'Status' && cell.row.original.status === 'terminated') ? 
                                      'bg-[#DEDEDE] text-[#343434] font-inter-semibold' : 
                                      ''
                                    }
                                    px-2 py-1 rounded-xl
                                  `}
                                >
                                  {cell.row.original.status}
                                </div>
                            </div>
                          ) : (
                              cell.column.Header === 'Action' ? (
                                <div className="flex gap-[20px] items-center">
                                  
                                </div>
                              ) : (
                                cell.column.id === 'selection' ? (
                                  cell.render('Cell')
                                ) : (
                                  isRowEditable(i) ? (
                                    <input
                                      type="text"
                                      value={cell.value}
                                      onChange={e => {
                                        // You'll need to update your data here
                                        data[row.index][cell.column.id] = e.target.value;
                                      }}
                                      className="w-full"
                                    />
                                  ) : (
                                    cell.render('Cell')
                                  )
                                )
                              )
                            )
                        }
                      </td>
                    ))}
                    {showMoreOptions && ( // Render more options popup if showMoreOptions is true
                      <div className="p-[10px] flex flex-col absolute right-[20px] top-[80px] bg-[#edeaea] rounded-md z-[999]">
                        <button
                          onClick={() => {
                            // setSelectedEmployeeIndex(selectedRowIndex);
                            // setPageState(employeeRecordState.employeeEdit);
                          }}

                        >
                          <div className="flex items-center gap-[5px]">
                            <img src={Edit} className="w-[10px]" alt='edit' />
                            <div className="font-inter-medium text-[10px] text-[#000000B2]">Edit</div>
                          </div>
                        </button>
                        <div className="py-[2px] mx-[-5px] border-b-[1px] border-[#00000026]"/>
                        <button className="pt-[4px]">
                          <div className="flex items-center gap-[5px]">
                            <img src={Remove} className="w-[10px]" alt='remove' />
                            <div className="font-inter-medium text-[10px] text-[#000000B2]">Remove</div>
                          </div>
                        </button>
                      </div>
                    )}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        pageIndex={pageIndex}
        pageCount={pageCount}
        pageOptions={pageOptions}
        pageSize={pageSize}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
      />
    </div>
  );
};
export default PayrollDetailTable;
