import React, { useContext, useState } from "react";
import {
  DropdownSVG,
  MarkSVG,
} from "../../constants/payrollmanagement/payroll_management";
import {
  AnnualLeaveSVG,
  SickLeaveSVG,
  EmergencyLeaveSVG,
} from "../../constants/payrollmanagement/payroll_management";
import { createBandData } from "../../services/api";
import { AuthContext } from "../../contexts/AuthContext";
import Modal from "../Modal/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VacationDropdown = (props) => {
  const {
    options_leave,
    selectedOption,
    setSelectedOption,
    options_band,
    selectedBandOption,
    setSelectedBandOption,
    selectClassName,
    optionClassName,
  } = props;

  // State to manage dropdown visibility
  const [showLeaveDropdown, setshowLeaveDropdown] = useState(false);
  const [showBandDropdown, setshowBandDropdown] = useState(false);
  const [showBand, setShowBand] = useState(false);
  const [showNumber, setShowNumber] = useState(false);
  const [enterNumber, setEnterNumber] = useState(0);
  const { user } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [vacationType, setVacationType] = useState("");
  const handleLeaveSelectOption = (options_leave_index) => {
    setSelectedOption(options_leave_index);
    const selectedLeaveLabel =
      options_leave.find((option) => option.index === options_leave_index)
        ?.label || "Select";
    setVacationType(selectedLeaveLabel);

    setshowLeaveDropdown(false); // Hide dropdown after selection
    setShowBand(true);
  };
  const handleBandSelectOption = (options_band) => {
    setSelectedBandOption(options_band);
    setshowBandDropdown(false); // Hide dropdown after selection
    setShowNumber(true);
  };
  const toggleLeaveDropdown = () => {
    setshowLeaveDropdown((prev) => !prev);
    //setShowBand(false);
  };
  const toggleBandDropdown = () => {
    setshowBandDropdown((prev) => !prev);
    // setshowLeaveDropdown((prev) => !prev);
    // setShowBand(false);
  };
  const handleMouseLeave = () => {
    setshowLeaveDropdown(false); // Hide dropdown when mouse leaves the component area
    //!(options_leave === "Select vacation") && setShowBand(true);
  };
  const handleChange = (e) => {
    setEnterNumber(e.target.value);
    console.log(enterNumber);
  };
  const handleBandDaysSave = async () => {
    const selectedBandLabel =
      options_band.find((option) => option.index === selectedBandOption)
        ?.label || "Select";
    const daysAsNumber = Number(enterNumber);
    const payload = {
      companyName: user.companyName,
      name: selectedBandLabel,
      vacationPolicies: [
        {
          type: vacationType,
          days: daysAsNumber,
        },
      ],
    };

    try {
      const res = await createBandData(payload);

      toast.success("Band data saved successfully!");

      //setShowModal(true); // Show modal on success
      console.log("Response from createBandData:", payload);
    } catch (error) {
      toast.error(`Error: ${error.message}`);
      console.error("Failed to save band data:", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div
        className={`relative w-[453px] h-[300px] mt-[30px]`}
        onMouseLeave={handleMouseLeave} // Event to hide dropdown when mouse leaves
      >
        <div>
          <button
            className="flex justify-between items-center w-[100%] h-[50px] bg-[#EBEBEB] text-[15px] text-[#000000] text-left py-1 pl-[30px] border-[1px] border-[#C4C4C4] rounded-md focus:outline-none focus:border-[#C4C4C4]"
            onClick={toggleLeaveDropdown}
            aria-haspopup="true"
            aria-expanded={showLeaveDropdown}
          >
            {options_leave.find((option) => option.index === selectedOption)
              ?.label || "Select"}
            <img className="mr-[30px]" src={DropdownSVG} />
          </button>
        </div>
        {showLeaveDropdown && (
          <div className="absolute z-10 right-0 mt-1 w-[273px]  pt-[19px] rounded-md shadow-lg bg-white ring-1 ring-black  focus:outline-none">
            <p className="ml-[40px] font-inter-regular text-[#00000066] text-[10px] font-[400]">
              Default
            </p>
            {options_leave.map(
              (option, index) =>
                option.index !== 0 && ( // Check if the index is not 0 before rendering the button
                  <button
                    className={`flex ml-[40px] font-inter-regular text-[12px] font-[500] text-[#000000] block w-full text-left py-2 ${
                      selectedOption === option.index ? "bg-gray-100" : ""
                    } ${optionClassName}`}
                    key={index}
                    onClick={() => handleLeaveSelectOption(option.index)}
                  >
                    <img
                      className="mr-[20px]"
                      style={{ width: "20px", height: "20px" }} // Corrected style attribute
                      src={option.img}
                      alt={option.label}
                    />
                    {option.label}
                  </button>
                )
            )}
            <div className="w-[90%] mx-[5%] h-[1px] bg-[#00000033] my-[20px]" />
            <p className="font-inter-regular ml-[40px] text-[#00000066] text-[10px] font-[400]">
              Custom
            </p>
            <button className="flex items-center w-[244px] h-[39px] bg-[#D9D9D9] rounded-md text-center mx-[15px] my-[13px] pl-[23px]">
              <img
                className="mr-[20px]"
                style={{ width: "20px", height: "20px" }} // Corrected style attribute
                src={EmergencyLeaveSVG}
                alt="Emergency Leave"
              />
              {options_leave.find((option) => option.index === selectedOption)
                ?.label || "Select"}
            </button>
          </div>
        )}
        {showBand && (
          <div>
            <div className="w-[453px] bg-white border-[#EFF0F6] rounded-lg border-[1px] mt-[35px] px-[25px] py-[35px]">
              <p className=" font-inter-bold font-[500] text-[15px]">
                Band of Employee
              </p>
              <p className=" font-inter-regular font-[500] text-[15px]">
                Select band of employee from the below dropdown
              </p>
              <div className="w-[403px] h-[1px] mx-auto bg-[#00000033] mt-[20px]" />

              <button
                className="flex justify-between items-center w-[100%] h-[50px] text-[15px] text-[#000000] text-left mt-[60px] pl-[30px]"
                onClick={toggleBandDropdown}
                aria-haspopup="true"
                aria-expanded={showLeaveDropdown}
              >
                {options_band.find(
                  (option) => option.index === selectedBandOption
                )?.label || "Select"}
                <img className="mr-[30px]" src={DropdownSVG} />
              </button>
              <div className="w-[403px] h-[1px] mx-auto bg-[#00000033] mb-[45px]" />
              {showNumber && (
                <div>
                  {" "}
                  <p className=" font-inter-bold font-[500] text-[15px]">
                    Numbers of Days
                  </p>
                  <p className=" font-inter-regular font-[500] text-[14px]">
                    Set the number of days eligible for this band of employees
                  </p>
                  <input
                    type={"text"}
                    id="banddays"
                    name="banddays"
                    //value={enterNumber}
                    onChange={handleChange}
                    placeholder={"Enter number of days"}
                    className="h-[50px] px-4 py-2 w-full rounded-md border-[#00000033] border-[1px] pr-[50px]  text-[#000000] mt-[24px]" // Increased padding-right to make room for the icon
                  />
                  <div className="flex justify-end mt-[20px] gap-[20px]">
                    <button className=" border-[#011165] border-[1px] rounded-md p-2 font-inter-semibold text-[#011165]">
                      CANCEL
                    </button>
                    <button
                      className=" rounded-md p-2 font-inter-semibold bg-[#011165] text-white active:bg-opacity-30"
                      onClick={handleBandDaysSave}
                    >
                      SAVE
                    </button>
                  </div>
                </div>
              )}
            </div>
            {showBandDropdown && (
              <div className="absolute z-10 right-[-300px] top-[250px] pb-[25px] w-[273px]  pt-[19px] rounded-md shadow-lg bg-white ring-1 ring-black  focus:outline-none">
                <p className="ml-[40px] font-inter-regular text-[#00000066] text-[15px] font-[400]">
                  Band of Employee
                </p>
                <div className="w-[90%] mx-[5%] h-[1px] bg-[#00000033] my-[20px]" />
                {options_band.map(
                  (option, index) =>
                    option.index !== 0 && ( // Check if the index is not 0 before rendering the button
                      <button
                        className={`flex ml-[40px] font-inter-regular text-[12px] font-[500] text-[#000000] block w-full text-left py-2 ${
                          selectedOption === option.index ? "bg-gray-100" : ""
                        } ${optionClassName}`}
                        key={index}
                        onClick={() => handleBandSelectOption(option.index)}
                      >
                        <img
                          className="mr-[20px]"
                          style={{ width: "20px", height: "20px" }} // Corrected style attribute
                          src={option.img}
                          alt={option.label}
                        />
                        {option.label}
                      </button>
                    )
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title="Modal Header"
      >
        <div className="w-[452px] h-[534px] rounded-lg flex flex-col items-center justify-center gap-[16px]">
          <img src={MarkSVG} alt="Success" />
          <p className=" font-inter-bold font-[600] text-[24px] text-payroll_primary">
            Successful
          </p>
          <p className=" font-inter-regular font-[400] text-[10px] text-payroll_primary">
            successfully created. Add employee to band.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default VacationDropdown;
