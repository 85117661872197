import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminContext } from "../../../contexts/AdminContext";
import Search from "../../../assets/images/dashboard/svg/search.svg";
import Terminate from "../../../assets/images/dashboard/svg/terminate.svg";
import Dropdown from "../../../components/Dropdown/index";
import { AuthContext } from "../../../contexts/AuthContext";
import { getEmployeeData, terminateEmployee } from "../../../services/api";
import EmployeeRecordTable from "./EmployeeRecordTable";
import { employeeRecordState } from "../../../constants/constants";
import EmployeeView from "./EmployeeView";
import EditEmployee from "./EditEmployee";

const dummyEmployee = [
  {
    firstName: "Peter",
    middleName: "Oscar",
    lastName: "Pan",
    email: "peterpan@gmail.com",
    phoneNumber: "+1 2328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "HR",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "23-03-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Peter Pan",
    bankAccountNumber: 3940392019,
    isBankAccountValidated: true,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000002,
    status: "active",
  },
  {
    firstName: "Simon",
    middleName: "Oscar",
    lastName: "Pegg",
    email: "simonpegg@gmail.com",
    phoneNumber: "+1 4328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "ICT",
    position: "UX designer",
    lineManager: "Marvin Ralph",
    hireDate: "14-05-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Simon Pegg",
    bankAccountNumber: 4920392019,
    isBankAccountValidated: true,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "active",
  },
  {
    firstName: "Susan",
    middleName: "",
    lastName: "Megg",
    email: "susanmegg@gmail.com",
    phoneNumber: "+1 2328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "HR",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "05-09-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Susan Megg",
    bankAccountNumber: 7940392013,
    isBankAccountValidated: true,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "active",
  },
  {
    firstName: "Philip",
    middleName: "Mae",
    lastName: "Olaitan",
    email: "philip@gmail.com",
    phoneNumber: "+1 9328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "Finance",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "30-07-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Peter Pan",
    bankAccountNumber: 3940392019,
    isBankAccountValidated: false,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "leave",
  },
  {
    firstName: "Sade",
    middleName: "Mae",
    lastName: "Akinlade",
    email: "akinalade@gmail.com",
    phoneNumber: "+1 2328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "HR",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "23-03-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Peter Pan",
    bankAccountNumber: 2300392019,
    isBankAccountValidated: false,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "terminated",
  },
  {
    firstName: "Sade",
    middleName: "Mae",
    lastName: "Akinlade",
    email: "akinalade@gmail.com",
    phoneNumber: "+1 2328284903",
    role: "employee",
    companyName: "Leo Ltd",
    department: "HR",
    position: "HR officer",
    lineManager: "Marvin Ralph",
    hireDate: "23-03-2023",
    grossSalary: "300000",
    bankName: "GTB",
    bankAccountName: "Peter Pan",
    bankAccountNumber: 2300392019,
    isBankAccountValidated: false,
    leftVacationInfo: [],
    address: "6295 Lake Worth Rd #19",
    city: "Los Angeles",
    state: "California",
    kinFirstName: "Wilter",
    kinLastName: "Pan",
    kinPhoneNumber: "+1 3039403923",
    kinAddress: "6295 Lake Worth Rd #19",
    emergencyFirstName: "Wilter",
    emergencyLastName: "Pan",
    emergencyPhoneNumber: "+1 3039403923",
    emergencyAddress: "6295 Lake Worth Rd #19",
    userId: 10000003,
    status: "leave",
  },
];
const AdminEmployeeRecord = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { employeeData, setEmployeeData } = useContext(AdminContext);
  const [pageState, setPageState] = useState(employeeRecordState.employeeTable);
  const [searchName, setSearchName] = useState("");
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [lineManagerOptions, setLineManagerOptions] = useState([
    {
      index: 0,
      label: "Select line manager",
    },
    {
      index: 1,
      label: "Peter",
    },
    {
      index: 2,
      label: "Susan",
    },
  ]);
  const [selectedLineManagerOption, setSelectedLineManagerOption] = useState(0);
  const [statusOptions, setStatusOptions] = useState([
    {
      index: 0,
      label: "All status",
    },
    {
      index: 1,
      label: "Active",
    },
    {
      index: 2,
      label: "Leave",
    },
    {
      index: 3,
      label: "Terminated",
    },
  ]);
  const [selectedStatusOption, setSelectedStatusOption] = useState(0);
  const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(0);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  // useEffect(() => {
  //   setEmployeeData(dummyEmployee);
  // }, []);

  const fetchEmployeeData = async (companyName) => {
    const res = await getEmployeeData(companyName);

    if (res?.length > 0) {
      setEmployeeData(res);
    }
  };
  useEffect(() => {
    if (Object.keys(user).length > 0 && !employeeData) {
      fetchEmployeeData(user?.companyName);
    }
  }, [user]);
  useEffect(() => {
    if (Object.keys(user).length > 0) {
      fetchEmployeeData(user?.companyName);
    }
  }, [triggerFetch]);
  useEffect(() => {
    if (employeeData && employeeData?.length > 0) {
      const uniqueLineManagers = [
        ...new Set(employeeData.map((employee) => employee.lineManager)),
      ].filter((manager) => manager !== undefined);
      setLineManagerOptions([
        {
          index: 0,
          label: "Select line manager",
        },
        ...uniqueLineManagers.map((e, index) => {
          return { index: index + 1, label: e };
        }),
      ]);
    }
  }, [employeeData]);
  useEffect(() => {}, [lineManagerOptions]);
  const handleSearchInputChange = (event) => {
    setSearchName(event.target.value);
  };
  useEffect(() => {}, [selectedEmployees]);
  const handleTerminate = async () => {
    if (selectedEmployees?.length === 0) {
      return;
    }
    let terminateUserIds = selectedEmployees.map((employee) => employee.userId);
    const res = await terminateEmployee({ userIds: terminateUserIds });

    if (res) {
      setTriggerFetch((prev) => !prev);
    }
  };
  return (
    <div className="w-full">
      <div className="text-[20px] font-inter-bold text-[#011165]">
        Employee Management
      </div>
      <div className="mt-[12px] border-b-[1px] border-[#0000001A]" />
      {pageState === employeeRecordState.employeeTable && (
        <div className="w-full flex flex-col gap-[20px] items-center justify-between py-[30px]">
          <div className="w-full flex justify-between gap-[10px] items-center">
            <div className="font-inter-bold text-base text-[#404040]">
              Employee Records
            </div>
          </div>
          <div className="w-full flex justify-between gap-[10px] items-center">
            <div className="flex gap-[10px]">
              <div className="relative flex">
                <img
                  src={Search}
                  className="w-[12] absolute top-[7px] left-[3px]"
                  alt="search"
                />
                <input
                  type="text"
                  name="searchName"
                  className="w-[167px] h-[27px] border-[1px] border-[#00000040] rounded-md px-[18px] py-[8px] focus:outline-none focus:border-[#000000A0] text-[11px] font-inter-regular text-[#000000B2]"
                  value={searchName}
                  placeholder="Search for employee..."
                  onChange={handleSearchInputChange}
                />
              </div>
              <Dropdown
                options={lineManagerOptions}
                selectedOption={selectedLineManagerOption}
                setSelectedOption={setSelectedLineManagerOption}
                selectClassName="w-[167px] h-[27px] text-[10px] font-inter-regular text-[#000000B2]"
                optionClassName=""
              />
              <Dropdown
                options={statusOptions}
                selectedOption={selectedStatusOption}
                setSelectedOption={setSelectedStatusOption}
                selectClassName="w-[167px] h-[27px] text-[10px] font-inter-regular text-[#000000B2]"
              />
            </div>
            <div className="flex gap-[10px]">
              <button
                className="flex gap-[8px] items-center rounded-md font-inter-bold text-[10px] text-white p-[10px] px-[15px] bg-[#011165]"
                onClick={handleTerminate}
              >
                <img src={Terminate} className="w-[18px]" alt="terminate" />
                TERMINATE
              </button>
              {/* <button 
                className="rounded-md font-inter-bold text-[10px] text-[#A6A6A6] p-[10px] px-[15px] bg-[#01116526]"
                onClick={() => {
                }}
                disabled={true}
              >
                EDIT
              </button> */}
            </div>
          </div>
          {employeeData && employeeData?.length > 0 && (
            <EmployeeRecordTable
              employeeData={employeeData}
              setEmployeeData={setEmployeeData}
              setSelectedEmployeeIndex={setSelectedEmployeeIndex}
              setSelectedEmployees={setSelectedEmployees}
              setPageState={setPageState}
              filterName={searchName}
              filterLineManager={
                lineManagerOptions[selectedLineManagerOption].label
              }
              filterStatus={statusOptions[selectedStatusOption].label}
            />
          )}
        </div>
      )}
      {pageState === employeeRecordState.employeeView && (
        <EmployeeView
          employee={employeeData[selectedEmployeeIndex]}
          setPageState={setPageState}
        />
      )}
      {pageState === employeeRecordState.employeeEdit && (
        <EditEmployee
          employee={employeeData[selectedEmployeeIndex]}
          setPageState={setPageState}
          setTriggerFetch={setTriggerFetch}
        />
      )}
    </div>
  );
};
export default AdminEmployeeRecord;
