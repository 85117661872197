/* eslint-disable */
import React, { createContext, useState } from "react";

export const AdminContext = createContext();

const AdminProvider = ({ children }) => {
  const [payrollHistory, setPayrollHistory] = useState(null);
  const [upcomingEvent, setUpcomingEvent] = useState(null);
  const [employeeData, setEmployeeData] = useState(null);
  const [payrollData, setPayrollData] = useState(null);
  const [approvalData, setApprovalData] = useState(null);
  const [paymentSetting, setPaymentSetting] = useState(null);
  const [approverData, setApproverData] = useState(null);
  const [vacationRequests, setVacationRequests] = useState(null);
  const [companyDeduction, setCompanyDeduction] = useState(null);
  return (
    <AdminContext.Provider
      value={{
        payrollHistory,
        setPayrollHistory,
        upcomingEvent,
        setUpcomingEvent,
        employeeData,
        setEmployeeData,
        payrollData,
        setPayrollData,
        approvalData,
        setApprovalData,
        paymentSetting,
        setPaymentSetting,
        approverData,
        setApproverData,
        vacationRequests,
        setVacationRequests,
        companyDeduction,
        setCompanyDeduction
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
