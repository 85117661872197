import { useContext, useEffect, useState } from "react";
import Modal from "../../../components/Modal/index";
import Info from "../../../assets/images/dashboard/svg/info.svg";
import ThreeDots from "../../../assets/images/dashboard/svg/three-dots.svg";
import LeftArrow from "../../../assets/images/dashboard/svg/left-arrow.svg";
import SaveIcon from "../../../assets/images/dashboard/svg/save.svg";
import PrintIcon from "../../../assets/images/dashboard/svg/print.svg";
import DownloadIcon from "../../../assets/images/dashboard/svg/download2.svg";
import { formatDate, getMonthFromDate } from "../../../utils/helper";
import { EmployeeContext } from "../../../contexts/EmployeeContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { getPaymentHistory } from "../../../services/api";
import { AdminContext } from "../../../contexts/AdminContext";
import { payrollHistoryState } from "../../../constants/constants";

const PayrollCard = (props) => {
  const { data, onClick } = props;
  return (
    <div className="w-1/4">
      <div
        className="m-[10px] px-[13px] py-[18px] border-[1px] border-[#EFF0F6] rounded-xl cursor-pointer  hover:scale-110 transition-transform"
        onClick={onClick}
      >
        <div className="flex items-center justify-between">
          <div className="font-inter-medium font-[#00000080] text-[13px]">
            {getMonthFromDate(data.date)}
          </div>
          <img
            src={ThreeDots}
            className="w-[3px] cursor-pointer"
            alt="detail"
          />
        </div>
        <div className="pt-[10px] font-inter-bold text-[18px] text-[#000000B2]">
          &#8358; {data.totalAmount}
        </div>
      </div>
    </div>
  );
};
const PayrollInMonth = (props) => {
  const { payrollHistory, setPageState, setSelectedPayrollIndex } = props;
  const [selectedYear, setSelectedYear] = useState("2024");

  return (
    <>
      <div className="w-full flex items-center gap-[15px] py-[20px]">
        <img src={Info} className="w-[19px]" alt="info" />
        <div className="font-inter-regular text-[10px] text-[#00000080]">
          Showing pay slips for {selectedYear}. To show results for a previous
          year, change timeframe
        </div>
      </div>
      <div className="flex flex-wrap pt-[10px]">
        {payrollHistory &&
          payrollHistory?.length > 0 &&
          payrollHistory.map((payData, index) => {
            return (
              <PayrollCard
                data={payData}
                onClick={() => {
                  console.log(index);
                  setPageState(payrollHistoryState.historyDetail);
                  setSelectedPayrollIndex(index);
                }}
                key={index}
              />
            );
          })}
      </div>
    </>
  );
};
export default PayrollInMonth;
