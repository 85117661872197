import React, { useContext, useEffect, useState } from "react";
import { EmployeeContext } from "../../contexts/EmployeeContext";
import { MONTH_ABBR_NAMES } from "../../constants/Calendar/month";

const thisMonth = new Date().getMonth();
const thisDate = new Date().getDate();
const thisYear = new Date().getFullYear();

const CalendarDate = ({ day, weekNumber }) => {
  const { setFocusDate, selectedDays, setSelectedDays } =
    useContext(EmployeeContext);
  const [selected, setSelected] = useState(false);

  const compareDay = (_day1, _day2) => {
    return (
      _day1.date === _day2.date &&
      _day1.month === _day2.month &&
      _day1.year === _day2.year
    );
  };
  const handleClickDate = (/** @type {any} */ day) => {
    // setFocusDate(day);
    if (selectedDays.some((_day) => compareDay(_day, day))) {
      setSelectedDays((_days) =>
        _days.filter((_day) => !compareDay(_day, day))
      );
      // setSelected(false);
    } else {
      setSelectedDays((_days) => [..._days, day]);
      // setSelected(true);
    }
  };

  useEffect(() => {
    setSelected(selectedDays.some((_day) => compareDay(_day, day)));
  }, [selectedDays, day]);

  const isToday =
    day.month === thisMonth && day.date === thisDate && day.year === thisYear;

  const borderClass = weekNumber === 0 ? "border-t-[0px]" : "border-t-[1px]";
  const todayHighlightClass = isToday ? "bg-[#1a73e8] text-white ml-1" : "";

  return (
    <td
      className={`border-[1px] border-solid border-[#9999994D] ${borderClass} ${
        selected
          ? "text-white"
          : day.week_day === 0 || day.week_day === 6
          ? "text-[#D62243]"
          : "text-[#333333]"
      } ${selected ? "bg-[#011165]" : "bg-white"}`}
      onClick={() => handleClickDate(day)}
      role="gridcell"
    >
      <div>
        <div className={`text-sm mt-1 h-[90px]`}>
          <div
            className={`${todayHighlightClass} ${
              day.date === 1
                ? "rounded-[15px] px-2 h-[30px] flex items-center"
                : "flex items-center justify-center w-[30px] rounded-[50%] h-[30px]"
            }`}
          >
            {day.date === 1
              ? `${MONTH_ABBR_NAMES[day.month]} ${day.date}`
              : day.date}
          </div>
        </div>
      </div>
    </td>
  );
};

export default CalendarDate;
