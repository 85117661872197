import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminContext } from "../../../contexts/AdminContext";
import Search from "../../../assets/images/dashboard/svg/search.svg";
import Generate from "../../../assets/images/dashboard/svg/generate.svg";
import Deduction from "../../../assets/images/dashboard/svg/deduction.svg";
import Dropdown from "../../../components/Dropdown/index";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  addCompanyDeduction,
  getCompanyDeduction,
  getEmployeeData,
  updateCompanyData,
} from "../../../services/api";
import EmployeeRecordTable from "./EmployeeRecordTable";
import { employeeRecordState } from "../../../constants/constants";
import Modal from "../../../components/Modal/index";
import DeductionTable from "./DeductionTable";

const AdminDeduction = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { companyDeduction, setCompanyDeduction } = useContext(AdminContext);
  const [pageState, setPageState] = useState(employeeRecordState.employeeTable);
  const [searchName, setSearchName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({});

  const fetchDeductionData = async (companyName) => {
    const res = await getCompanyDeduction(companyName);

    if (res?.length > 0) {
      setCompanyDeduction(res);
    }
  };
  useEffect(() => {
    if (Object.keys(user).length > 0 && !companyDeduction) {
      fetchDeductionData(user.companyName);
    }
  }, [user]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log(formData);
    let deduction = companyDeduction ?? [];
    console.log(deduction);
    if (!isEdit) {
      deduction.push({
        deductionType: formData.deductionType,
        deductionName: formData.deductionName,
        deductionAmount: formData.deductionAmount,
        deductionPercentage: formData.deductionPercentage,
      });
    } else {
      deduction[formData.index].deductionType = formData.deductionType;
      deduction[formData.index].deductionName = formData.deductionName;
      deduction[formData.index].deductionAmount = formData.deductionAmount;
      deduction[formData.index].deductionPercentage =
        formData.deductionPercentage;
    }
    console.log(deduction);
    const res = await updateCompanyData({
      companyName: user.companyName,
      deduction: deduction,
    });
    console.log(res);
    fetchDeductionData(user.companyName);
    setFormData({
      deductionName: "",
      deductionType: "",
      deductionAmount: 0,
      deductionPercentage: 0,
    });
    setIsEdit(false);
    setShowModal(false);
  };
  const handleDelete = async (index) => {
    let deduction = companyDeduction;
    deduction.splice(index, 1);
    const res = await updateCompanyData({
      companyName: user.companyName,
      deduction: deduction,
    });
    fetchDeductionData(user.companyName);
  };
  const handleEdit = (index) => {
    let data = {};
    Object.assign(data, companyDeduction[index], { index });
    setFormData(data);
    setIsEdit(true);
    setShowModal(true);
  };
  const handleCancel = () => {
    setFormData({
      deductionName: "",
      deductionType: "",
      deductionAmount: 0,
      deductionPercentage: 0,
    });
    setIsEdit(false);
    setShowModal(false);
  };
  return (
    <>
      <div className="w-full">
        <div className="text-[20px] font-inter-bold text-[#011165]">
          Pay Slips
        </div>
        <div className="mt-[12px] border-b-[1px] border-[#0000001A]" />
        <div className="w-full flex justify-between gap-[10px] items-center py-[20px]">
          <div className="font-inter-bold text-base text-[#404040]">
            Deductions
          </div>
          <button
            className="rounded-md font-inter-bold text-[10px] text-white p-[10px] bg-[#011165]"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <span className="text-[14px]">+</span> ADD DEDUCTION
          </button>
        </div>
        <div className="w-2/3">
          {companyDeduction && (
            <DeductionTable
              deductionData={companyDeduction}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          )}
        </div>
      </div>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title="Modal Heade"
      >
        <div className="max-h-[678px] min-w-[588px]">
          <div className="flex gap-[20px]">
            <img src={Deduction} className="w-[53px]" alt="deduction" />
            <div className="flex flex-col justify-between">
              <div className="font-inter-semibold text-[20px] text-[#011165]">
                Add Deductions
              </div>
              <div className="font-inter-regular text-[12px] text-[#00000080]">
                Add custom deductions.
              </div>
            </div>
          </div>
          <div className="pt-[30px] border-b-[1px] border-[#00000026]" />
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-[20px] py-[30px]">
              <div className="font-inter-semibold text-[15px] text-[#000000B2]">
                Add Custom Deduction
              </div>
              <div className="w-full flex flex-col gap-[10px]">
                <div className="w-full flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                  <div className="font-inter-bold text-[#000000B2]">
                    Deduction Name
                  </div>
                  <input
                    type="text"
                    name="deductionName"
                    className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                    value={formData.deductionName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="w-full flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                  <div className="font-inter-bold text-[#000000B2]">
                    Deduction Type
                  </div>
                  <input
                    type="text"
                    name="deductionType"
                    className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                    value={formData.deductionType}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="w-full flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                  <div className="font-inter-bold text-[#000000B2]">Amount</div>
                  <input
                    type="number"
                    name="deductionAmount"
                    className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                    value={formData.deductionAmount}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="w-full flex flex-col gap-[5px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                  <div className="font-inter-bold text-[#000000B2]">
                    Percentage
                  </div>
                  <input
                    type="number"
                    name="deductionPercentage"
                    className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                    value={formData.deductionPercentage}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-[10px] pt-[20px]">
              <button
                className="rounded-md font-inter-bold text-[10px] text-[#011165] px-[10px] py-[10px] border-[1px] border-[#011165]"
                onClick={handleCancel}
              >
                CANCEL
              </button>
              <button
                className="rounded-md font-inter-bold text-[10px] text-white px-[10px] py-[10px] bg-[#011165]"
                type="submit"
              >
                + ADD DEDUCTION
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default AdminDeduction;
