import "./App.css";
import Routers from "./Route/index";
import "react-datepicker/dist/react-datepicker.css";
import EmployeeProvider from "./contexts/EmployeeContext";
import AdminProvider from "./contexts/AdminContext";
import AuthProvider from "./contexts/AuthContext";
import NotificationProvider from "./contexts/NotificationContext";
import { useEffect } from "react";
function App() {
  return (
    <div className="App">
      <AuthProvider>
        <AdminProvider>
          <EmployeeProvider>
            <NotificationProvider>
              <Routers />
            </NotificationProvider>
          </EmployeeProvider>
        </AdminProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
