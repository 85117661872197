import {
  PAYROLL_FOOTER_COMPANY,
  PAYROLL_FOOTER_CONTACTUS,
  PAYROLL_FOOTER_DOWNLOADAPP,
  PAYROLL_FOOTER_COPYRIGHT,
  PAYROLL_FOOTER_PRODUCTS,
  PAYROLL_FOOTER_PAYROLL,
  PAYROLL_FOOTER_PRODUCTS_TITLE,
} from "../../constants/landingpage_payroll/landingpage_payroll";
const Footer = () => {
  return (
    <div className="mt-[330px]">
      <div className="flex justify-between px-[62px] gap-[97px]">
        <div>
          <p className=" font-eina02-bold font-[400] text-[24px] text-white">
            {PAYROLL_FOOTER_PAYROLL.title}
          </p>
          <p
            style={{ lineHeight: "30px" }}
            className=" font-eina02-regular font-[400] text-[16px] text-white w-[130px] pt-[40px] line-[30px]"
          >
            {PAYROLL_FOOTER_PAYROLL.street}
          </p>
        </div>
        <div>
          <p className=" font-eina02-bold font-[400] text-[20px] text-white">
            {PAYROLL_FOOTER_COMPANY.title}
          </p>
          <p className=" font-eina02-regular font-[400] text-[16px] text-white w-[130px] pt-[40px] line-[30px]">
            {PAYROLL_FOOTER_COMPANY.aboutus}
          </p>
          <p className=" font-eina02-regular font-[400] text-[16px] text-white w-[130px] pt-[15px] line-[30px]">
            {PAYROLL_FOOTER_COMPANY.faqs}
          </p>
        </div>
        <div>
          <p className=" font-eina02-bold font-[400] text-[20px] text-white">
            {PAYROLL_FOOTER_PRODUCTS_TITLE}
          </p>
          <ul className=" font-eina02-regular font-[400] text-[16px] text-white columns-2 pt-[40px]">
            {PAYROLL_FOOTER_PRODUCTS.map((tab) => (
              <li
                key={tab.label}
                className="mr-[0px] mb-[24px] break-inside-avoid"
              >
                {tab.label}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <p className="font-eina02-bold font-[400] text-[20px] text-white">
            {PAYROLL_FOOTER_DOWNLOADAPP.title}
          </p>
          <img
            src={PAYROLL_FOOTER_DOWNLOADAPP.playstoresvg}
            alt="Google Play"
            className="pt-[40px] cursor-pointer"
          />
          <img
            src={PAYROLL_FOOTER_DOWNLOADAPP.appstoresvg}
            alt="App Store"
            className="pt-[15px] cursor-pointer"
          />
        </div>
      </div>
      <div className="w-[1210px] border-[1px] border-white opacity-[20%] mx-auto"></div>
      <div className="flex justify-between px-[62px] pt-[20px]">
        <div className="flex items-center gap-2">
          <img src={PAYROLL_FOOTER_COPYRIGHT.csvg} alt="C" />
          <p className=" font-eina02-regular font-[400] text-[20px] text-white">
            {PAYROLL_FOOTER_COPYRIGHT.title}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <img
            src={PAYROLL_FOOTER_COPYRIGHT.phonesvg}
            alt="Phone"
            className=" cursor-pointer"
          />
          <img
            src={PAYROLL_FOOTER_COPYRIGHT.facebooksvg}
            alt="FaceBook"
            className=" cursor-pointer"
          />
          <img
            src={PAYROLL_FOOTER_COPYRIGHT.twittersvg}
            alt="Twitter"
            className=" cursor-pointer"
          />
          <img
            src={PAYROLL_FOOTER_COPYRIGHT.linkedinsvg}
            alt="LinkedIn"
            className=" cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};
export default Footer;
