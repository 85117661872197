import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminContext } from "../../../contexts/AdminContext";
import Search from "../../../assets/images/dashboard/svg/search.svg";
import Terminate from "../../../assets/images/dashboard/svg/terminate.svg";
import Dropdown from "../../../components/Dropdown/index";
import { AuthContext } from "../../../contexts/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  approveVacationRequest,
  getEmployeeData,
  getPendingVacationRequest,
} from "../../../services/api";
import {
  approvePayrollState,
  approveStatus,
  approverManagementState,
  employeeRecordState,
  initiatePayrollState,
} from "../../../constants/constants";
import VacationRequestTable from "./VacationRequestTable";

const dummyVacationRequest = [
  {
    firstName: "Sade",
    middleName: "Mae",
    lastName: "Akinlade",
    email: "akinalade@gmail.com",
    department: "HR",
    vacationCategory: "Annual leave",
    vacationDate: "21-04-2024",
    vacationDuration: 3,
  },
  {
    firstName: "Sade",
    middleName: "Mae",
    lastName: "Akinlade",
    email: "akinalade@gmail.com",
    department: "HR",
    vacationCategory: "Annual leave",
    vacationDate: "21-04-2024",
    vacationDuration: 3,
  },
  {
    firstName: "Sade",
    middleName: "Mae",
    lastName: "Akinlade",
    email: "akinalade@gmail.com",
    department: "HR",
    vacationCategory: "Annual leave",
    vacationDate: "21-04-2024",
    vacationDuration: 3,
  },
  {
    firstName: "Sade",
    middleName: "Mae",
    lastName: "Akinlade",
    email: "akinalade@gmail.com",
    department: "HR",
    vacationCategory: "Annual leave",
    vacationDate: "21-04-2024",
    vacationDuration: 3,
  },
  {
    firstName: "Sade",
    middleName: "Mae",
    lastName: "Akinlade",
    email: "akinalade@gmail.com",
    department: "HR",
    vacationCategory: "Annual leave",
    vacationDate: "21-04-2024",
    vacationDuration: 3,
  },
];
const AdminVacations = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { vacationRequests, setVacationRequests } = useContext(AdminContext);
  const [selectedRows, setSelectedRows] = useState([]);

  const fetchVacationRequestData = async (companyName) => {
    const res = await getPendingVacationRequest(companyName);
    console.log("res", res);
    setVacationRequests(res);
  };
  useEffect(() => {
    if (Object.keys(user).length > 0 && !vacationRequests) {
      fetchVacationRequestData(user.companyName);
    }
  }, [user]);

  const handleApprove = async (index) => {
    let ids = [vacationRequests[index].id];
    let status = approveStatus.approved;
    const res = await approveVacationRequest({ ids, status });
    if (res) {
      fetchVacationRequestData(user.companyName);
      toast.success("Vacation request approved!");
    }
  };
  const handleDecline = async (index) => {
    let ids = [vacationRequests[index].id];
    let status = approveStatus.canceled;
    const res = await approveVacationRequest({ ids, status });
    if (res) {
      fetchVacationRequestData(user.companyName);
      toast.error("Vacation request declined.");
      console.log("Vacation request declined.");
    }
  };
  const handleBulkApprove = async () => {
    if (selectedRows?.length === 0) {
      toast.info("No requests selected.");
      return;
    }
    let ids = selectedRows.map((req) => req.id);
    let status = approveStatus.approved;
    const res = await approveVacationRequest({ ids, status });
    if (res) {
      fetchVacationRequestData(user.companyName);
      toast.success("Selected vacation requests approved!");
    }
  };

  const handleBulkDecline = async () => {
    if (selectedRows?.length === 0) {
      toast.info("No requests selected.");
      return;
    }
    let ids = selectedRows.map((req) => req.id);
    let status = approveStatus.canceled;
    const res = await approveVacationRequest({ ids, status });
    if (res) {
      fetchVacationRequestData(user.companyName);
      toast.error("Selected vacation requests declined.");
    }
  };
  return (
    <div className="w-full">
      <ToastContainer />
      <div className="text-[20px] font-inter-bold text-[#011165]">
        Approval Management
      </div>
      <div className="mt-[12px] border-b-[1px] border-[#0000001A]" />
      <div className="w-full flex justify-between items-center py-[25px]">
        <div className="font-inter-bold text-base text-[#404040]">
          Vacations
        </div>
        <div className="flex gap-[10px]">
          <button
            className="rounded-md font-inter-bold text-[10px] text-[#011165] p-[10px] border-[1px] border-[#011165]"
            onClick={handleBulkDecline}
          >
            DECLINE
          </button>
          <button
            className="flex items-center rounded-md font-inter-bold text-[10px] text-white p-[10px] px-[15px] bg-[#011165]"
            onClick={handleBulkApprove}
          >
            APPROVE
          </button>
        </div>
      </div>
      {vacationRequests && vacationRequests?.length > 0 && (
        <VacationRequestTable
          vacationRequests={vacationRequests}
          handleApprove={handleApprove}
          handleDecline={handleDecline}
          setSelectedRows={setSelectedRows}
        />
      )}
    </div>
  );
};
export default AdminVacations;
