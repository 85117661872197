import { useContext, useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, useRowSelect, usePagination } from "react-table";
import { AuthContext } from "../../../contexts/AuthContext";
import Modal from "../../../components/Modal/index";
import Pagination from "../../../components/Pagination/index";
import SortIcon from "../../../assets/images/dashboard/svg/sort-icon.svg";
import LeftArrow from "../../../assets/images/dashboard/svg/left-arrow.svg";
import RightArrow from "../../../assets/images/dashboard/svg/right-arrow.svg";
import Employee from "../../../assets/images/dashboard/svg/employee-sample.svg";
import Edit from "../../../assets/images/dashboard/svg/edit.svg";
import ValidataActive from "../../../assets/images/dashboard/svg/validate-active.svg";
import ValidataInactive from "../../../assets/images/dashboard/svg/validate-inactive.svg";
import { employeeAccountState } from "../../../constants/constants";
import {
  employeeBandsState,
  payrollHistoryState,
} from "../../../constants/constants";
import { getCompanyBands, updateEmployeeBandData } from "../../../services/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const EmployeeBandsTable = (props) => {
  const { employeeData, setSelectedEmployeeIndex, setPageState } = props;
  const [showModal, setShowModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editableRows, setEditableRows] = useState(new Set()); // State to track editable rows
  const { user } = useContext(AuthContext);
  const isRowEditable = (rowIndex) => editableRows.has(rowIndex);
  const [selectedRole, setSelectedRole] = useState("General Employee"); // Default selection
  const [selectedEmployees, setSelectedEmployees] = useState();
  const [roles, setRoles] = useState([]);
  const handleAddToBand = (employee) => {
    setSelectedEmployees(employee);
    fetchUpcomingEvent(user.companyName);
    setShowModal(true);
  };
  const fetchUpcomingEvent = async (companyName) => {
    try {
      const res = await getCompanyBands(companyName);

      if (res.band && res.band.length > 0) {
        const updatedRoles = res.band.map((band) => ({
          label: band.name,
          value: band.name,
        }));
        setRoles(updatedRoles);
        console.log(updatedRoles);
      } else {
        console.log("No bands found or bad data structure:", res);
      }
    } catch (error) {
      console.error("Failed to fetch bands:", error);
    }
  };
  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };
  const data = employeeData ?? [];
  const COLUMNS = [
    {
      id: "selection",
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <div className="">
          <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
        </div>
      ),
      Cell: ({ row }) => (
        <input
          type="checkbox"
          className=""
          {...row.getToggleRowSelectedProps()}
        />
      ),
    },
    {
      Header: "Name",
      accessor: (row) =>
        `${row.firstName} ${row.middleName ?? ""} ${row.lastName}`,
    },
    { Header: "Department", accessor: "department" },
    { Header: "Role", accessor: "position" },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <div
          className="flex items-center justify-center w-[91px] h-[31px] border-[#011165] rounded-[30px] border-[1px] text-center cursor-pointer"
          onClick={() => handleAddToBand(row.original)}
        >
          <p className="font-inter-medium text-[12px] font-[600] text-[#011165]">
            {row.original.band ? "Change Band" : "Add to Band"}
          </p>
        </div>
      ),
    },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    // rows,
    prepareRow,
    state: { pageIndex, pageSize },
    selectedFlatRows,
    pageOptions,
    pageCount,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 5 }, // Initial pagination state
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    setSelectedRows(selectedFlatRows?.map((row) => row.original));
  }, [selectedFlatRows, setSelectedRows]);

  useEffect(() => {}, []);
  const handleBandCreate1 = () => {
    setPageState(employeeBandsState.createBands);
  };
  const toggleEditable = (rowIndex) => {
    setEditableRows((prevState) => {
      const newSet = new Set(prevState);
      if (newSet.has(rowIndex)) {
        newSet.delete(rowIndex);
      } else {
        newSet.add(rowIndex);
      }
      return newSet;
    });
  };

  const handleSaveEmployeeBand = async () => {
    const payload = {
      companyName: user.companyName,
      band: selectedRole,
      userId: selectedEmployees.userId,
    };
    const res = await updateEmployeeBandData(payload);
    toast.success("Band data added successfully!");
    setShowModal(false);
  };
  return (
    <>
      <ToastContainer />
      <div className="w-full">
        <div className="flex justify-end items-center">
          {/* <div className="flex justify-center items-center w-[63px] h-[41px] rounded-md border-primary border-[1px] mr-[15px] cursor-pointer">
            <p className=" font-inter-regular text-[10px] font-[600] text-payroll_primary">
              EDIT
            </p>
          </div> */}
          <div
            className="flex justify-center items-center w-[116px] h-[41px] rounded-md bg-payroll_primary border-[1px] mr-[15px] cursor-pointer active:bg-opacity-30"
            onClick={handleBandCreate1}
          >
            <p className=" font-inter-regular text-[10px] font-[600] text-white">
              CREATE BAND
            </p>
          </div>
        </div>
        <div className="flex ml-[-10px] gap-[20px] mt-[29px]">
          <table {...getTableProps()} className="w-full bg-white rounded-xl">
            <thead className="bg-[#EDEDED]">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      scope="col"
                      className={`px-8 py-6 
                        ${column.id === "selection" ? "rounded-tl-xl" : ""}
                        ${column.Header === "Action" ? "rounded-tr-xl" : ""}
                      `}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="flex items-center gap-[5px] text-left font-inter-semibold text-sm text-[#000000B2]">
                        {column.render("Header")}
                        {column?.id !== "selection" && (
                          <img src={SortIcon} className="w-[8px]" />
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className="px-8 py-10 text-sm font-inter-medium"
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          title="Modal Header"
        >
          <style>
            {`
          .custom-radio-container {
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            user-select: none;
            font-size: 16px;
            display: block;
          }
          .custom-radio-container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }
          .custom-radio-container span {
            position: absolute;
            top: 0;
            left: 0;
            height: 22px;
            width: 22px;
            background-color: #eee;
            border-radius: 4px;
          }
          .custom-radio-container input:checked + span {
            background-color: #aaa;
          }
          .custom-radio-container span:after {
            content: "";
            position: absolute;
            display: none;
          }
          .custom-radio-container input:checked + span:after {
            display: block;
            left: 5px;
            top: 3px;
            width: 13px;
            height: 13px;
            border: solid white;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
          }
        `}
          </style>

          <div className=" w-[384px] rounded-lg flex flex-col items-start justify-center gap-[16px]">
            <p className=" font-inter-bold text-[20px] font-[600] text-payroll_primary">
              Add to Employee Band
            </p>
            <p className=" font-inter-regular font-[400] text-[12px] text-[#aaa]">
              Add staff to employee band
            </p>
            <div className=" w-[380px] h-[1px] bg-[#aaaaaa88] my-[13px]"></div>
            {roles.map((role, index) => (
              <>
                <label
                  className="custom-radio-container font-inter-medium"
                  key={role.value}
                >
                  {role.label}
                  <input
                    type="radio"
                    value={role.value}
                    name="role"
                    checked={selectedRole === role.value}
                    onChange={handleRoleChange}
                  />{" "}
                  <span></span>
                </label>
                {index !== roles.length - 1 && (
                  <div className="w-[380px] h-[1px] bg-[#aaaaaa88]"></div>
                )}
              </>
            ))}
            <div
              className="flex justify-center items-center  w-[46px] h-[41px] bg-payroll_primary rounded-md mx-auto cursor-pointer active:opacity-30"
              onClick={handleSaveEmployeeBand}
            >
              <p className=" font-inter-medium text-[12px] font-[500] text-white ">
                SAVE
              </p>
            </div>
          </div>
        </Modal>
        <Pagination
          pageIndex={pageIndex}
          pageCount={pageCount}
          pageOptions={pageOptions}
          pageSize={pageSize}
          previousPage={previousPage}
          nextPage={nextPage}
          setPageSize={setPageSize}
        />
      </div>
    </>
  );
};
export default EmployeeBandsTable;
