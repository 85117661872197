import AboutUsCard from "../../components/landingpage_payroll/AboutUsCard";
import {
  PAYROLL_ABOUTUS_TITLE,
  PAYROLL_COFOUNER1_DATAS,
  PAYROLL_COFOUNER2_DATAS,
  PAYROLL_COFOUNER3_DATAS,
  PAYROLL_EFFECTIVE_SVG,
  PAYROLL_FOCUSE_DATAS,
} from "../../constants/landingpage_payroll/landingpage_payroll";
import Footer from "./Footer";

const Payroll_AboutUs = () => {
  return (
    <div className="  bg-[#F2F9FF] pt-[60px] pb-[47px] mt-[400px]">
      <div className="relative flex justify-between  px-[74px] ">
        <div>
          <img src={PAYROLL_ABOUTUS_TITLE.img} alt="About us" />
          <p className=" font-eina02-bold font-[400] text-[55px] text-payroll_primary w-[360px]">
            {PAYROLL_ABOUTUS_TITLE.title}
          </p>
        </div>
        <div className="px-[95px]">
          <img src={PAYROLL_EFFECTIVE_SVG.effective1} alt="Effective 1" />
          <div>
            <AboutUsCard
              img={PAYROLL_COFOUNER1_DATAS.img}
              description={PAYROLL_COFOUNER1_DATAS.description}
              name={PAYROLL_COFOUNER1_DATAS.name}
              attribute={PAYROLL_COFOUNER1_DATAS.attribute}
            />
          </div>
          <div className=" absolute right-[300px] top-[361px]">
            <AboutUsCard
              img={PAYROLL_COFOUNER2_DATAS.img}
              description={PAYROLL_COFOUNER2_DATAS.description}
              name={PAYROLL_COFOUNER2_DATAS.name}
              attribute={PAYROLL_COFOUNER2_DATAS.attribute}
            />
          </div>
          <div className=" absolute top-[661px] z-10">
            <AboutUsCard
              img={PAYROLL_COFOUNER3_DATAS.img}
              description={PAYROLL_COFOUNER3_DATAS.description}
              name={PAYROLL_COFOUNER3_DATAS.name}
              attribute={PAYROLL_COFOUNER3_DATAS.attribute}
            />
          </div>
        </div>
        <img
          src={PAYROLL_EFFECTIVE_SVG.effective2}
          alt="Effective 2"
          className=" absolute right-[0px] top-[642px] z-0"
        />
      </div>

      <div className=" h-[1066px] bg-payroll_primary rounded-[50px] mt-[532px] mx-[53px]">
        <div className=" relative flex justify-center pt-[197px]">
          <div>
            <p className=" font-eina02-bold font-[400] text-[55px] text-white text-center w-[620px]">
              {PAYROLL_FOCUSE_DATAS.title}
            </p>
            <div className="flex justify-center items-center w-[203px] h-[57px] bg-[#FF5800] rounded-[100px] mx-auto mt-[35px] cursor-pointer">
              <p className=" font-eina02-bold font-[400] text-[20px] text-white">
                {PAYROLL_FOCUSE_DATAS.btn}
              </p>
            </div>
          </div>
          <img
            src={PAYROLL_FOCUSE_DATAS.svg}
            alt="Star"
            className=" absolute left-0 top-[68px] left-[47px]"
          />
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default Payroll_AboutUs;
