export const formatDate = (from, to) => {
  const fromDate = new Date(from);
  const toDate = new Date(to);
  if (fromDate.getMonth() === toDate.getMonth()) {
    const options = { year: 'numeric', month: 'short' };
    const formattedDate = fromDate.toLocaleDateString('en-US', options);
    return formattedDate.replace(' ', ', ');
  } else {
    const options = { year: 'numeric', month: 'long' };
    const formattedFromDate = fromDate.toLocaleDateString('en-US', options);
    const formattedToDate = toDate.toLocaleDateString('en-US', options);
    return `${formattedFromDate}-${formattedToDate}`.replace(/ /g, ', ');
  }
}
export const getMonthFromDate = (dateString) => {
  const date = new Date(dateString);
  const options = { month: 'long' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
}
export const getYearFromDate = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
}
export const addDaySuffix = (formattedDate) => {
  const parts = formattedDate.split(' ');
  const day = parseInt(parts[1], 10);
  let suffix = 'th';
  if (day === 1 || day === 21 || day === 31) {
    suffix = 'st';
  } else if (day === 2 || day === 22) {
    suffix = 'nd';
  } else if (day === 3 || day === 23) {
    suffix = 'rd';
  }
  return `${day}${suffix} ${parts[0]}, ${parts[2]}`;
}
export const formatEventDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return addDaySuffix(formattedDate);
}