import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useTable,
  useSortBy,
  useRowSelect,
  usePagination,
  useFilters,
} from "react-table";
import ReactDatePicker from "react-datepicker";
import Employee from "../../../assets/images/dashboard/svg/employee-sample.svg";
import Edit from "../../../assets/images/dashboard/svg/edit.svg";
import Close from "../../../assets/images/dashboard/svg/close.svg";

import Pagination from "../../../components/Pagination/index";
import SortIcon from "../../../assets/images/dashboard/svg/sort-icon.svg";
import { initiatePayrollState } from "../../../constants/constants";

const InitiatePayroll = (props) => {
  const {
    employeeData,
    setPayableEmployee,
    payableMonth,
    setPayableMonth,
    selectedFromDate,
    setSelectedFromDate,
    selectedToDate,
    setSelectedToDate,
    setPageState,
    setRefresh,
  } = props;
  const [totalSalary, setTotalSalary] = useState(0);
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [editableRows, setEditableRows] = useState(new Set()); // State to track editable rows

  const isRowEditable = (rowIndex) => editableRows.has(rowIndex);

  const data = employeeData ?? [];
  const COLUMNS = [
    {
      id: "selection",
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <div className="">
          <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
        </div>
      ),
      Cell: ({ row }) => (
        <input
          type="checkbox"
          className=""
          {...row.getToggleRowSelectedProps()}
        />
      ),
    },
    {
      Header: "Name",
      accessor: (row) =>
        `${row.firstName} ${row.middleName ?? ""} ${row.lastName}`,
    },
    { Header: "Department", accessor: "department" },
    { Header: "Gross Salary", accessor: (row) => `₦ ${row.grossSalary}` },
    { Header: "Action", accessor: "action" },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    // rows,
    prepareRow,
    state: { pageIndex, pageSize },
    selectedFlatRows,
    pageOptions,
    pageCount,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    // setFilter
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 5 }, // Initial pagination state
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    let total = 0;
    if (selectedRows?.length > 0) {
      selectedRows.forEach((e) => (total += e.grossSalary));
    }
    setTotalSalary(total);
  }, [selectedRows]);
  useEffect(() => {
    setSelectedRows(selectedFlatRows?.map((row) => row.original));
  }, [selectedFlatRows, setSelectedRows]);

  useEffect(() => {}, [pageIndex]);

  useEffect(() => {
    setPayableMonth(
      selectedToDate.getMonth() - selectedFromDate.getMonth() + 1
    );
  }, [selectedToDate, selectedFromDate]);

  useEffect(() => {
    let newArray = employeeData.map((employee) => {
      let newObj = {};
      Object.assign(newObj, employee, {
        totalPayableAmount: employee.grossSalary * payableMonth,
      });
      return newObj;
    });
    setPayableEmployee(newArray);
  }, [payableMonth]);

  const toggleEditable = (rowIndex) => {
    setEditableRows((prevState) => {
      const newSet = new Set(prevState);
      if (newSet.has(rowIndex)) {
        newSet.delete(rowIndex);
      } else {
        newSet.add(rowIndex);
      }
      return newSet;
    });
  };
  const onFromDateChange = (date) => {
    setSelectedFromDate(date);
  };

  const onToDateChange = (date) => {
    setSelectedToDate(date);
  };
  const handleApplyClick = () => {
    if (selectedRows?.length > 0) {
      setPayableEmployee(selectedRows);
      setPageState(initiatePayrollState.addEmployeePayroll);
    }
  };
  const handleClearClick = () => {
    setSelectedFromDate(new Date());
    setSelectedToDate(new Date());
    setRefresh((prev) => !prev);
  };
  const handleInputChange = (id, event) => {
    let newGrossSalary = parseFloat(event.target.value.replace("₦ ", ""));
    const updatedData = [
      ...data.slice(0, id), // Elements before the updated row
      {
        ...data[id],
        grossSalary: newGrossSalary,
        totalPayableAmount: newGrossSalary * payableMonth,
      }, // Updated row
      ...data.slice(id + 1), // Elements after the updated row
    ];
    setPayableEmployee(updatedData);
  };
  const handleRemove = (i) => {
    let data = JSON.parse(JSON.stringify(employeeData));
    data.splice(i, 1);
    setPayableEmployee(data);
  };

  return (
    <div className="w-full flex flex-col pt-[0px] gap-[20px]">
      <div className="flex justify-between gap-[15px]">
        <div className="w-1/2 border-[1px] border-[#EFF0F6] rounded-2xl p-[15px]">
          <div className="flex items-center justify-between">
            <div className="font-inter-semibold text-sm text-[#404040]">
              Select Payroll Date
            </div>
            <div className="flex gap-[10px]">
              <button
                className="flex items-center py-[9px] px-[20px] rounded-md border-[1px] border-[#011165]"
                onClick={handleClearClick}
              >
                <div className="font-inter-semibold text-[10px] text-[#404040]">
                  CLEAR
                </div>
              </button>
              <button
                className="flex items-center py-[9px] px-[20px] rounded-md  bg-[#011165] hover:bg-[#011265e1]"
                onClick={handleApplyClick}
              >
                <div className="text-white font-inter-semibold text-[10px] ">
                  APPLY
                </div>
              </button>
            </div>
          </div>
          <div className="pt-[10px] mx-[-15px] border-b-[1px] border-[#EFF0F6]" />
          <div className="flex gap-[10px] pt-[15px]">
            <div className="flex w-1/2 flex-col pt-[20px] gap-[15px]">
              <div className="font-inter-semibold text-sm text-[#404040]">
                From Date
              </div>
              <ReactDatePicker
                selected={selectedFromDate}
                showIcon
                toggleCalendarOnIconClick
                onChange={onFromDateChange}
                showMonthYearPicker
                dateFormat="MM/yyyy"
              />
            </div>
            <div className="flex w-1/2 flex-col pt-[20px] gap-[15px]">
              <div className="font-inter-semibold text-sm text-[#404040]">
                To Date
              </div>
              <ReactDatePicker
                selected={selectedToDate}
                showIcon
                toggleCalendarOnIconClick
                onChange={onToDateChange}
                showMonthYearPicker
                dateFormat="MM/yyyy"
              />
            </div>
          </div>
        </div>
        <div className="w-1/2 border-[1px] border-[#EFF0F6] rounded-2xl p-[15px]">
          <div className="pt-[10px] font-inter-semibold text-sm text-[#404040]">
            Total Salaries
          </div>
          <div className="pt-[15px] mx-[-15px] border-b-[1px] border-[#EFF0F6]" />
          <div className="flex justify-center items-center pt-[40px] font-inter-semibold text-[20px] text-[#000000B2]">
            ₦ {totalSalary}
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="flex ml-[-10px] gap-[20px]">
          <table {...getTableProps()} className="w-full bg-white rounded-xl">
            <thead className="bg-[#EDEDED]">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      scope="col"
                      className={`px-8 py-4
                        ${column.id === "selection" ? "rounded-tl-xl" : ""}
                        ${column.Header === "Action" ? "rounded-tr-xl" : ""}
                      `}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="flex items-center gap-[5px] text-left font-inter-semibold text-sm text-[#000000B2]">
                        {column.render("Header")}
                        {column?.id !== "selection" && (
                          <img src={SortIcon} className="w-[8px]" />
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              className="divide-y divide-[#7b78781a]"
              {...getTableBodyProps()}
            >
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        className={`
                            px-8 py-5 text-[#00000080] font-inter-medium text-sm
                            ${
                              cell.column.Header === "Name"
                                ? "flex items-center justify-start gap-[5px]"
                                : ""
                            }
                          `}
                        {...cell.getCellProps()}
                      >
                        {/* {cell.column.Header === "Name" && (
                          <img
                            src={Employee}
                            className="w-[29px]"
                            alt="employee"
                          />
                        )} */}
                        {cell.column.Header === "Status" ? (
                          <div className="flex gap-[10px]">
                            <div
                              className={`
                                    ${
                                      cell.column.Header === "Status" &&
                                      cell.row.original
                                        .isBankAccountValidated === true
                                        ? "bg-[#1DA39259] text-[#1DA392] font-inter-semibold"
                                        : ""
                                    }
                                    ${
                                      cell.column.Header === "Status" &&
                                      cell.row.original
                                        .isBankAccountValidated === false
                                        ? "bg-[#FBDCAE] text-[#5D3208] font-inter-semibold"
                                        : ""
                                    }
                                    px-2 py-1 rounded-xl
                                  `}
                            >
                              {cell.row.original.isBankAccountValidated
                                ? "Validated"
                                : "Pending"}
                            </div>
                          </div>
                        ) : cell.column.Header === "Action" ? (
                          <div className="flex gap-[20px] items-center">
                            <img
                              src={Edit}
                              className="w-[19px] cursor-pointer"
                              alt="edit"
                              onClick={() => {
                                toggleEditable(i);
                              }}
                            />
                            <img
                              src={Close}
                              className="w-[19px] cursor-pointer"
                              alt="close"
                              onClick={() => {
                                handleRemove(i);
                              }}
                            />
                          </div>
                        ) : cell.column.id === "selection" ? (
                          cell.render("Cell")
                        ) : isRowEditable(i) &&
                          cell.column.Header === "Gross Salary" ? (
                          <input
                            type="text"
                            value={cell.value}
                            onChange={(event) => handleInputChange(i, event)}
                            className="w-full"
                            autoFocus
                            onBlur={() => toggleEditable(i)}
                          />
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Pagination
          pageIndex={pageIndex}
          pageCount={pageCount}
          pageOptions={pageOptions}
          pageSize={pageSize}
          previousPage={previousPage}
          nextPage={nextPage}
          setPageSize={setPageSize}
        />
      </div>
    </div>
  );
};
export default InitiatePayroll;
