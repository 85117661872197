import axios from "axios";
import { API_URL } from "../utils/config/app.config";
import { HTTPCode, urlConfig } from "../constants/constants";
import { getApi, postApi } from "./api.utils";

export const register = async (payload) => {
  try {
    const res = await postApi(`${API_URL}/${urlConfig.register}`, payload);
    return res;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const login = async (payload) => {
  try {
    const res = await postApi(`${API_URL}/${urlConfig.login}`, payload);
    return res;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const logout = async (payload) => {
  try {
    const res = await postApi(`${API_URL}/${urlConfig.logout}`, payload);
    return res;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};
export const changePassword = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.changePassword}`,
      payload
    );
    if (res.status === "success") {
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};
export const updatePassword = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.updatePassword}`,
      payload
    );
    if (res.status === "success") {
      return res;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};
export const authUser = async (payload) => {
  try {
    const res = await postApi(`${API_URL}/${urlConfig.authUser}`, payload);
    return res;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const requestVerificationCode = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.requestVerificationCode}`,
      payload
    );
    return res;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const confirmVerificationCode = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.confirmVerificationCode}`,
      payload
    );
    return res;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const getPaymentHistory = async (userId) => {
  try {
    const res = await getApi(
      `${API_URL}/${urlConfig.getPaymentHistory}/${userId}`
    );
    if (res.status === "success") {
      return res.data;
    }
    return [];
  } catch (err) {
    console.error(err);
    return err.response;
  }
};
export const getUpcommingEvent = async (companyName) => {
  try {
    const res = await getApi(
      `${API_URL}/${urlConfig.getUpcommingEvent}/${companyName}`
    );
    if (res.status === "success") {
      return res.data;
    }
    return [];
  } catch (err) {
    console.error(err);
    return err.response;
  }
};
export const getCompanyBands = async (companyName) => {
  try {
    const res = await getApi(
      `${API_URL}/${urlConfig.getCompanyBands}/${companyName}`
    );
    if (res.status === "success") {
      return res.data;
    }
    return [];
  } catch (err) {
    console.error(err);
    return err.response;
  }
};
export const getAvailableLeave = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.getAvailableLeave}`,
      payload
    );
    if (res.status === "success") {
      return res.data;
    }
    return [];
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const updateAvailableLeave = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.updateAvailableLeave}`,
      payload
    );
    if (res.status === "success") {
      return res.data;
    }
    return [];
  } catch (err) {
    console.error(err);
    return [];
  }
};
export const addVacationRequest = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.addVacationRequest}`,
      payload
    );
    if (res.status === "success") {
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};
export const addPaymentSetting = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.addPaymentSetting}`,
      payload
    );
    if (res.status === "success") {
      return res.data;
    }
    return [];
  } catch (err) {
    console.error(err);
    return [];
  }
};
export const getPaymentSetting = async (userId) => {
  try {
    const res = await getApi(
      `${API_URL}/${urlConfig.getPaymentSetting}/${userId}`
    );
    if (res.status === "success") {
      return res.data;
    }
    return [];
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const markDefaultPaymentSetting = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.markDefaultPaymentSetting}`,
      payload
    );
    if (res.status === "success") {
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};
export const deletePaymentSetting = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.deletePaymentSetting}`,
      payload
    );
    if (res.status === "success") {
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};
export const updateEmployeeData = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.updateEmployeeData}`,
      payload
    );
    if (res.status === "success") {
      return res.data;
    }
    return {};
  } catch (err) {
    console.error(err);
    return {};
  }
};

export const getPayrollHistoryByMonth = async (companyName) => {
  try {
    const res = await getApi(
      `${API_URL}/${urlConfig.getPayrollHistoryByMonth}/${companyName}`
    );
    if (res.status === "success") {
      return res.data;
    }
    return [];
  } catch (err) {
    console.error(err);
    return err.response;
  }
};
export const getBankList = async () => {
  try {
    const res = await getApi(`${API_URL}/${urlConfig.getBankList}`);
    if (res.status === "success") {
      return res.data;
    }
    return [];
  } catch (err) {
    console.error(err);
    return err.response;
  }
};
export const getCheckBankAccountValidation = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.getCheckBankAccountValidation}`,
      payload
    );
    if (res.status === "success") {
      return res.data;
    }
    return [];
  } catch (err) {
    console.error(err);
    return err.response;
  }
};
export const getPayrollHistoryInDetail = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.getPayrollHistoryInDetail}`,
      payload
    );
    if (res.status === "success") {
      return res.data;
    }
    return [];
  } catch (err) {
    console.error(err);
    return err.response;
  }
};
export const getApprovalHistory = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.getApprovalHistory}`,
      payload
    );
    if (res.status === "success") {
      return res.data;
    }
    return [];
  } catch (err) {
    console.error(err);
    return err.response;
  }
};
export const getSummaryForDepartment = async (
  comapnyName,
  month,
  department
) => {
  try {
    const res = await getApi(
      `${API_URL}/${urlConfig.getSummaryForDepartment}/${comapnyName}?month=${month}?department=${department}`
    );
    if (res.status === "success") {
      return res.data;
    }
    return [];
  } catch (err) {
    console.error(err);
    return err.response;
  }
};
export const getEmployeeData = async (companyName) => {
  try {
    const res = await getApi(
      `${API_URL}/${urlConfig.getEmployeeData}/${companyName}`
    );
    if (res.status === "success") {
      return res.data;
    }
    return [];
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const registerEmployee = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.registerEmployee}`,
      payload
    );
    if (res.status === "success") {
      return res.data;
    }
    return {};
  } catch (err) {
    console.error(err);
    return {};
  }
};

export const updateEmployeeBandData = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.updateEmployeeBandData}`,
      payload
    );
    if (res.status === "success") {
      return res.data;
    }
    return {};
  } catch (err) {
    console.error(err);
    return {};
  }
};
export const registerEmployeeBulk = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.registerEmployeeBulk}`,
      payload
    );
    if (res.status === "success") {
      return res.data;
    }
    return {};
  } catch (err) {
    console.error(err);
    return {};
  }
};

export const terminateEmployee = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.terminateEmployee}`,
      payload
    );
    if (res.status === "success") {
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};
export const deleteEmployee = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.deleteEmployee}`,
      payload
    );
    if (res.status === "success") {
      return res;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getCompanyDeduction = async (companyName) => {
  try {
    const res = await getApi(
      `${API_URL}/${urlConfig.getCompanyDeduction}/${companyName}`
    );
    if (res.status === "success") {
      return res.data;
    }
    return [];
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const updateCompanyData = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.updateCompanyData}`,
      payload
    );
    if (res.status === "success") {
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getCompanyApprover = async (companyName) => {
  try {
    const res = await getApi(
      `${API_URL}/${urlConfig.getCompanyApprover}/${companyName}`
    );
    if (res.status === "success") {
      return res.data;
    }
    return [];
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getPendingVacationRequest = async (companyName) => {
  try {
    const res = await getApi(
      `${API_URL}/${urlConfig.getPendingVacationRequest}/${companyName}`
    );
    if (res.status === "success") {
      return res.data;
    }
    return [];
  } catch (err) {
    console.error(err);
    return [];
  }
};
export const approveVacationRequest = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.approveVacationRequest}`,
      payload
    );
    if (res.status === "success") {
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};
export const createPayrollActivity = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.createPayrollActivity}`,
      payload
    );
    if (res.status === "success") {
      return res;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getPayrollData = async (companyName) => {
  try {
    const res = await getApi(
      `${API_URL}/${urlConfig.getPendingPayroll}/${companyName}`
    );
    if (res.status === "success") {
      return res.data;
    }
    return [];
  } catch (err) {
    console.error(err);
    return [];
  }
};
export const updateApprovalActivity = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.updateApprovalActivity}`,
      payload
    );
    if (res.status === "success") {
      return res.data;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const createBandData = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${urlConfig.createBandData}`,
      payload
    );
    if (res.status === "success") {
      return res.data;
    }
    return {};
  } catch (err) {
    console.error(err);
    return {};
  }
};
