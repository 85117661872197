import { useContext, useEffect, useState } from "react";
import Modal from "../../../components/Modal/index";
import Info from "../../../assets/images/dashboard/svg/info.svg";
import ThreeDots from "../../../assets/images/dashboard/svg/three-dots.svg";
import LeftArrow from "../../../assets/images/dashboard/svg/left-arrow.svg";
import SaveIcon from "../../../assets/images/dashboard/svg/save.svg";
import PrintIcon from "../../../assets/images/dashboard/svg/print.svg";
import DownloadIcon from "../../../assets/images/dashboard/svg/download2.svg";
import { formatDate, getMonthFromDate } from "../../../utils/helper";
import { EmployeeContext } from "../../../contexts/EmployeeContext";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  getApprovalHistory,
  getPaymentHistory,
  getPayrollHistoryByMonth,
  getPayrollHistoryInDetail,
} from "../../../services/api";
import { AdminContext } from "../../../contexts/AdminContext";
import { payrollHistoryState } from "../../../constants/constants";
import HistoryInMonth from "./PayrollInMonth";
import PayrollInMonth from "./PayrollInMonth";
import PayrollDetail from "./PayrollDetail";
const dummyPayrollHistory = [
  {
    date: "2024-01",
    payroll: [
      {
        department: "Human Resource",
        amount: 3000000,
      },
      {
        department: "Finance",
        amount: 4000000,
      },
      {
        department: "Procurement",
        amount: 5000000,
      },
      {
        department: "ICT",
        amount: 6000000,
      },
      {
        department: "Customer Service",
        amount: 7000000,
      },
    ],
    totalAmount: 25000000,
  },
  {
    date: "2024-02",
    payroll: [
      {
        department: "Human Resource",
        amount: 3000000,
      },
      {
        department: "Finance",
        amount: 4000000,
      },
      {
        department: "Procurement",
        amount: 5000000,
      },
      {
        department: "ICT",
        amount: 6000000,
      },
      {
        department: "Customer Service",
        amount: 7000000,
      },
    ],
    totalAmount: 25000000,
  },
  {
    date: "2024-03",
    payroll: [
      {
        department: "Human Resource",
        amount: 3000000,
      },
      {
        department: "Finance",
        amount: 4000000,
      },
      {
        department: "Procurement",
        amount: 5000000,
      },
      {
        department: "ICT",
        amount: 6000000,
      },
      {
        department: "Customer Service",
        amount: 7000000,
      },
    ],
    totalAmount: 25000000,
  },
  {
    date: "2024-04",
    payroll: [
      {
        department: "Human Resource",
        amount: 3000000,
      },
      {
        department: "Finance",
        amount: 4000000,
      },
      {
        department: "Procurement",
        amount: 5000000,
      },
      {
        department: "ICT",
        amount: 6000000,
      },
      {
        department: "Customer Service",
        amount: 7000000,
      },
    ],
    totalAmount: 25000000,
  },
  {
    date: "2024-05",
    payroll: [
      {
        department: "Human Resource",
        amount: 3000000,
      },
      {
        department: "Finance",
        amount: 4000000,
      },
      {
        department: "Procurement",
        amount: 5000000,
      },
      {
        department: "ICT",
        amount: 6000000,
      },
      {
        department: "Customer Service",
        amount: 7000000,
      },
    ],
    totalAmount: 25000000,
  },
];
const dummyPayrollHistoryInDetail = [
  {
    name: "Susan Megg",
    image: "",
    department: "Human Resource",
    amount: "300000",
  },
  {
    name: "Philip Olaitan",
    image: "",
    department: "Human Resource",
    amount: "400000",
  },
  {
    name: "Sade Akinlade",
    image: "",
    department: "Human Resource",
    amount: "500000",
  },
  {
    name: "Philip Olaitan",
    image: "",
    department: "Human Resource",
    amount: "400000",
  },
  {
    name: "Susan Megg",
    image: "",
    department: "Human Resource",
    amount: "400000",
  },
];
const dummyApprovalData = [
  {
    name: "Susan Megg",
    image: "",
    designation: "PM",
    status: "approved",
    date: "17-02-0203",
  },
  {
    name: "Philip Olaitan",
    image: "",
    designation: "Head Finance",
    status: "approved",
    date: "17-02-0203",
  },
  {
    name: "Sade Akinlade",
    image: "",
    designation: "CPO",
    status: "approved",
    date: "17-02-0203",
  },
  {
    name: "Philip Olaitan",
    image: "",
    designation: "COO",
    status: "canceled",
    date: "17-02-0203",
  },
  {
    name: "Susan Megg",
    image: "",
    designation: "CEO",
    status: "canceled",
    date: "17-02-0203",
  },
];
const PayrollCard = (props) => {
  const { data, onClick } = props;
  console.log(data);
  return (
    <div className="w-1/4">
      <div
        className="m-[10px] px-[13px] py-[18px] border-[1px] border-[#EFF0F6] rounded-xl cursor-pointer  hover:scale-110 transition-transform"
        onClick={onClick}
      >
        <div className="flex items-center justify-between">
          <div className="font-inter-medium font-[#00000080] text-[13px]">
            {getMonthFromDate(data.date)}
          </div>
          <img
            src={ThreeDots}
            className="w-[3px] cursor-pointer"
            alt="detail"
          />
        </div>
        <div className="pt-[10px] font-inter-bold text-[18px] text-[#000000B2]">
          &#8358; {data.totalAmount}
        </div>
      </div>
    </div>
  );
};
const AdminPayrollHistory = () => {
  const [selectedYear, setSelectedYear] = useState("2024");
  const [modalData, setModalData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const { user } = useContext(AuthContext);
  const { payrollHistory, setPayrollHistory } = useContext(AdminContext);
  const [pageState, setPageState] = useState(
    payrollHistoryState.historyInMonth
  );
  const [selectedPayrollIndex, setSelectedPayrollIndex] = useState(0);
  const [payrollHistoryInDetail, setPayrollHistoryInDetail] = useState([]);
  const [approvalHistory, setApprovalHistory] = useState([]);
  // useEffect(() => {
  //   setPayrollHistory(dummyPayrollHistory);
  // }, []);
  // useEffect(() => {
  //   setPayrollHistory(dummyPayrollHistory);
  // }, []);
  useEffect(() => {
    const fetchPayrollHistory = async (companyName) => {
      const res = await getPayrollHistoryByMonth(companyName);

      if (res?.length > 0) {
        setPayrollHistory(res);
        console.log(res);
      }
    };
    if (Object.keys(user).length > 0 && !payrollHistory) {
      fetchPayrollHistory(user.companyName);
    }
  }, [user, payrollHistory]);

  const fetchPayrollHistory = async (companyName) => {
    const res = await getPayrollHistoryByMonth(companyName);
    console.log(res);
    if (res?.length > 0) {
      setPayrollHistory(res);
    }
  };
  useEffect(() => {
    if (Object.keys(user).length > 0 && !payrollHistory) {
      fetchPayrollHistory(user.companyName);
    }
  }, [user, payrollHistory]);

  const fetchPayrollHistoryInDetail = async (companyName, date) => {
    const res = await getPayrollHistoryInDetail({
      companyName,
      fromDate: date,
    });
    console.log(res);
    if (res?.length > 0) {
      setPayrollHistoryInDetail(res);
    }
  };
  const fetchApprovalHistory = async (companyName, date) => {
    const res = await getApprovalHistory({ companyName, fromDate: date });
    console.log(res);
    if (res?.length > 0) {
      setApprovalHistory(res);
    }
  };
  useEffect(() => {
    console.log(selectedPayrollIndex);
    if (pageState === payrollHistoryState.historyDetail) {
      fetchPayrollHistoryInDetail(
        user.companyName,
        payrollHistory[selectedPayrollIndex].date
      );
      fetchApprovalHistory(
        user.companyName,
        payrollHistory[selectedPayrollIndex].date
      );
    }
  }, [selectedPayrollIndex, pageState, user]);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSave = () => {
    // Add logic to save data here
    console.log("Save button clicked");
    // Here you can perform the save operation
    alert("Data saved successfully!");
  };

  const handlePrint = () => {
    // Here you can perform the print operation
    window.print();
  };

  const handleDownload = () => {
    // Here you can perform the download operation
    // For example, create a blob URL and trigger download
    const data = JSON.stringify(modalData);
    const blob = new Blob([data], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `paySlipsData-${modalData?.date}.json`;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };
  return (
    <>
      <div className="w-full">
        <div className="text-[20px] font-inter-bold text-[#011165]">
          Payroll Management
        </div>
        <div className="mt-[12px] border-b-[1px] border-[#0000001A]" />
        <div className="flex gap-[30px] items-center cursor-pointer pt-[25px]">
          {pageState === payrollHistoryState.historyDetail && (
            <img
              src={LeftArrow}
              className="w-[9px]"
              alt="prev"
              onClick={() => {
                setPageState(payrollHistoryState.historyInMonth);
              }}
            />
          )}
          <div className="text-base font-inter-bold text-[#404040]">
            Payroll History
          </div>
        </div>
        {pageState === payrollHistoryState.historyInMonth && (
          <PayrollInMonth
            payrollHistory={payrollHistory}
            setPageState={setPageState}
            setSelectedPayrollIndex={setSelectedPayrollIndex}
          />
        )}
        {pageState === payrollHistoryState.historyDetail && (
          <div>
            <PayrollDetail
              payrollHistory={payrollHistoryInDetail}
              approvalHistory={approvalHistory}
              setPageState={setPageState}
              selectedMonthData={payrollHistory[selectedPayrollIndex]}
              nextMonthData={
                selectedPayrollIndex < payrollHistory?.length - 1
                  ? payrollHistory[selectedPayrollIndex]
                  : undefined
              }
            />
          </div>
        )}
      </div>
    </>
  );
};
export default AdminPayrollHistory;
