import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  confirmVerificationCode,
  requestVerificationCode,
} from "../../../services/api";
import { LOGIN_PSVG } from "../../../constants/Auth/Login";
import { userRole } from "../../../constants/constants";

const CodeVerification = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { setUser, setRole, user } = useContext(AuthContext);
  const [otp, setOTP] = useState(Array(6).fill(""));
  const [isOTPsent, setIsOTPsent] = useState(false);
  const email = params.email || user?.email;

  const sendOTP = async () => {
    if (!isOTPsent && email) {
      setIsOTPsent(true);
      const res = await requestVerificationCode({ email });
      if (res.status === "success") {
        toast.success("OTP sent to your email!");
      } else {
        toast.error(res.message || "Failed to send OTP. Please try again.");
        setIsOTPsent(false);
      }
    }
  };

  useEffect(() => {
    sendOTP();
  }, []); // Empty dependency array to ensure it runs only once

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (otp.join("").length === 6) {
      // Ensure OTP is exactly 6 digits
      const res = await confirmVerificationCode({ email, code: otp.join("") });
      if (res.status === "success") {
        handleSuccessfulLogin(res);
      } else {
        toast.error(res.message || "Invalid OTP. Please try again.");
      }
    } else {
      toast.error("Please enter a 6 digit OTP.");
    }
  };

  const handleSuccessfulLogin = (res) => {
    localStorage.setItem(
      "accessToken",
      JSON.stringify({ accessToken: res.data?.token?.access })
    );
    localStorage.setItem(
      "refreshToken",
      JSON.stringify({ refreshToken: res.data?.token?.refresh })
    );
    setUser(res.data.user);
    setRole(res.data.user.role);
    navigate(
      res.data.user.role === userRole.admin
        ? "/admin/dashboard"
        : "/employee/dashboard"
    );
    toast.success("Login successful!");
  };

  const resendOTP = async () => {
    const res = await requestVerificationCode({ email }); // Directly call the request function here if needed
    if (res.status === "success") {
      toast.success("OTP resent to your email and SMS!");
    } else {
      toast.error(res.message || "Failed to resend OTP. Please try again.");
    }
  };

  const handleChange = (event, index) => {
    const newOTP = [...otp];
    newOTP[index] = event.target.value.slice(0, 1);
    setOTP(newOTP);

    // Auto-focus to next input field after current input
    if (event.target.nextSibling && event.target.value) {
      event.target.nextSibling.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").slice(0, 6).split("");
    if (pasteData.length === 6) {
      setOTP(pasteData);
      document.getElementById(`otp-${5}`)?.focus();
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="flex justify-between h-screen bg-payroll_primary">
        <div className="h-full overflow-hidden">
          <p className="font-eina02-bold font-[900] text-[36px] text-white opacity-[25%] pt-[48px] pl-[75px]">
            {LOGIN_PSVG.title}
          </p>
          <img src={LOGIN_PSVG.svg} alt="P" className="pt-[50px]" />
        </div>
        <div className="w-[400px] max-w-md m-auto p-8 space-y-8 bg-payroll_primary rounded-lg shadow-lg">
          <h1 className="text-center text-2xl font-eina02-bold font-bold text-white opacity-[70%]">
            Verify Your Account
          </h1>
          <p className=" font-eina02-regular text-center text-sm  text-white  opacity-[70%]">
            A one-time passcode was sent to you via email. <br /> Please enter
            the 6 digit OTP below.
          </p>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="otp"
                className="block mb-1 text-sm font-medium text-white opacity-[70%]"
              >
                Enter OTP here
              </label>
              <div
                className="flex justify-between space-x-2"
                onPaste={handlePaste}
              >
                {otp.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    id={`otp-${index}`}
                    name={`otp-${index}`}
                    value={value}
                    onChange={(e) => handleChange(e, index)}
                    className="w-full p-3 text-center text-lg font-semibold border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 opacity-30 rounded-lg"
                    maxLength="1"
                    pattern="[0-9]{1}"
                    autoComplete="off"
                    required
                  />
                ))}
              </div>
            </div>
            <div className="flex flex-col justify-between items-center">
              <label
                htmlFor="otp"
                className="block mb-1 text-sm font-medium text-white opacity-[70%]"
              >
                Did not receive OTP?
              </label>
              <button
                type="button"
                onClick={resendOTP}
                className="text-sm text-white hover:underline opacity-[70%]"
              >
                Resend
              </button>

              <button
                type="submit"
                className="mt-[20px] px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 border-[#2785b155] border-[1px] opacity-[70%] active:opacity-30"
              >
                Verify
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CodeVerification;
