import { useContext, useEffect, useMemo, useState } from "react";
import { useTable, useSortBy } from "react-table";
import { AdminContext } from "../../../contexts/AdminContext";

import SortIcon from "../../../assets/images/dashboard/svg/sort-icon.svg";
import Vacation from "../../../assets/images/dashboard/svg/vacation-icon.svg";
import Employee from "../../../assets/images/dashboard/svg/employee-sample.svg";
import { dashboardState } from "../../../constants/constants";
import { formatEventDate } from "../../../utils/helper";

const EventRow = (props) => {
  const { event } = props;
  return (
    <div className="w-full py-[13px] flex justify-between items-center">
      <div className="flex gap-[15px] items-center">
        <img
          src={Vacation}
          className="w-[51px] cursor-pointer"
          alt="vacation"
        />
        <div className="">
          <div className="font-eina02-semibold text-[14px] text-[#404040]">
            {event.eventName}
          </div>
          <div className="font-eina02-regular text-[14px] text-[#757575]">
            {formatEventDate(event.eventDate)}
          </div>
        </div>
      </div>
      <div className="flex gap-[8px] items-center">
        <div className="font-eina02-semibold text-[10px] text-[#404040] cursor-pointer">
          {event.eventCategory}
        </div>
      </div>
    </div>
  );
};

const HistoryInDetail = (props) => {
  const { payrollHistory, approvalData, employeeVacation, setPageState } =
    props;
  const { upcomingEvent } = useContext(AdminContext);
  console.log("4444444444444", payrollHistory);
  const data = payrollHistory;
  const COLUMNS = [
    { Header: "Employee", accessor: "name" },
    { Header: "Department", accessor: "department" },
    { Header: "Amount Paid", accessor: "totalPay" },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        data,
        columns,
      },
      useSortBy
    );

  // useEffect(() => {
  //   console.log(headerGroups)
  // }, [headerGroups]);
  return (
    <div className="w-full">
      <div className="flex ml-[-10px] gap-[20px]">
        <div className="w-2/3">
          <table {...getTableProps()} className="w-full bg-white rounded-xl">
            <thead className="bg-[#EDEDED]">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      scope="col"
                      className={`px-8 py-6 
                        ${column.Header === "Employee" ? "rounded-tl-xl" : ""}
                        ${
                          column.Header === "Amount Paid" ? "rounded-tr-xl" : ""
                        }
                      `}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="flex gap-[5px] text-left font-inter-semibold text-sm text-[#000000B2]">
                        {column.render("Header")}
                        <img src={SortIcon} className="w-[8px]" />
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              className="divide-y divide-[#7b78781a]"
              {...getTableBodyProps()}
            >
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        className={`
                            px-8 py-6 text-[#00000080] font-inter-medium text-sm
                            ${
                              cell.column.Header === "Employee"
                                ? "flex items-center justify-start gap-[5px]"
                                : ""
                            }
                          `}
                        {...cell.getCellProps()}
                      >
                        {cell.column.Header === "Employee" && (
                          <img
                            src={Employee}
                            className="w-[29px]"
                            alt="employee"
                          />
                        )}
                        {cell.column.Header === "Amount Paid"
                          ? `₦ ${cell.value}`
                          : cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="w-1/3 min-w-[500px] flex flex-col gap-[25px]">
          <div className="border-[#EFF0F6] border-[0.92px] rounded-2xl">
            <div className="flex justify-between items-center w-full px-[40px] py-[15px] border-b-[1px] border-[#EFF0F6]">
              <div className="font-eina02-semibold text-[16px] text-[#404040]">
                Recent Approvals
              </div>
              <div
                className="font-inter-medium text-sm text-[#A6A6A6] cursor-pointer"
                onClick={() => setPageState(dashboardState.approvalHistory)}
              >
                View more
              </div>
            </div>
            <div className="border-b-[2px] border-[#FF5800] ml-[40px] mt-[-2px] w-[100px]" />
            <div className="px-[40px] py-[20px]">
              {Object.keys(approvalData).length > 0 &&
                approvalData
                  .slice(approvalData.length - 3, approvalData.length)
                  .map((approval) => {
                    return (
                      <div className="flex items-center justify-between py-2">
                        <div className="font-inter-medium text-sm text-[#00000080]">
                          {" "}
                          {approval?.name}
                        </div>
                        <div
                          className={`
                          font-inter-medium text-[12px]  py-[5px] px-[10px] rounded-2xl
                          ${
                            approval.status === "approved"
                              ? "bg-[#1DA39259] text-[#1DA392]"
                              : "bg-[#FBDCAE] text-[#5D3208]"
                          }
                        `}
                        >
                          {approval?.status}
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
          <div className="border-[#EFF0F6] border-[0.92px] rounded-2xl">
            <div className="flex justify-between w-full px-[40px] py-[15px] border-b-[1px] border-[#EFF0F6]">
              <div className="font-eina02-semibold text-[16px] text-[#404040]">
                Upcomming events
              </div>
            </div>
            <div className="border-b-[2px] border-[#FF5800] ml-[40px] mt-[-2px] w-[145px]" />
            <div className="px-[40px] pt-[20px]">
              {employeeVacation &&
                employeeVacation.map((event, i) => {
                  return <EventRow event={event} key={i} />;
                })}
              {upcomingEvent &&
                upcomingEvent.map((event, i) => {
                  return <EventRow event={event} key={i} />;
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HistoryInDetail;
