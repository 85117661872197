import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { EmployeeContext } from "../../../contexts/EmployeeContext";
import * as XLSX from "xlsx";
import Upload from "../../../assets/images/dashboard/svg/upload-blue.svg";
import Email from "../../../assets/images/dashboard/svg/mail.svg";
import Phone from "../../../assets/images/dashboard/svg/phone.svg";
import Home from "../../../assets/images/dashboard/svg/home.svg";
import Person from "../../../assets/images/dashboard/svg/person.svg";
import {
  getBankList,
  getEmployeeData,
  registerEmployee,
  registerEmployeeBulk,
  updateEmployeeData,
} from "../../../services/api";
import { userRole } from "../../../constants/constants";
import { AdminContext } from "../../../contexts/AdminContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const AddEmployee = () => {
  const { user } = useContext(AuthContext);
  const { setEmployeeData } = useContext(AdminContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const fileInputRef = useRef(null);
  const { bankList, setBankList } = useContext(EmployeeContext);
  const [bankDestinationcode, setBankDestinationcode] = useState();
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  // Function to create a map from bank label to value
  const createBankMap = (bankList) => {
    const bankMap = {};
    bankList.forEach((bank) => {
      bankMap[bank.label] = bank.value;
    });
    setBankDestinationcode(bankMap);
  };
  const resisger = async (formData) => {
    const destinationcode =
      bankDestinationcode[formData.bankName] || "Not Found";
    const payload = {
      ...formData,
      companyName: user.companyName,
      role: userRole.employee,
      destinationcode: destinationcode,
      password: "111111",
    };

    const res = await registerEmployee(payload);
    return res;
  };
  const fetchEmployeeData = async (companyName) => {
    const res = await getEmployeeData(companyName);
    if (res?.length > 0) {
      setEmployeeData(res);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Process form data here

    const res = await resisger(formData);
    toast.success("New employee created successfully!");
    if (Object.keys(res)?.length > 0) {
      await fetchEmployeeData(user.companyName);
      navigate("/admin/employee-management/accounts");
    }
  };
  const handleSaveAndAnother = async () => {
    const res = await resisger(formData);

    if (Object.keys(res)?.length > 0) {
      setFormData({
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        department: "",
        grossSalary: "",
        bankName: "",
        bankAccountName: "",
        bankAccountNumber: "",
      });
    }
  };
  const handleUploadCSVClick = () => {
    fileInputRef.current.click();
  };
  const handleUpload = async (fileData) => {
    const workbook = XLSX.read(fileData, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    // Handle the parsed Excel data

    let payload = [];
    if (data?.length > 1) {
      for (let i = 1; i < data?.length; i++) {
        payload.push({
          firstName: data[i][0],
          middleName: data[i][1],
          lastName: data[i][2],
          email: data[i][3],
          department: data[i][4],
          grossSalary: data[i][5],
          bankName: data[i][6],
          bankAccountName: data[i][7],
          bankAccountNumber: data[i][8],
          companyName: user.companyName,
          role: userRole.employee,
          password: "111111",
        });
      }
      const res = await registerEmployeeBulk(payload);
      if (res) {
        fetchEmployeeData(user.companyName);
      }
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileData = e.target.result;
        handleUpload(fileData);
      };
      reader.readAsBinaryString(file);
    }
  };
  useEffect(() => {
    fetchBankList();
  }, []);
  const fetchBankList = async () => {
    const res = await getBankList();
    createBankMap(res);
    setBankList(res);
  };
  return (
    <div className="w-full">
      <ToastContainer />
      <div className="text-[20px] font-inter-bold text-[#011165]">
        Employee Management
      </div>
      <div className="mt-[12px] border-b-[1px] border-[#0000001A]" />
      <div className="text-base font-inter-bold text-[#404040] pt-[30px]">
        Add New Employee
      </div>
      <div className="flex gap-[15px] pt-[20px]">
        <div className="w-3/4 max-w-[1200px] flex flex-col pt-[10px] px-[35px] pb-[30px] border-[1px] border-[#EFF0F6] rounded-2xl">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-[12px] pt-[10px]">
              <div className="flex gap-[30px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                <div className="flex flex-col w-1/3 gap-[5px] justify-between">
                  <div className="font-inter-bold text-[#000000B2]">
                    First Name
                  </div>
                  <input
                    type="text"
                    name="firstName"
                    className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="flex flex-col w-1/3 gap-[5px] justify-between">
                  <div className="font-inter-bold text-[#000000B2]">
                    Middle Name
                  </div>
                  <input
                    type="text"
                    name="middleName"
                    className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                    value={formData.middleName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex flex-col w-1/3 gap-[5px] justify-between">
                  <div className="font-inter-bold text-[#000000B2]">
                    Last Name
                  </div>
                  <input
                    type="text"
                    name="lastName"
                    className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="pt-[20px] flex gap-[30px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                  <div className="font-inter-bold text-[#000000B2]">
                    Email Address
                  </div>
                  <input
                    type="text"
                    name="email"
                    className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                  <div className="font-inter-bold text-[#000000B2]">
                    Department
                  </div>
                  <input
                    type="text"
                    name="department"
                    className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                    value={formData.department}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="pt-[20px] flex gap-[30px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                  <div className="font-inter-bold text-[#000000B2]">
                    Gross Salary
                  </div>
                  <input
                    type="text"
                    name="grossSalary"
                    className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                    value={formData.grossSalary}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                  <div className="font-inter-bold text-[#000000B2]">
                    Bank Name
                  </div>
                  {/* Conditional rendering check */}
                  {bankList && bankList.length > 0 && (
                    <select
                      name="bankName"
                      className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                      value={formData.bankName || ""}
                      onChange={handleInputChange}
                    >
                      {bankList.map((bank, index) => (
                        <option key={index} value={bank.label}>
                          {bank.label}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
              <div className="pt-[20px] flex gap-[30px] justify-between font-inter-medium text-[14px] text-[#00000080]">
                <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                  <div className="font-inter-bold text-[#000000B2]">
                    Account Name
                  </div>
                  <input
                    type="text"
                    name="bankAccountName"
                    className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                    value={formData.bankAccountName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex flex-col w-1/2 gap-[5px] justify-between">
                  <div className="font-inter-bold text-[#000000B2]">
                    Account Number
                  </div>
                  <input
                    type="text"
                    name="bankAccountNumber"
                    className="border-[1px] border-[#00000040] rounded-md px-[5px] py-[8px] focus:outline-none focus:border-[#000000A0]"
                    value={formData.bankAccountNumber}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="mt-[50px] border-b-[1px] border-[#0000001A]" />
            <div className="flex justify-between pt-[50px]">
              <div className="flex">
                <button
                  className="flex justify-center items-center py-[7px] px-[10px] rounded-lg text-white font-inter-semibold text-[11px] bg-[#011165] border-[1px] border-[#011165]"
                  onClick={handleSaveAndAnother}
                  type="button"
                >
                  SAVE AND ANOTHER
                </button>
              </div>
              <div className="flex gap-[20px]">
                <button
                  className="flex justify-center items-center py-[7px] px-[10px] rounded-lg text-[#011165] font-inter-semibold text-[11px] bg-[#ffffff] border-[1px] border-[#011165]"
                  onClick={() =>
                    navigate("/admin/employee-management/accounts")
                  }
                >
                  CANCEL
                </button>
                <button
                  className="flex justify-center items-center py-[7px] px-[10px] rounded-lg text-white font-inter-semibold text-[11px] bg-[#011165] border-[1px] border-[#011165]"
                  type="submit"
                >
                  SAVE
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="w-1/4">
          <div>
            <input
              ref={fileInputRef}
              type="file"
              className="hidden"
              onChange={handleFileChange}
            />
          </div>
          <button
            className="flex justify-center items-center gap-[10px] py-[7px] px-[10px] rounded-lg text-[#011165] font-inter-semibold text-[11px] bg-[#ffffff] border-[1px] border-[#011165]"
            onClick={handleUploadCSVClick}
          >
            <img src={Upload} className="w-[20px]" alt="upload" />
            BULK ADD EMPLOYEE
          </button>
        </div>
      </div>
    </div>
  );
};
export default AddEmployee;
