import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  confirmVerificationCode,
  requestVerificationCode,
  updatePassword,
} from "../../../services/api";
import { LOGIN_PSVG } from "../../../constants/Auth/Login";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { email } = useParams();
  const { setUser, setRole } = useContext(AuthContext);
  const [contact, setContact] = useState("");
  const [otp, setOTP] = useState(Array(6).fill(""));
  const [newPassword, setNewPassword] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);
  const handleContactChange = (e) => {
    setContact(e.target.value);
  };

  const sendOTP = async (e) => {
    e.preventDefault();
    console.log(contact);
    const res = await requestVerificationCode({ email: contact });
    if (res.status === "success") {
      toast.success("OTP sent to your email!");
      setOtpVerified(false);
    } else {
      toast.error(res.message || "Failed to send OTP. Please try again.");
    }
  };

  const handleChange = (event, index) => {
    const newOTP = [...otp];
    newOTP[index] = event.target.value.slice(0, 1);
    setOTP(newOTP);

    // Auto-focus to next input field after current input
    if (event.target.nextSibling && event.target.value) {
      event.target.nextSibling.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").slice(0, 6).split("");
    if (pasteData.length === 6) {
      setOTP(pasteData);
      const lastInput = document.getElementById(`otp-${5}`);
      if (lastInput) {
        lastInput.focus();
      }
    }
  };
  const handleSaveNewPassword = async (e) => {
    e.preventDefault();
    const res = await updatePassword({ email: contact, newPassword });

    if (res) {
      setUser(res.data);
      setRole(res.data.role);
      toast.success("Password updated successfully!");
      navigate("/login"); // Redirect to login after successful password update
    } else {
      toast.error(
        res.message || "Failed to update password. Please try again."
      );
    }
  };
  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    const code = otp.join("");
    if (code.length === 6) {
      const res = await confirmVerificationCode({ email: contact, code });
      if (res.status === "success") {
        setUser(res.data.user);
        setRole(res.data.user.role);
        setOtpVerified(true);
        toast.success("OTP verified!");
      } else {
        toast.error(res.message || "Invalid OTP. Please try again.");
      }
    } else {
      toast.error("Please enter a 6 digit OTP.");
    }
  };
  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };
  return (
    <div className="flex justify-between h-screen bg-payroll_primary">
      <div className="h-full overflow-hidden">
        <p className="font-eina02-bold font-[900] text-[36px] text-white opacity-[25%] pt-[48px] pl-[75px]">
          {LOGIN_PSVG.title}
        </p>
        <img src={LOGIN_PSVG.svg} alt="P" className="pt-[50px]" />
      </div>
      <div className="w-[400px] max-w-md m-auto p-8 space-y-8 bg-payroll_primary rounded-lg shadow-2xl">
        <h1 className="text-center text-2xl font-eina02-bold font-bold text-white opacity-[70%]">
          Reset Your Password
        </h1>
        <form onSubmit={sendOTP} className="space-y-6">
          <input
            type="text"
            id="contact"
            name="contact"
            value={contact}
            onChange={handleContactChange}
            placeholder="Email or Phone Number"
            className="block w-full p-3 text-center text-lg font-semibold border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 opacity-30 rounded-lg"
            required
          />
          <button
            type="submit"
            className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 border-[#2785b155] border-[1px]  opacity-[70%] active:opacity-30"
          >
            Send OTP
          </button>
        </form>
        {!otpVerified ? (
          <form onSubmit={handleVerifyOTP} className="space-y-6">
            <div
              className="flex justify-between space-x-2"
              onPaste={handlePaste}
            >
              {otp.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  id={`otp-${index}`}
                  name={`otp-${index}`}
                  value={value}
                  onChange={(e) => handleChange(e, index)}
                  className="w-full p-3 text-center text-lg font-semibold border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 opacity-30 rounded-lg"
                  maxLength="1"
                  pattern="[0-9]{1}"
                  autoComplete="off"
                  required
                />
              ))}
            </div>
            <button
              type="submit"
              className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 border-[#2785b155] border-[1px]  opacity-[70%] active:opacity-30"
            >
              Verify
            </button>
          </form>
        ) : (
          <form onSubmit={handleSaveNewPassword} className="space-y-6">
            <input
              type="password"
              value={newPassword}
              onChange={handleNewPasswordChange}
              placeholder="New Password"
              className="block w-full p-3 text-center text-lg font-semibold border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 opacity-30 rounded-lg"
              required
            />
            <button
              type="submit"
              className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 border-[#2785b155] border-[1px]  opacity-[70%] active:opacity-30"
            >
              Save
            </button>
          </form>
        )}
        <p className="text-sm text-center text-white opacity-[70%]">
          Already have a password?
          <button
            onClick={() => navigate("/login")}
            className="text-[#D64A00] hover:text-indigo-500"
          >
            Log in
          </button>
        </p>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ResetPassword;
